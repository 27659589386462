import { CmsContent, cmsContentSchema } from './cmsSchemas';

const { error } = console;

export type GetCmsContentResponse =
  | {
      type: 'ok';
      payload: CmsContent;
    }
  | {
      type: 'error';
    };
export const getCmsContent = async (): Promise<GetCmsContentResponse> => {
  try {
    const cmsContent = await fetch('/api/booking-flow-content');
    const cmsContentJson = await cmsContent.json();
    const parsed = cmsContentSchema.parse(cmsContentJson.data);

    return {
      type: 'ok',
      payload: parsed,
    };
  } catch (err) {
    error(err);

    return {
      type: 'error',
    };
  }
};
