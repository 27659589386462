import AWS from 'aws-sdk';

import { breakpointSizes } from 'styles/breakpoints';

export const formatSlug = (str) => str.replace(/_/g, '-');

export const formatEnum = (str) => str.replace(/-/g, '_');

const capitaliseWords = (str) =>
  str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');

export const formatEnumToString = (str) => {
  if (!str) return;

  const formatString = str.replace(/_/g, ' ');
  return capitaliseWords(formatString);
};

export const formatSlugToString = (str) => {
  if (!str) return;

  const formatString = str.replace(/-/g, ' ');
  return capitaliseWords(formatString);
};

export const isEven = (index) => index % 2 === 0;

export const hasLocalStorage = () => typeof localStorage !== 'undefined';

export const hasCookiesEnabled = () =>
  hasLocalStorage() && localStorage.cookieAccepted === '1';

export const isClient = () => typeof window !== 'undefined';

export const isTouchDevice = () =>
  isClient() && ('ontouchstart' in window || navigator.maxTouchPoints > 0);

export const isDesktop = (size = 'large') =>
  window.innerWidth >= breakpointSizes[size];

export const isMobileDevice = () => window.innerWidth < breakpointSizes.medium;

export const supInfoField = (contact, field) =>
  contact?.SupInfo?.find(({ FieldName }) => FieldName === field)?.FieldContent;

export const groupBy = (arr, key) => {
  return arr.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );
};

export const formatAtreemoData = (data) =>
  data.reduce((result, item) => {
    return {
      ...result,
      [item.KeyName]: item.KeyValue,
    };
  }, {});

export const clamp = (val, min, max) => Math.max(min, Math.min(max, val));

export const lerp = (start, end, t) => start * (1 - t) + end * t;

export const map = (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c;

export const randomInteger = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const isProduction = () =>
  process.env.NEXT_PUBLIC_BUILD_ENV === 'production';

export const isExternalLink = (href) => href?.charAt(0) !== '/';

export const handleAuthenticationCheck = (session, email) => {
  const sessionEmail = session?.user.email.toLowerCase();
  const contactEmail = email?.toLowerCase();

  if (!session || sessionEmail !== contactEmail) {
    throw new Error('Not Authorised');
  }
};

export const handleAwsSdkConfig = () => {
  AWS.config.update({
    accessKeyId: process.env.CLAYS_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.CLAYS_AWS_SECRET_ACCESS_KEY,
    region: process.env.CLAYS_AWS_REGION,
  });
};

export const handleReplaceMoorgate = (location) => {
  const regex = /Moorgate/gi;
  return location.replace(regex, 'The City');
};

export const formatPathname = (path) => {
  const pathWithoutQueryParam = path.split('?')[0];
  const pathWithoutHash = pathWithoutQueryParam.split('#')[0];
  return pathWithoutHash;
};
