import { css } from 'styled-components';

export const loadFonts = css`
  @font-face {
    font-family: 'Wulkan Display';
    font-display: block;
    src: url('/fonts/wulkan/wulkan-display-black.woff2') format('woff2'),
      url('/fonts/wulkan/wulkan-display-black.woff') format('woff');
    font-weight: 900;
  }

  @font-face {
    font-family: 'Wulkan Display';
    font-display: block;
    src: url('/fonts/wulkan/wulkan-display-bold.woff2') format('woff2'),
      url('/fonts/wulkan/wulkan-display-bold.woff') format('woff');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Wulkan Display';
    font-display: block;
    src: url('/fonts/wulkan/wulkan-display-bold-italic.woff2') format('woff2'),
      url('/fonts/wulkan/wulkan-display-bold-italic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'Wulkan Display';
    font-display: block;
    src: url('/fonts/wulkan/wulkan-display-semibold.woff2') format('woff2'),
      url('/fonts/wulkan/wulkan-display-semibold.woff') format('woff');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Wulkan Display';
    font-display: block;
    src: url('/fonts/wulkan/wulkan-display-semibold-italic.woff2')
        format('woff2'),
      url('/fonts/wulkan/wulkan-display-semibold-italic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'Poppins';
    font-display: block;
    src: url('/fonts/poppins/poppins-regular.ttf') format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Poppins';
    font-display: block;
    src: url('/fonts/poppins/poppins-medium.ttf') format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Poppins';
    font-display: block;
    src: url('/fonts/poppins/poppins-semibold.ttf') format('truetype');
    font-weight: 600;
  }
`;

export const base = 16;

const fonts = {
  wulkan: 'Wulkan Display, sans-serif',
  poppins: 'Poppins, sans-serif',
};

export default fonts;
