import fetch from 'node-fetch';
import useSWR from 'swr';

export const fetcher = async (url) => {
  const res = await fetch(url);
  if (!res.ok) {
    throw new Error('An error occurred while fetching data');
  }
  return res.json();
};

const useFetch = (url, shouldFetch = true) => {
  const { data, error, mutate } = useSWR(shouldFetch ? url : null, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return {
    data,
    isLoading: !error && !data && shouldFetch,
    isError: error || data?.error,
    mutate,
  };
};

export default useFetch;
