import React from 'react';
import styled, { keyframes } from 'styled-components';
import * as Accordion from '@radix-ui/react-accordion';

import PackagePill from './PackagePill';

import { P3 } from 'styles/typography';
import FONTS from 'styles/fonts';
import COLORS from 'styles/colors';

const slideDown = keyframes`
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
`;

const Container = styled.div`
  display: grid;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.45rem;
  font-family: ${FONTS.poppins};
  font-size: 0.8125rem;
  line-height: 140%;
  text-align: left;
  background-color: inherit;
  color: inherit;
`;

const PackagePriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${P3} {
    margin: 0;
  }
`;

const DescriptionContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

const DescriptionText = styled.div`
  li {
    list-style-type: disc;
    margin-inline-start: 1.5rem;
  }

  p {
    margin: 0;
  }

  p,
  li {
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
  }

  p:first-of-type {
    margin-bottom: 1rem;
  }
`;

const ExpandableDescription = styled(Accordion.Content)`
  overflow: hidden;

  &[data-state='open'] {
    animation: ${slideDown} 300ms ease-out;
  }

  &[data-state='closed'] {
    animation: ${slideUp} 150ms ease-in;
  }
`;

const ExpandDescriptionButton = styled(Accordion.Trigger)`
  border: none;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  background: transparent;
  color: inherit;
  padding: 0;

  &[data-state='open'] {
    margin-top: 1.5rem;
    p::before {
      content: 'Close details';
    }
    span::before {
      content: ${(props) =>
        props.selected
          ? 'url(/images/icons/minus-small-white.svg)'
          : 'url(/images/icons/minus-small.svg)'};
    }
  }

  &[data-state='closed'] {
    p::before {
      content: 'See details';
    }
    span::before {
      content: ${(props) =>
        props.selected
          ? 'url(/images/icons/plus-small-white.svg)'
          : 'url(/images/icons/plus-small.svg)'};
    }
  }
`;

const ExpandDescriptionButtonText = styled.p`
  text-decoration: underline;
  margin: 0;
`;

const ExpandDescriptionButtonIcon = styled.span`
  margin-top: 0.1rem;
`;

const PackageCard = ({
  packageType = 'default',
  packagePrice,
  packageDescription,
  packageDescriptionExpanded,
  selected = false,
}) => {
  return (
    <Container>
      <PackagePriceContainer>
        <PackagePill name={packageType} openStyle={selected} />
        <P3 weight="semibold">{packagePrice}</P3>
      </PackagePriceContainer>

      <DescriptionContainer>
        <DescriptionText
          dangerouslySetInnerHTML={{ __html: packageDescription }}
        />
      </DescriptionContainer>

      {/* Expandable content */}
      <Accordion.Root type="single" collapsible>
        <Accordion.Item value={packageType}>
          <ExpandableDescription>
            <DescriptionText
              dangerouslySetInnerHTML={{ __html: packageDescriptionExpanded }}
            />
          </ExpandableDescription>
          <ExpandDescriptionButton
            selected={selected}
            asChild
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div>
              <ExpandDescriptionButtonText />
              <ExpandDescriptionButtonIcon />
            </div>
          </ExpandDescriptionButton>
        </Accordion.Item>
      </Accordion.Root>
    </Container>
  );
};

export default PackageCard;
