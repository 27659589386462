import styled, { css } from 'styled-components';

import ErrorSvg from 'public/images/icons/error.svg';
import Info from 'public/images/icons/info.svg';
import Time from 'public/images/icons/time.svg';

import FONTS from 'styles/fonts';
import COLORS from 'styles/colors';
import BREAKPOINTS from 'styles/breakpoints';
import { P2 } from 'styles/typography';

const nudgeColors = {
  default: COLORS.white,
  error: COLORS.pink,
  success: COLORS.sky,
  gametime: COLORS.tealMuted,
};

const nudgeIcons = {
  default: <Info />,
  error: <ErrorSvg />,
  success: <Info />,
  gametime: <Time />,
};

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 0.688rem;
  align-items: center;
  color: ${COLORS.forestDark};
  background-color: ${({ nudgeType }) => `${nudgeColors[nudgeType]}`};
  font-family: ${FONTS.poppins};
  border-radius: 0.5rem;

  padding: ${({ hasIcon }) =>
    hasIcon ? '0.5rem 1rem 0.5rem 0.5rem' : '0.5rem'};

  /* Adjust styles for rounded nudges  */
  ${({ rounded }) =>
    rounded &&
    css`
      gap: 0.5rem;
      border-radius: 6.25rem;
      padding: ${({ hasIcon }) =>
        hasIcon ? '0.25rem 1rem 0.25rem 0.25rem' : '0.5rem 1rem'};
    `}

  /* Game time nudge transparent on mobile */
  ${({ nudgeType }) =>
    nudgeType === 'gametime' &&
    css`
      background-color: transparent;
    `}

/* Transparent background nudges */
    ${({ hasBackground }) =>
    !hasBackground &&
    css`
      background-color: transparent;
      padding-left: 0;
      padding-block: 0;
    `}

  svg > path {
    fill: ${({ nudgeType }) => `${nudgeType === 'success' && 'currentColor'}`};
  }

  ${BREAKPOINTS.medium`
    ${({ rounded }) =>
      !rounded &&
      css`
        padding: ${({ hasIcon }) =>
          hasIcon ? '1rem 1rem 1rem 0.5rem' : '1rem'};
      `}


  ${({ nudgeType }) =>
    nudgeType === 'gametime' &&
    css`
      background-color: ${nudgeColors[nudgeType]};
      padding-left: 0.62rem;
      padding-block: 0.38rem;
    `}
  `}
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled(P2)`
  margin: 0;
  color: inherit;
`;

const Nudge = ({
  name = 'default',
  text,
  hasIcon = true,
  rounded = false,
  hasBackground = true,
  className = '',
}) => {
  const nudgeType = ['error', 'success', 'gametime'].includes(
    name.toLowerCase()
  )
    ? name.toLowerCase()
    : 'default';

  return (
    <Container
      nudgeType={nudgeType}
      hasIcon={hasIcon}
      rounded={rounded}
      hasBackground={hasBackground}
      className={className}
    >
      {hasIcon && <Icon>{nudgeIcons[nudgeType]}</Icon>}
      <Text>{text}</Text>
    </Container>
  );
};

export default Nudge;
