import AtreemoTrackingScript from './AtreemoTrackingScript';
import FbqFallbackScript from './FbqFallback';
import HotjarScript from './Hotjar';
import ServerGTMScript from './ServerGTM';

const ThirdPartyScripts = () => {
  return (
    <>
      <ServerGTMScript />
      <FbqFallbackScript />
      <AtreemoTrackingScript />
      <HotjarScript />
    </>
  );
};

export default ThirdPartyScripts;
