import { toISODateString } from 'utils/bookingSearchUtils';

import {
  BookingFlowMachineEvent,
  SearchData,
  ThreeDayAvailability,
  BookingCostInfo,
  BookingFlowUserInfo,
  BookingFlowBillingInfo,
  BookingFlowAdditionalNotes,
  API_SUCCESS_EVENTS,
  API_ERROR_EVENTS,
} from './types';
import {
  AllergenReservationTags,
  AvailableAddOn,
  AvailableAddOnsGroup,
  AvailablePackage,
  AvailablePackages,
  BookingPayment,
  CreateBookingData,
  DietaryReservationTags,
  InlineBookingFlowType,
  ReservationTag,
  SingleTimeSlot,
} from './apiSchemas';
import { CmsContent } from './cmsSchemas';
import { FreedomPayAttributes, FreedomPayPaymentKeys } from './freedomPay';
import { addMinutes } from 'date-fns';

const getLastIndexOfEvent = (
  events: BookingFlowMachineEvent[],
  eventType: BookingFlowMachineEvent['type']
): number | null => {
  let indexOfLastNudgeEvent: number | null = null;
  events.forEach((event, index) => {
    if (event.type === eventType) {
      indexOfLastNudgeEvent = index;
    }
  });

  return indexOfLastNudgeEvent;
};

const getLastIndexOfEventWithFallback = (
  events: BookingFlowMachineEvent[],
  eventType: BookingFlowMachineEvent['type']
) => getLastIndexOfEvent(events, eventType) ?? -1;

export const getInitialSearchData = (events: BookingFlowMachineEvent[]) => {
  return events.reduce((acc, event) => {
    switch (event.type) {
      case 'onInitialSearchData':
        return { ...event.searchData };

      default:
        return acc;
    }
  }, null as SearchData | null);
};

export const getFocusedAvailabilityDay = (
  events: BookingFlowMachineEvent[]
) => {
  return events.reduce((acc, event) => {
    switch (event.type) {
      case 'onInitialSearchData': {
        return event.searchData.When.date;
      }

      case 'onNudgeAvailability': {
        if (!acc) {
          return acc;
        }
        const nudgeByDay = event.direction === 'forwards' ? 1 : -1;
        const currentDate = new Date(acc);

        const newDate = new Date(
          currentDate.setDate(currentDate.getDate() + nudgeByDay)
        );

        // We want to ignore TZ here, since the next day could be in a
        // different TZ, but we still want to increment / decrement the day.
        const offsetNewDate = addMinutes(newDate, -newDate.getTimezoneOffset());

        return toISODateString(offsetNewDate);
      }

      default: {
        return acc;
      }
    }
  }, null as string | null);
};

export const getCombinedSearchData = (
  events: BookingFlowMachineEvent[]
): SearchData | null => {
  const initialSearchData = getInitialSearchData(events);
  const focusedAvailabilityDay = getFocusedAvailabilityDay(events);

  if (!initialSearchData || !focusedAvailabilityDay) {
    return null;
  }

  return {
    ...initialSearchData,
    When: {
      ...initialSearchData.When,
      date: focusedAvailabilityDay,
    },
  };
};

export const getIsReFetching = (events: BookingFlowMachineEvent[]): boolean => {
  /**
   * We can't use `findLastIndex` because we're not on node v18 yet, so we have
   * to do our own.
   */
  const indexOfLastNudgeEvent = getLastIndexOfEvent(
    events,
    'onNudgeAvailability'
  );

  if (indexOfLastNudgeEvent === null) {
    return false;
  }

  const eventsSinceLastNudge = events.slice(indexOfLastNudgeEvent + 1);

  const foundEvent = eventsSinceLastNudge.find(
    (e) =>
      e.type === 'onAvailabilityFetched' ||
      e.type === 'onAvailabilityFetchError'
  );

  return !foundEvent;
};

export const getThreeDayAvailability = (events: BookingFlowMachineEvent[]) => {
  return events.reduce((acc, event) => {
    if ('threeDayAvailability' in event) {
      return event.threeDayAvailability;
    }
    return acc;
  }, null as ThreeDayAvailability | null);
};

export const getSelectedTimeSlot = (events: BookingFlowMachineEvent[]) => {
  return events.reduce((acc, event) => {
    switch (event.type) {
      case 'onTimeSlotSelected':
        return event.timeSlot;

      default:
        return acc;
    }
  }, null as SingleTimeSlot | null);
};

export const getSelectedBookingFlowType = (
  events: BookingFlowMachineEvent[]
) => {
  return events.reduce((acc, event) => {
    switch (event.type) {
      case 'onTimeSlotSelected':
        return event.bookingFlowType;

      default:
        return acc;
    }
  }, null as InlineBookingFlowType | null);
};

export const getShouldShowGameAreaStep = (
  events: BookingFlowMachineEvent[]
) => {
  const booking = getBooking(events);

  if (booking?.gameArea?.show.length === 0) {
    return false;
  }

  return true;
};

export const getCmsContent = (
  events: BookingFlowMachineEvent[]
): CmsContent | null => {
  return events.reduce((acc, event) => {
    if ('cmsContent' in event) {
      return event.cmsContent;
    }
    return acc;
  }, null as CmsContent | null);
};

export const getTotalGameDuration = (events: BookingFlowMachineEvent[]) => {
  const baseTimeSlotDuration = events.reduce((acc, event) => {
    switch (event.type) {
      case 'onTimeSlotSelected':
        return event.timeSlot.duration;

      case 'onSelectAddOn':
      case 'onDeSelectAddOn':
        if (event.addOn.addOnType === 'time_extension') {
          return (
            acc +
            (event.type === 'onSelectAddOn'
              ? event.addOn.duration
              : -event.addOn.duration)
          );
        }

      default:
        return acc;
    }
  }, 0);

  return baseTimeSlotDuration;
};

const getIndexOfLastBookingRetrievalEvent = (
  events: BookingFlowMachineEvent[]
): number | null => {
  let indexOfLastBookingRetrievalEvent: number | null = null;
  events.forEach((event, index) => {
    if ('booking' in event) {
      indexOfLastBookingRetrievalEvent = index;
    }
  });
  return indexOfLastBookingRetrievalEvent;
};

const getEventsSinceLastBookingRetrieval = (
  events: BookingFlowMachineEvent[]
): BookingFlowMachineEvent[] => {
  const indexOfLastBookingRetrievalEvent =
    getIndexOfLastBookingRetrievalEvent(events);

  const eventsSinceLastBookingRetrieval =
    indexOfLastBookingRetrievalEvent === null
      ? []
      : events.slice(indexOfLastBookingRetrievalEvent + 1);

  return eventsSinceLastBookingRetrieval;
};

export const getSelectedPackageCost = (
  events: BookingFlowMachineEvent[]
): number => {
  const booking = getBooking(events);

  let cost = 0;

  if (booking) {
    cost = booking.packagesCost;

    const eventsSinceLastBookingRetrieval =
      getEventsSinceLastBookingRetrieval(events);

    const packageSelectionEventsSinceLastBookingRetrieval =
      eventsSinceLastBookingRetrieval.filter(
        (event) => event.type === 'onSelectPackage'
      );

    if (packageSelectionEventsSinceLastBookingRetrieval.length) {
      const newPackageCost =
        getSelectedPackage(packageSelectionEventsSinceLastBookingRetrieval)
          ?.charge ?? 0;
      cost = newPackageCost * booking.partySize;
    }
  }

  return cost;
};

export const getSelectedAddOnsCost = (
  events: BookingFlowMachineEvent[]
): number => {
  const booking = getBooking(events);
  let cost = 0;

  if (booking) {
    const selectedAddOns = new Map<string, AvailableAddOn>();

    // Process the events to track selected and deselected add-ons
    events.forEach((event) => {
      if (event.type === 'onSelectAddOn') {
        selectedAddOns.set(event.addOn.addOnType, event.addOn);
      } else if (event.type === 'onDeSelectAddOn') {
        selectedAddOns.delete(event.addOn.addOnType);
      }
    });

    // Calculate the cost of selected add-ons
    const totalAddOnsCost = Array.from(selectedAddOns.values()).reduce(
      (sum, addOn) => sum + addOn.charge,
      0
    );

    // Multiply by the party size if defined
    cost = totalAddOnsCost * booking.partySize;
  }

  return cost;
};

export const getTotalBookingCost = (
  events: BookingFlowMachineEvent[]
): BookingCostInfo => {
  const booking = getBooking(events);

  if (booking) {
    let totalCost = booking.totalCost;
    let totalCostPerPerson = totalCost / booking.partySize;

    // If there has been an `onSelectPackage` since the last
    // `onUpdatePackageSuccess` then calculate this dynamically.
    const indexOfLastPackageSelectionEvent = getLastIndexOfEventWithFallback(
      events,
      'onSelectPackage'
    );
    const indexOfLastPackageUpdateSuccessEvent =
      getLastIndexOfEventWithFallback(events, 'onUpdatePackageSuccess');
    if (
      indexOfLastPackageSelectionEvent > indexOfLastPackageUpdateSuccessEvent
    ) {
      const selectedPackage = getSelectedPackage(events);

      const costOfPreviousPackagesPerPerson = booking.packages.reduce(
        (acc, pkg) => acc + pkg.charge,
        0
      );

      const totalCostOfPreviousPackages =
        costOfPreviousPackagesPerPerson * booking.partySize;

      totalCost -= totalCostOfPreviousPackages;
      totalCostPerPerson -= costOfPreviousPackagesPerPerson;

      if (selectedPackage) {
        totalCost += selectedPackage.charge * booking.partySize;
        totalCostPerPerson += selectedPackage.charge;
      }
    }

    // If there has been an `onSelectAddOn` or `onDeSelectAddOn` since the last
    // `onUpdateAddOnsSuccess` then calculate this dynamically.
    const indexOfLastAddOnSelectionEvent = Math.max(
      getLastIndexOfEventWithFallback(events, 'onSelectAddOn'),
      getLastIndexOfEventWithFallback(events, 'onDeSelectAddOn')
    );
    const indexOfLastAddOnUpdateSuccessEvent = getLastIndexOfEventWithFallback(
      events,
      'onUpdateAddOnsSuccess'
    );
    if (indexOfLastAddOnSelectionEvent > indexOfLastAddOnUpdateSuccessEvent) {
      const selectedAddOns = getSelectedAddOns(events);

      const totalCostOfPreviousAddOns = booking.addOns.reduce(
        (acc, addOn) => acc + addOn.totalCost,
        0
      );
      const costOfPreviousAddOnsPerPerson =
        totalCostOfPreviousAddOns / booking.partySize;

      totalCost -= totalCostOfPreviousAddOns;
      totalCostPerPerson -= costOfPreviousAddOnsPerPerson;

      if (selectedAddOns.length) {
        const costOfSelectedAddOnsPerPerson = selectedAddOns.reduce(
          (acc, addOn) => acc + addOn.charge,
          0
        );
        const totalCostOfSelectedAddOns =
          costOfSelectedAddOnsPerPerson * booking.partySize;

        totalCost += totalCostOfSelectedAddOns;
        totalCostPerPerson += costOfSelectedAddOnsPerPerson;
      }
    }

    return {
      total: totalCost,
      perPerson: totalCostPerPerson,
    };
  }

  const perPerson = events.reduce((acc, event) => {
    switch (event.type) {
      case 'onTimeSlotSelected':
        return event.timeSlot.charge;

      default:
        return acc;
    }
  }, 0);

  const searchData = getCombinedSearchData(events);
  if (!searchData) {
    return {
      perPerson: 0,
      total: 0,
    };
  }

  return {
    perPerson,
    total: perPerson * searchData.Who,
  };
};

export const getAvailablePackages = (events: BookingFlowMachineEvent[]) => {
  return events.reduce((acc, event) => {
    if ('availablePackages' in event) {
      return event.availablePackages;
    }
    return acc;
  }, null as null | AvailablePackages);
};

export const getSelectedPackage = (events: BookingFlowMachineEvent[]) => {
  return events.reduce((acc, event) => {
    switch (event.type) {
      case 'onSelectPackage':
        return event.package;

      default:
        return acc;
    }
  }, null as null | AvailablePackage);
};

export const getBooking = (events: BookingFlowMachineEvent[]) => {
  return events.reduce((acc, event) => {
    if ('booking' in event) {
      return event.booking;
    }
    return acc;
  }, null as null | CreateBookingData);
};

export const getAvailableAddOns = (events: BookingFlowMachineEvent[]) => {
  return events.reduce((acc, event) => {
    if ('availableAddOns' in event) {
      return event.availableAddOns;
    }
    return acc;
  }, null as null | AvailableAddOnsGroup[]);
};

export const getSelectedAddOnsWithGroupKeys = (
  events: BookingFlowMachineEvent[]
) => {
  const addOns = events.reduce((acc, event) => {
    switch (event.type) {
      case 'onSelectAddOn': {
        if (!event.addOn.isAvailable) {
          return acc;
        }

        if (event.addOnGroupAllowMultiple) {
          return [
            ...acc,
            { addOn: event.addOn, addOnGroupKey: event.addOnGroupKey },
          ];
        }

        // If the group doesn't allow multiple, we need to remove any other
        // add-ons of the same group, before adding the selected add-on.
        const filtered = acc.filter(
          (a) => a.addOnGroupKey !== event.addOnGroupKey
        );

        return [
          ...filtered,
          { addOn: event.addOn, addOnGroupKey: event.addOnGroupKey },
        ];
      }

      case 'onDeSelectAddOn': {
        return acc.filter((a) => a.addOn.addOnType !== event.addOn.addOnType);
      }

      default:
        return acc;
    }
  }, [] as { addOn: AvailableAddOn; addOnGroupKey: AvailableAddOnsGroup['key'] }[]);

  return addOns;
};

export const getSelectedAddOns = (events: BookingFlowMachineEvent[]) => {
  return getSelectedAddOnsWithGroupKeys(events).map((a) => a.addOn);
};

export const getUserInfo = (events: BookingFlowMachineEvent[]) => {
  return events.reduce((acc, event) => {
    if ('userInfo' in event) {
      return event.userInfo;
    }
    return acc;
  }, null as null | BookingFlowUserInfo);
};

export const getBillingInfo = (events: BookingFlowMachineEvent[]) => {
  return events.reduce((acc, event) => {
    if ('billingInfo' in event) {
      return event.billingInfo;
    }
    return acc;
  }, null as null | BookingFlowBillingInfo);
};

export const getAdditionalNotes = (events: BookingFlowMachineEvent[]) => {
  return events.reduce((acc, event) => {
    if ('additionalNotes' in event) {
      return event.additionalNotes;
    }
    return acc;
  }, null as null | BookingFlowAdditionalNotes);
};

export const getBookingPayment = (events: BookingFlowMachineEvent[]) => {
  return events.reduce((acc, event) => {
    if ('bookingPayment' in event) {
      return event.bookingPayment;
    }
    return acc;
  }, null as null | BookingPayment);
};

export const getDietaryReservationTags = (
  events: BookingFlowMachineEvent[]
) => {
  return events.reduce((acc, event) => {
    if ('dietaryReservationTags' in event) {
      return event.dietaryReservationTags;
    }
    return acc;
  }, null as null | DietaryReservationTags);
};

export const getAllergenReservationTags = (
  events: BookingFlowMachineEvent[]
) => {
  return events.reduce((acc, event) => {
    if ('allergenReservationTags' in event) {
      return event.allergenReservationTags;
    }
    return acc;
  }, null as null | AllergenReservationTags);
};

export const getReservationTags = (events: BookingFlowMachineEvent[]) => {
  const dietaryReservationTags = getDietaryReservationTags(events);
  const allergenReservationTags = getAllergenReservationTags(events);

  return {
    dietaryReservationTags,
    allergenReservationTags,
  };
};

export const getSelectedReservationTags = (
  events: BookingFlowMachineEvent[]
) => {
  return events.reduce((acc, event) => {
    switch (event.type) {
      case 'onSelectReservationTag':
        // Otherwise add the reservation tag to `acc` if it's not already there.
        return [
          ...acc.filter((tag) => tag.id !== event.reservationTag?.id),
          event.reservationTag,
        ];

      case 'onDeSelectReservationTag':
        return acc.filter((tag) => tag.id !== event.reservationTag?.id);

      default:
        return acc;
    }
  }, [] as ReservationTag[]);
};

export const getLastApiErrorEvent = (events: BookingFlowMachineEvent[]) => {
  // We need to know if there's been an error event since the last success
  // event.
  let indexOfLastApiSuccessEvent: number = -1;
  let indexOfLastApiErrorEvent: number = -1;
  events.forEach((event, index) => {
    if (API_SUCCESS_EVENTS.includes(event.type)) {
      indexOfLastApiSuccessEvent = index;
    }
    if (API_ERROR_EVENTS.includes(event.type)) {
      indexOfLastApiErrorEvent = index;
    }
  });

  if (indexOfLastApiSuccessEvent >= indexOfLastApiErrorEvent) {
    return null;
  }

  return events[indexOfLastApiErrorEvent];
};

export const getIsFreedomPayReady = (events: BookingFlowMachineEvent[]) => {
  const indexOfLastOnPaymentInitialisedEvent =
    getLastIndexOfEvent(events, 'onPaymentInitialised') ?? -1;
  const indexOfLastOnFreedomPayReadyEvent =
    getLastIndexOfEvent(events, 'onFreedomPayReady') ?? -1;
  return (
    indexOfLastOnFreedomPayReadyEvent >= indexOfLastOnPaymentInitialisedEvent
  );
};

const getPaymentKeys = (events: BookingFlowMachineEvent[]) => {
  return events.reduce((acc, event) => {
    if ('paymentKeys' in event) {
      return event.paymentKeys;
    }
    return acc;
  }, null as null | FreedomPayPaymentKeys);
};

const getPaymentAttributes = (events: BookingFlowMachineEvent[]) => {
  return events.reduce((acc, event) => {
    if ('paymentAttributes' in event) {
      return event.paymentAttributes;
    }
    return acc;
  }, null as null | FreedomPayAttributes);
};

export const getPaymentData = (events: BookingFlowMachineEvent[]) => {
  const paymentKeys = getPaymentKeys(events);
  const paymentAttributes = getPaymentAttributes(events);

  return {
    paymentKeys,
    paymentAttributes,
  };
};

export const getPromoCodeState = (events: BookingFlowMachineEvent[]) => {
  const indexOfLastOnApplyPromoCode =
    getLastIndexOfEvent(events, 'onApplyPromoCode') ?? -1;

  const indexOfLastOnApplyPromoCodeSuccess =
    getLastIndexOfEvent(events, 'onApplyPromoCodeSuccess') ?? -1;

  const indexOfLastOnApplyPromoCodeError =
    getLastIndexOfEvent(events, 'onApplyPromoCodeError') ?? -1;

  const isSubmitting =
    indexOfLastOnApplyPromoCode > indexOfLastOnApplyPromoCodeSuccess &&
    indexOfLastOnApplyPromoCode > indexOfLastOnApplyPromoCodeError;

  const isError =
    indexOfLastOnApplyPromoCodeError > indexOfLastOnApplyPromoCode;

  return {
    isSubmitting,
    isError,
  };
};
