import * as React from 'react';

import { useBookingFlowManager } from './Provider';

const FALLBACK_MESSAGE =
  "Sorry, we couldn't process your payment. Please try again.";

export const useApiErrorMessage = (): string | null => {
  const { lastApiErrorEvent } = useBookingFlowManager();

  return React.useMemo(() => {
    switch (lastApiErrorEvent?.type) {
      case 'onAvailabilityFetchError':
        return "Sorry, we couldn't find any available times for your search. Please try again.";

      case 'onCreateBookingError':
        return "Sorry, we couldn't create your booking. Please try again.";

      case 'onUpdatePackageError':
        return "Sorry, we couldn't update your package. Please try again.";

      case 'onUpdateAddOnsError':
        return "Sorry, we couldn't update your extras. Please try again.";

      case 'onPaymentInitialisationError':
        return "Sorry, we couldn't initialise your payment. Please try again.";

      case 'onPaymentError':
        // We attempt to handle soft declines silently.
        if (lastApiErrorEvent.softDecline) {
          return null;
        }
        return FALLBACK_MESSAGE;

      case 'onFreedomPayError':
        if (lastApiErrorEvent.errors === 'rejected') {
          return FALLBACK_MESSAGE;
        }

        const error = lastApiErrorEvent.errors.find(Boolean);
        if (error?.message) {
          return error.message;
        }
        return FALLBACK_MESSAGE;

      default:
        break;
    }

    if (lastApiErrorEvent) {
      console.error('Unhandled API error event:', lastApiErrorEvent);
      return 'Sorry, something went wrong. Please try again.';
    }

    return null;
  }, [lastApiErrorEvent]);
};
