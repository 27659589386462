const formValidation = {
  firstName: { required: 'Please enter your first name' },
  lastName: { required: 'Please enter your last name' },
  email: {
    required: 'Please enter your email address',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Please enter a valid email address',
    },
  },
  password: {
    required: 'Please enter your password',
    minLength: {
      value: 8,
      message: 'Password must be at least 8 characters',
    },
    pattern: {
      value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
      message: 'Password must include an upper case, lower case, and a number.',
    },
  },
  passwordHelpText:
    'Use at least 8 characters and include an upper case, lower case, and a number.',
  confirmPassword: 'Passwords must match',
  subject: { required: 'Please choose a subject' },
  message: {
    required: 'Please enter a message',
    maxLength: {
      value: 1000,
      message: 'You have exceeded the 1000 character limit',
    },
  },
  googleReCaptcha: 'Sorry, there was an unexpected problem with ReCaptcha',
  googleReCaptchaExpired:
    'ReCaptcha session has expired, please check the checkbox again.',
};

export const isValidEmail = (email) => {
  const re = formValidation.email.pattern.value;
  return re.test(email);
};

export default formValidation;
