import React from 'react';

import styled from 'styled-components';

import ChevronSvg from 'public/images/icons/chevron.svg';

import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';
import { P2 } from 'styles/typography';

const Container = styled.div`
  font-size: 0.8125rem;
  font-family: ${FONTS.poppins};
`;

const SelectContainer = styled.div`
  position: relative;
`;

const SelectDropDown = styled.select`
  font-size: 0.8125rem;
  padding: 0.69rem 2rem 0.69rem 1rem;
  color: ${COLORS.forestBlack};
  appearance: none;
  border: none;
  border: 1px solid ${COLORS.blueGrey};
  border-radius: 0.5rem;
  width: 100%;
  background: transparent;
  outline: none;
  cursor: pointer;
`;

const CustomArrow = styled.span`
  position: absolute;
  top: 0;
  right: 0.5rem;
  height: 100%;
  display: grid;
  place-items: center;
  pointer-events: none;
  width: 1rem;
  svg {
    margin-top: -0.3rem;
    path {
      stroke: ${COLORS.blueGrey};
    }
  }
`;

const InputLabel = styled.label`
  font-size: 0.625rem;
  color: ${COLORS.forestDark};
  margin-bottom: 0.5rem;
  display: inline-block;
`;

const ErrorMessage = styled(P2)`
  margin: 0;
  font-size: 0.625rem;
  color: ${COLORS.orange};
  margin-top: 0.25rem;
`;

export type SelectProps = {
  className?: string;
  name: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  required?: boolean;
  errorMessage?: string;
  options:
    | string[]
    | {
        value: string;
        label: string;
      }[];
  placeholderText?: string;
  onBlur?: () => void;
};
const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      className,
      name,
      label,
      onChange,
      value,
      required = true,
      errorMessage,
      options,
      placeholderText,
      ...props
    },
    ref
  ) => {
    return (
      <Container className={className}>
        {label && <InputLabel htmlFor={`input-${name}`}>{label}</InputLabel>}{' '}
        <SelectContainer>
          <SelectDropDown
            ref={ref}
            name={name}
            id={`input-${name}`}
            onChange={onChange}
            required={required}
            {...props}
            value={value}
          >
            <option value="" disabled hidden>
              {placeholderText}
            </option>

            {options.map((option) => {
              if (typeof option === 'object') {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                );
              }

              return (
                <option key={option} value={option}>
                  {option}
                </option>
              );
            })}
          </SelectDropDown>

          <CustomArrow>
            <ChevronSvg />
          </CustomArrow>
        </SelectContainer>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Container>
    );
  }
);

export default Select;
