import * as React from 'react';
import WhereContent from '../components/SearchBar/WhereContent';
import WhenContent from '../components/SearchBar/WhenContent';
import WhoContent from '../components/SearchBar/WhoContent';
import OccasionContent from '../components/SearchBar/OccasionContent';
import {
  formatDateTimeDisplay,
  formatWhoValue,
} from 'utils/bookingSearchUtils';
import { useNav } from './NavProvider';
import { datalayerPush } from '../components/ThirdParty/GtmDatalayerPush';
import { useContextApi } from '../hooks/useContext';
import { useSession, signIn, signOut } from 'next-auth/react';

import useFetch from 'hooks/useFetch';

const BookingSearchContext = React.createContext();

export const useBookingSearch = () => React.useContext(BookingSearchContext);

const BookingSearchProvider = ({ children }) => {
  const { isSearchModalOpen, setSearchModalOpen, setIsBookingModalOpen } =
    useNav();
  const { createContext } = useContextApi();
  const { data: session, status } = useSession();

  const initalSearchData = {
    Where: { id: '', label: '' },
    When: {
      date: '',
      time: '',
    },
    Who: 0,
    Occasion: {
      id: '',
      name: '',
    },
  };

  const [searchData, setSearchData] = React.useState(initalSearchData);

  const [searchErrors, setSearchErrors] = React.useState({
    Where: false,
    When: false,
    Who: false,
    Occasion: false,
  });

  const { data: occasions, isError: isOccasionError } = useFetch(
    '/api/sevenrooms/getOccasions'
  );

  const updateSearchData = (key, value) => {
    setSearchData((prevData) => ({
      ...prevData,
      [key]: value,
    }));

    setSearchErrors((prevErrors) => ({
      ...prevErrors,
      [key]: false,
    }));
  };

  const clearSearchData = () => {
    setSearchData(initalSearchData);
    setSearchErrors({
      Where: false,
      When: false,
      Who: false,
      Occasion: false,
    });
  };

  const handleCreateContext = () => {
    const payload = {
      venue_id: searchData.Where.id,
      party_size: searchData.Who,
      search_date: searchData.When.date,
      search_time: searchData.When.time,
      occasion_id: searchData.Occasion.id,
      session_id: session?.id,
      source_path: window.location.pathname,
    };
    createContext(payload);
  };

  const submitSearchData = () => {
    const newErrors = {
      Where: !searchData.Where.id,
      When: !searchData.When.date || !searchData.When.time,
      Who: searchData.Who <= 0,
      Occasion: searchData.Occasion.id === '',
    };

    datalayerPush({ event: 'searchSubmitted', searchData });

    setSearchErrors(newErrors);
    const hasErrors = Object.values(newErrors).some((isError) => isError);

    if (!hasErrors) {
      // Close the search modal and go to the Loading Step
      if (isSearchModalOpen) {
        setSearchModalOpen(false);
      }
      setIsBookingModalOpen(true);
      handleCreateContext();
    }
  };

  const searchBarConfig = React.useMemo(
    () => [
      {
        title: 'Where',
        placeholder: 'Choose location',
        content: WhereContent,
        value: searchData.Where.label,
      },
      {
        title: 'When',
        placeholder: 'Add date & time',
        content: WhenContent,
        value:
          searchData.When.date && searchData.When.time
            ? formatDateTimeDisplay(searchData.When.date, searchData.When.time)
            : '',
      },
      {
        title: 'Who',
        placeholder: 'Add group',
        content: WhoContent,
        value: formatWhoValue(searchData.Who),
      },
      {
        title: 'Occasion',
        placeholder: 'Add occasion',
        content: OccasionContent,
        value: searchData.Occasion.name,
      },
    ],
    [searchData]
  );

  const value = {
    searchData,
    searchErrors,
    setSearchErrors,
    updateSearchData,
    clearSearchData,
    searchBarConfig,
    occasions: isOccasionError ? null : occasions,
    submitSearchData,
  };

  return (
    <BookingSearchContext.Provider value={value}>
      {children}
    </BookingSearchContext.Provider>
  );
};

export default BookingSearchProvider;
