import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Controller } from 'react-hook-form';
import * as React from 'react';

import Input from '../Input';
import Nudge from '../Nudge';
import Select from '../Select';
import Checkbox from '../Checkbox';
import PaymentCardsList from '../PaymentCardsList';
import GoogleReCaptcha from 'src/components/GroupBookingsForm/GoogleReCaptcha';
import { countriesWithCountryCode } from 'utils/countriesWithDialCodes';

import COLORS from 'styles/colors';
import BREAKPOINTS, { breakpointSizes } from 'styles/breakpoints';
import { useBookingFlowManager } from 'src/context/bookingFlow';
import SummaryTabInline from '../SummaryTabInline';

import { observer } from 'mobx-react-lite';
import { ConsentStore } from 'src/stores/ConsentStore';

const Step = styled.div`
  padding: 1rem;
  ${BREAKPOINTS.medium`
          padding: 1.5rem;
    `}
`;

const Form = styled.div`
  background-color: ${COLORS.white};
  padding: 1rem;
  border-radius: 0.5rem;
  display: grid;
  gap: 1rem;
`;

const LabelWithLink = styled.span`
  a {
    color: inherit;
    font-weight: normal;
  }
`;

const StyledPaymentCardsList = styled(PaymentCardsList)`
  margin-top: 1rem;
  ${BREAKPOINTS.medium`
        margin-top: 1.65rem;
    `}
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 1rem;

  margin-top: 1rem;

  ${BREAKPOINTS.medium`
    grid-template-columns: 1fr 1fr;
    margin-top: 1.5rem;
    `}
`;

const CancellationPolicyLabel = () => (
  <LabelWithLink>
    I agree to the{' '}
    <a
      href="https://clays.bar/terms-and-conditions"
      target="_blank"
      rel="noreferrer"
    >
      Cancellation Policy
    </a>
    *
  </LabelWithLink>
);

const PrivacyPolicyLabel = () => (
  <LabelWithLink>
    I have read and accept the{' '}
    <a href="https://clays.bar/privacy-policy" target="_blank" rel="noreferrer">
      Privacy Policy
    </a>{' '}
    *
  </LabelWithLink>
);

const BillingInfoStep = () => {
  const { billingInfoForm } = useBookingFlowManager();
  const [captchaSuccess, setCaptchaSuccess] = React.useState(false);

  const { control, trigger } = billingInfoForm;

  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${breakpointSizes.medium}px)`,
  });

  return (
    <Step>
      <Nudge
        text="All transactions are secure and encrypted."
        rounded
        hasBackground={isDesktopOrLaptop ? true : false}
        hasIcon={isDesktopOrLaptop ? true : false}
      />

      <StyledPaymentCardsList />

      <Container>
        {ConsentStore.allCookiesAccepted && !captchaSuccess ? (
          <div>
            <GoogleReCaptcha
              onSuccess={() => {
                setCaptchaSuccess(true);
              }}
            />
          </div>
        ) : (
          <Form>
            <Controller
              name="name"
              control={control}
              rules={{
                required: { value: true, message: 'Name is required' },
              }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  label="Name on card"
                  {...field}
                  trigger={trigger}
                  errorMessage={error?.message}
                />
              )}
            />

            <Controller
              name="addressLine1"
              control={control}
              rules={{
                required: { value: true, message: 'Address is required' },
              }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  label="Address line 1"
                  {...field}
                  trigger={trigger}
                  errorMessage={error?.message}
                />
              )}
            />

            <Controller
              name="addressLine2"
              control={control}
              render={({ field }) => (
                <Input
                  label="Address line 2 (optional)"
                  {...field}
                  trigger={trigger}
                />
              )}
            />

            <Controller
              name="city"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Town or City is required',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  label="Town/City"
                  {...field}
                  trigger={trigger}
                  errorMessage={error?.message}
                />
              )}
            />

            <Controller
              name="postcode"
              control={control}
              rules={{
                required: { value: true, message: 'Postcode is required' },
              }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  label="Postcode"
                  {...field}
                  trigger={trigger}
                  errorMessage={error?.message}
                />
              )}
            />

            <Controller
              name="country"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Please select your country',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  label="Country"
                  errorMessage={error?.message}
                  options={countriesWithCountryCode}
                />
              )}
            />

            <Controller
              name="cancellationPolicy"
              rules={{
                required: {
                  value: true,
                  message: 'Please accept the cancellation policy',
                },
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Checkbox
                  labelSize="large"
                  label={<CancellationPolicyLabel />}
                  {...field}
                  errorMessage={error?.message}
                  onChange={(value) => {
                    field.onChange(value);
                    trigger(field.name);
                  }}
                />
              )}
            />

            <Controller
              name="ageConfirmation"
              rules={{
                required: {
                  value: true,
                  message: 'Please confirm that you are over the age of 18',
                },
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Checkbox
                  labelSize="large"
                  label="I confirm that I and my guests are over the age of 18*"
                  {...field}
                  errorMessage={error?.message}
                  onChange={(value) => {
                    field.onChange(value);
                    trigger(field.name);
                  }}
                />
              )}
            />

            <Controller
              name="privacyPolicy"
              rules={{
                required: {
                  value: true,
                  message: 'Please accept the privacy policy',
                },
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Checkbox
                  labelSize="large"
                  label={<PrivacyPolicyLabel />}
                  {...field}
                  errorMessage={error?.message}
                  onChange={(value) => {
                    field.onChange(value);
                    trigger(field.name);
                  }}
                />
              )}
            />
          </Form>
        )}

        {isDesktopOrLaptop && <SummaryTabInline />}
      </Container>
    </Step>
  );
};

export default observer(BillingInfoStep);
