import styled from 'styled-components';
import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';
import BREAKPOINTS from 'styles/breakpoints';

const TimeSelectWrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 6.0625rem;
  height: 2.9rem;
  padding: 0.31rem 1rem 0.2rem;
  background-color: ${({ selected }) =>
    selected ? COLORS.forest : COLORS.white};
  border: transparent;
  border-radius: 0.5rem;
  font-family: ${FONTS.poppins};
  color: ${({ selected }) => (selected ? COLORS.white : COLORS.forestDark)};
  text-align: center;
  cursor: pointer;
  flex-shrink: 0;

  &:disabled {
    pointer-events: none;
    background-color: ${COLORS.forestDark.opacity(0.1)};
    color: ${COLORS.forestDark.opacity(0.5)};
  }

  &:focus-visible {
    outline: 1px solid -webkit-focus-ring-color;
  }

  ${BREAKPOINTS.medium`
    width: 5rem;
    height: 2.9rem;
    padding: 0.31rem 0.5rem;
  `}
`;

const Time = styled.span`
  display: block;
  font-size: 0.813rem;
`;

const Price = styled.span`
  font-size: 0.625rem;
`;

const SPECIAL_DATES = ['2024-11-29', '2024-11-30'];

const TimeSelect = ({
  time,
  price,
  selected = false,
  disabled = false,
  onClick,
  d,
  where,
}) => {
  const { requestedDay } = d;

  return (
    <TimeSelectWrapper
      onClick={onClick}
      selected={selected}
      disabled={disabled}
    >
      <Time>{time}</Time>
      <Price>
        £{price} pp{' '}
        {where === 'Birmingham' && SPECIAL_DATES.includes(requestedDay) ? (
          <span>*</span>
        ) : null}
      </Price>
    </TimeSelectWrapper>
  );
};

export default TimeSelect;
