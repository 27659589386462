import * as zod from 'zod';
import sanitizeHtml from 'sanitize-html';

/**
 * Shared
 */
const chargeTypeSchema = zod.literal('person');

export const knownAddOnTypesSchema = zod.union([
  zod.literal('time_extension'),
  zod.literal('pre_game_outdoor_table'),
  zod.literal('post_game_outdoor_table'),
]);

export type KnownAddOnType = zod.infer<typeof knownAddOnTypesSchema>;

const stringCostSchema = zod.string().transform((val) => parseFloat(val));

const htmlSchema = zod.string().transform((val) => sanitizeHtml(val));

const allergenReservationTagSchema = zod.literal('allergen');
const dietaryReservationTagSchema = zod.literal('dietary');
const occasionReservationTagSchema = zod.literal('occasion');

const reservationTagTypesSchema = zod.union([
  allergenReservationTagSchema,
  dietaryReservationTagSchema,
  occasionReservationTagSchema,
]);

const reservationTagSchema = zod
  .object({
    type: reservationTagTypesSchema,
    id: zod.string(),
    display_name: zod.string(),
  })
  .transform(({ display_name, ...rest }) => ({
    displayName: display_name,
    ...rest,
  }));

const reservationTagsSchema = zod.array(reservationTagSchema);

export type ReservationTag = zod.infer<typeof reservationTagSchema>;

/**
 * Fetch availability
 */
const accessRuleSchema = zod
  .object({
    venue_id: zod.string(),
  })
  .transform(({ venue_id }) => ({
    venueId: venue_id,
  }));

const singleTimeSlotSchema = zod
  .object({
    utc_datetime: zod.string().nullable().optional(),
    charge: zod.number(),
    access_persistent_id: zod.string().nullable().optional(),
    shift_persistent_id: zod.string().nullable().optional(),
    time_iso: zod.string(),
    time: zod.string(),
    type: zod.union([zod.literal('request'), zod.literal('book')]),
    duration: zod.number().nullable().optional(),
    charge_type: chargeTypeSchema,
    access_rule: accessRuleSchema.optional(),
  })
  .transform(
    ({
      access_persistent_id,
      shift_persistent_id,
      utc_datetime,
      time_iso,
      charge_type,
      access_rule,
      ...rest
    }) => ({
      accessPersistentId: access_persistent_id,
      shiftPersistentId: shift_persistent_id,
      timeIso: time_iso,
      chargeType: charge_type,
      accessRule: access_rule,
      ...rest,
    })
  );

export type SingleTimeSlot = zod.infer<typeof singleTimeSlotSchema>;

const singleAvailabilitySchema = zod
  .object({
    name: zod.string(),
    shift_persistent_id: zod.string().nullable().optional(),
    shift_id: zod.string(),
    shift_category: zod.string(),
    times: zod.array(singleTimeSlotSchema),
  })
  .transform(({ shift_persistent_id, shift_id, shift_category, ...rest }) => ({
    shiftPersistentId: shift_persistent_id,
    shiftId: shift_id,
    shiftCategory: shift_category,
    ...rest,
  }));

const redirectBookingFlowTypes = zod.union([
  zod.literal('contact_corporate'),
  zod.literal('contact_individual'),
]);

const inlineBookingFlowType = zod.union([
  zod.literal('online_peg'),
  zod.literal('online_social'),
]);

export type InlineBookingFlowType = zod.infer<typeof inlineBookingFlowType>;

const gameAreaType = zod.union([zod.literal('peg'), zod.literal('social')]);

export type GameAreaType = zod.infer<typeof gameAreaType>;

export const gameAreaSchema = zod.object({
  selected: gameAreaType.nullable(),
  show: zod.array(gameAreaType),
});

export type GameArea = zod.infer<typeof gameAreaSchema>;

const fetchAvailabilityDataSchema = zod
  .object({
    availability_type: zod.string(),
    booking_flow_type: inlineBookingFlowType,
    availability: zod.array(singleAvailabilitySchema),
  })
  .transform(({ availability, availability_type, booking_flow_type }) => ({
    availabilityType: availability_type,
    bookingFlowType: booking_flow_type,
    availability,
  }));

export const singleDayAvailabilitySchema = zod.object({
  status: zod.literal(200),
  data: fetchAvailabilityDataSchema,
});

export type FetchAvailabilitySingleDayAvailability = zod.infer<
  typeof singleDayAvailabilitySchema
>;

export const singleDayAvailabilitySchemaCorporate = zod.object({
  data: zod.object({
    booking_flow_type: redirectBookingFlowTypes,
  }),
});

export type FetchAvailabilitySingleDayAvailabilityCorporate = zod.infer<
  typeof singleDayAvailabilitySchema
>;

export const fetchAvailabilityResponseSchema = zod.object({
  currentDay: singleDayAvailabilitySchema,
  currentDayPlusOne: singleDayAvailabilitySchema,
  currentDayPlusTwo: singleDayAvailabilitySchema,
});

export type FetchAvailabilityResponsePayload = zod.infer<
  typeof fetchAvailabilityResponseSchema
>;

/**
 * Create booking
 */
export type CreateBookingInput = {
  venueId: string;
  reservationDate: string;
  reservationTime: string;
  partySize: number;
  accessPersistentId: string;
  shiftPersistentId: string;
  occasionId: string | null;
};

const packageSchema = zod
  .object({
    charge: stringCostSchema,
    charge_type: chargeTypeSchema,
    package_id: zod.string(),
  })
  .transform(({ charge_type, package_id, ...rest }) => ({
    chargeType: charge_type,
    packageId: package_id,
    ...rest,
  }));

const addOnSchema = zod
  .object({
    add_on_type: knownAddOnTypesSchema,
    charge: stringCostSchema,
    duration: zod.number(),
    charge_type: chargeTypeSchema,
    name: zod.string(),
    quantity: zod.number(),
    total_cost: stringCostSchema,
  })
  .transform(({ add_on_type, charge_type, total_cost, ...rest }) => ({
    addOnType: add_on_type,
    chargeType: charge_type,
    totalCost: total_cost,
    ...rest,
  }));

const bookingBillingSchema = zod
  .object({
    description: zod.string(),
    transaction_id: zod.string(),
    transaction_total: stringCostSchema,
    currency_code: zod.string(),
    name_on_card: zod.string(),
    email: zod.string(),
    salutation: zod.string(),
    first_name: zod.string(),
    last_name: zod.string(),
    phone: zod.string(),
    address: zod.string(),
    address_2: zod.string(),
    city: zod.string(),
    state: zod.string(),
    post_code: zod.string(),
    country_code: zod.string(),
    status: zod.string(),
    marketing_opt_in: zod.boolean(),
    notes: zod.string(),
    date_of_birth: zod.string(),
  })
  .transform(
    ({
      transaction_total,
      marketing_opt_in,
      date_of_birth,
      address,
      address_2,
      country_code,
      currency_code,
      first_name,
      last_name,
      name_on_card,
      post_code,
      transaction_id,
      ...rest
    }) => ({
      transactionTotal: transaction_total,
      marketingOptIn: marketing_opt_in,
      dateOfBirth: date_of_birth,
      addressLine1: address,
      addressLine2: address_2,
      countryCode: country_code,
      currencyCode: currency_code,
      firstName: first_name,
      lastName: last_name,
      nameOnCard: name_on_card,
      postCode: post_code,
      transactionId: transaction_id,
      ...rest,
    })
  );

const promoCodeSchema = zod.object({
  code: zod.string(),
  description: zod.string(),
  type: zod.string(),
  value: zod.string().transform((val) => parseFloat(val)),
});

const createBookingDataSchema = zod
  .object({
    access_persistent_id: zod.string(),
    booking_id: zod.string(),
    charge: stringCostSchema,
    charge_type: chargeTypeSchema,
    currency_code: zod.string(),
    description: zod.string(),
    is_package_required: zod.boolean(),
    party_size: zod.number(),
    reservation_date: zod.string(),
    reservation_time: zod.string(),
    shift_persistent_id: zod.string(),
    total_cost: stringCostSchema,
    reservation_cost: stringCostSchema,
    packages_cost: stringCostSchema,
    packages: zod.array(packageSchema),
    add_ons: zod.array(addOnSchema),
    venue_id: zod.string(),
    venue_name: zod.string(),
    reservation_tags: reservationTagsSchema,
    billing: bookingBillingSchema.optional(),
    deposit_amount: stringCostSchema,
    add_ons_cost: stringCostSchema,
    promo_code: promoCodeSchema.optional(),
    promo_code_discount: stringCostSchema.optional(),
    game_area: gameAreaSchema.optional(),
  })
  .transform(
    ({
      access_persistent_id,
      booking_id,
      charge_type,
      currency_code,
      party_size,
      reservation_date,
      reservation_time,
      shift_persistent_id,
      total_cost,
      reservation_cost,
      packages_cost,
      venue_id,
      venue_name,
      is_package_required,
      add_ons,
      reservation_tags,
      deposit_amount,
      add_ons_cost,
      promo_code,
      promo_code_discount,
      game_area,
      ...rest
    }) => ({
      promoCodeDiscount: promo_code_discount,
      accessPersistentId: access_persistent_id,
      bookingId: booking_id,
      chargeType: charge_type,
      currencyCode: currency_code,
      partySize: party_size,
      reservationDate: reservation_date,
      reservationTime: reservation_time,
      shiftPersistentId: shift_persistent_id,
      totalCost: total_cost,
      reservationCost: reservation_cost,
      packagesCost: packages_cost,
      venueId: venue_id,
      venueName: venue_name,
      addOns: add_ons,
      isPackageRequired: is_package_required,
      reservationTags: reservation_tags,
      depositAmount: deposit_amount,
      addOnsCost: add_ons_cost,
      promoCode: promo_code,
      gameArea: game_area,
      ...rest,
    })
  );

export type CreateBookingData = zod.infer<typeof createBookingDataSchema>;
export const createBookingResponseSchema = zod.object({
  status: zod.literal(200),
  data: createBookingDataSchema,
});

export const completeBookingPaymentAsyncResultSchema = zod.discriminatedUnion(
  'status',
  [
    zod.object({
      status: zod.literal(200),
      data: createBookingDataSchema,
    }),
    zod.object({
      status: zod.literal(202),
    }),
    zod.object({
      status: zod.literal(400),
      errorMessage: zod.string(),
      errors: zod
        .object({
          payment: zod.array(zod.string()),
        })
        .nullable()
        .optional(),
    }),
  ]
);

/**
 * Get available packages
 */
export type GetAvailablePackagesInput = {
  bookingId: string;
};

const availablePackageSchema = zod
  .object({
    charge: zod.number(),
    charge_type: chargeTypeSchema,
    description: htmlSchema,
    description_short: htmlSchema,
    name: zod.string(),
    package_id: zod.string(),
  })
  .transform(({ charge_type, description_short, package_id, ...rest }) => ({
    chargeType: charge_type,
    descriptionShort: description_short,
    packageId: package_id,
    ...rest,
  }));

export type AvailablePackage = zod.infer<typeof availablePackageSchema>;

const availablePackagesSchema = zod.array(availablePackageSchema);
export type AvailablePackages = zod.infer<typeof availablePackagesSchema>;

const getAvailablePackagesDataSchema = zod.object({
  packages: availablePackagesSchema,
});

export type GetAvailablePackagesData = zod.infer<
  typeof getAvailablePackagesDataSchema
>;

export const getAvailablePackagesResponseSchema = zod.object({
  status: zod.literal(200),
  data: getAvailablePackagesDataSchema,
});

/**
 * Get available add-ons
 */
export type GetAvailableAddOnsInput = {
  bookingId: string;
};

export type AvailableAddOnsGroup = {
  key: 'playing_time' | 'outdoor_table';
  title: string;
  description: string;
  allowMultiple: boolean;
  addOns: AvailableAddOn[];
};

const availableAddOnSchema = zod
  .object({
    add_on_type: knownAddOnTypesSchema,
    charge: stringCostSchema,
    charge_type: chargeTypeSchema,
    duration: zod.number(),
    is_available: zod.boolean(),
    name: zod.string(),
    quantity: zod.number(),
    total_cost: stringCostSchema,
  })
  .transform(
    ({ charge_type, add_on_type, is_available, total_cost, ...rest }) => ({
      chargeType: charge_type,
      addOnType: add_on_type,
      isAvailable: is_available,
      totalCost: total_cost,
      ...rest,
    })
  );

export type AvailableAddOn = zod.infer<typeof availableAddOnSchema>;

const getAvailableAddOnsDataSchema = zod
  .object({
    add_ons: zod.array(zod.any()),
  })
  .transform(({ add_ons }) => {
    const addOns: AvailableAddOn[] = [];

    add_ons.forEach((addOn) => {
      const res = availableAddOnSchema.safeParse(addOn);
      // We're only interested in the available add-ons.
      if (res.success) {
        if (res.data.isAvailable) {
          addOns.push(res.data);
        }
      } else {
        console.error(`Error parsing add-on`, addOn, res.error);
      }
    });

    const addOnGroups: AvailableAddOnsGroup[] = [];

    const timeExtensionAddOn = addOns.find(
      (e) => e.addOnType === 'time_extension'
    );
    if (timeExtensionAddOn) {
      addOnGroups.push({
        addOns: [timeExtensionAddOn],
        allowMultiple: false,
        title: 'Add more playing time',
        key: 'playing_time',
        description: 'This will be added to your standard booking',
      });
    }

    const preGameOutdoorTableAddOn = addOns.find(
      (e) => e.addOnType === 'pre_game_outdoor_table'
    );
    const postGameOutdoorTableAddOn = addOns.find(
      (e) => e.addOnType === 'post_game_outdoor_table'
    );

    if (preGameOutdoorTableAddOn || postGameOutdoorTableAddOn) {
      addOnGroups.push({
        addOns: [
          ...(preGameOutdoorTableAddOn ? [preGameOutdoorTableAddOn] : []),
          ...(postGameOutdoorTableAddOn ? [postGameOutdoorTableAddOn] : []),
        ],
        allowMultiple: false,
        title: 'Book a table on our terrace',
        key: 'outdoor_table',
        description:
          'Our heated terrace in Canary Wharf is ready to welcome you. Terrace bookings are for 90 minutes as standard. In case of bad weather we are unable to guarantee an inside table, inside tables will be subject to availability.',
      });
    }

    return { addOnGroups };
  });

export type GetAvailableAddOnsData = zod.infer<
  typeof getAvailableAddOnsDataSchema
>;

export const getAvailableAddOnsResponseSchema = zod.object({
  status: zod.literal(200),
  data: getAvailableAddOnsDataSchema,
});

export const allergenReservationTagsSchema = zod.array(
  zod
    .object({
      id: zod.string(),
      display_name: zod.string(),
    })
    .transform(({ display_name, ...rest }) => ({
      displayName: display_name,
      type: 'allergen' as const,
      ...rest,
    }))
);

export type AllergenReservationTags = zod.infer<
  typeof allergenReservationTagsSchema
>;

export const dietaryReservationTagsSchema = zod.array(
  zod
    .object({
      id: zod.string(),
      display_name: zod.string(),
    })
    .transform(({ display_name, ...rest }) => ({
      displayName: display_name,
      type: 'dietary' as const,
      ...rest,
    }))
);

export type DietaryReservationTags = zod.infer<
  typeof dietaryReservationTagsSchema
>;

const bookingPaymentSchema = zod
  .object({
    iframe: zod.string(),
    transaction_amount: stringCostSchema,
  })
  .transform(({ transaction_amount, ...rest }) => ({
    transactionAmount: transaction_amount,
    ...rest,
  }));

export type BookingPayment = zod.infer<typeof bookingPaymentSchema>;

export const initialiseBookingPaymentResponseSchema = zod.object({
  status: zod.literal(200),
  data: bookingPaymentSchema,
});
