import * as React from 'react';
import styled from 'styled-components';

import { useBookingFlowManager } from 'src/context/bookingFlow';

import BookingFlowButton from './Button';
import Input, { InputLabel } from './Input';
import { useInSyncRef } from 'src/hooks/useInSyncRef';

const PromoCode = styled.div`
  margin-top: 1rem;
  width: 100%;
`;

const PromoCodeFieldGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
`;

const PromoCodeInput = styled(Input)``;

const ApplyPromoButton = styled(BookingFlowButton)`
  max-height: 2.5rem;
`;

export const PromoCodeField = () => {
  const { send, booking, promoCodeState } = useBookingFlowManager();

  const appliedPromoCode = React.useMemo(
    () => booking?.promoCode?.code,
    [booking]
  );
  const [promoCode, setPromoCode] = React.useState(appliedPromoCode || '');

  const appliedPromoCodeRef = useInSyncRef(appliedPromoCode);

  React.useEffect(() => {
    if (!promoCodeState.isSubmitting) {
      setPromoCode(appliedPromoCodeRef.current || '');
    }
  }, [promoCodeState.isSubmitting, setPromoCode, appliedPromoCodeRef]);

  return (
    <PromoCode>
      <InputLabel>Promo code</InputLabel>
      <PromoCodeFieldGroup>
        <PromoCodeInput
          type="text"
          name="promoCode"
          onChange={(e) => setPromoCode(e.target.value)}
          value={promoCode}
          errorMessage={
            promoCodeState.isError
              ? "Sorry, we didn't recognise that code"
              : undefined
          }
        />
        <ApplyPromoButton
          onClick={() => send({ type: 'onApplyPromoCode', promoCode })}
          theme="blue"
          disabled={
            appliedPromoCode === promoCode || promoCodeState.isSubmitting
          }
        >
          Apply
        </ApplyPromoButton>
      </PromoCodeFieldGroup>
    </PromoCode>
  );
};
