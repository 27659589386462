import React from 'react';
import { useBookingSearch } from 'context/BookingSearchProvider';
import { useClaysLocation } from 'context/ClaysLocationProvider';
import styled, { css } from 'styled-components';
import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';
import { formatSlugToString } from 'utils/helpers';
import { datalayerPush } from '../ThirdParty/GtmDatalayerPush';

const RadioButtonContainer = styled.label`
  // --- LAYOUT ---
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  // --- STYLES ---
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  background-color: ${COLORS.white};
  color: ${COLORS.darkGrey};
  border: 1px solid ${COLORS.blueGrey};
  cursor: pointer;

  // --- FONTS ---
  font-family: ${FONTS.poppins};
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 140%;

  // --- HOVER STYLES ---
  &:hover {
    background-color: ${COLORS.forest};
    color: ${COLORS.white};
  }

  // --- SELECTED STYLES ---
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${COLORS.forest};
      color: ${COLORS.white};
    `}

  // --- DISABLED STYLES ---
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #f7f7f7;
      color: rgba(13, 31, 33, 0.5);
      border-color: #e4e4e4;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const HiddenRadioButton = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

export const WhereContent = () => {
  const { searchData, updateSearchData } = useBookingSearch();
  const { allClaysLocations } = useClaysLocation();

  const handleSelectLocation = (venueId, label) => {
    updateSearchData('Where', { id: venueId, label: label });
    datalayerPush({
      event: 'locationSelected',
      venueId: venueId,
      venueName: label,
    });
  };

  return (
    <div>
      {allClaysLocations.map(
        ({ slug, city, postcode, comingSoon, sevenroomsVenueId }) => {
          const label = formatSlugToString(slug);
          const value = comingSoon ? 'Coming soon!' : `${city} ${postcode}`;
          const disabled = comingSoon;

          return (
            <RadioButtonContainer
              key={label}
              selected={searchData.Where.id === sevenroomsVenueId && !disabled}
              disabled={disabled}
              onClick={() =>
                !disabled && handleSelectLocation(sevenroomsVenueId, label)
              }
            >
              <HiddenRadioButton
                name="location"
                checked={searchData.Where.id === sevenroomsVenueId}
                onChange={() => handleSelectLocation(sevenroomsVenueId, label)}
                disabled={disabled}
              />
              <span>{label}</span>
              <span>{value}</span>
            </RadioButtonContainer>
          );
        }
      )}
    </div>
  );
};

export default WhereContent;
