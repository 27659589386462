import * as React from 'react';
import styled from 'styled-components';
import FONTS from 'styles/fonts';
import COLORS from 'styles/colors';
import Checkbox from './Checkbox';
import { P2 } from 'styles/typography';
import ChevronSvg from 'public/images/icons/chevron-centered.svg';
import { ReservationTag } from 'src/context/bookingFlow';

const InputLabel = styled.label`
  font-family: ${FONTS.poppins};
  font-size: 0.625rem;
  color: ${COLORS.forestDark};
`;

const Dropdown = styled.div`
  position: relative;
  width: 100%;
  font-family: ${FONTS.poppins};
  font-size: 0.8125rem;
`;

const DropdownButton = styled.div`
  box-sizing: border-box;
  /* LAYOUT */
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  min-height: 2.75rem;
  margin-top: 0.5rem;

  /* STYLES */
  border-radius: 0.5rem;
  border: 1px solid ${COLORS.blueGrey};
  background-color: ${COLORS.white};
  cursor: pointer;

  &:focus-visible {
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

const Tag = styled.button`
  /* LAYOUT */
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 1.625rem;

  /* STYLES */
  font-family: ${FONTS.poppins};
  background-color: ${COLORS.forest};
  color: ${COLORS.white};
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;

  &:focus-visible {
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  width: 100%;
`;

interface DropdownContentProps {
  show: boolean;
}

const DropdownContent = styled.div<DropdownContentProps>`
  /* LAYOUT */
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 0.5rem;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  max-height: 200px;
  overflow-y: scroll;
  z-index: 1;
  font-size: 0.8125rem;

  /* STYLES */
  border-radius: 0.5rem;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.blueGrey};
`;

const IconSize = '0.5rem';
const CloseIcon = styled.div`
  display: block;
  height: ${IconSize};
  width: ${IconSize};
  background-image: url('/images/icons/cross-white.svg');
  background-size: ${IconSize};
  background-repeat: no-repeat;
  background-position: center;
`;

const ChevronSize = '1.5rem';

const Chevron = styled(ChevronSvg)`
  path {
    fill: ${COLORS.blueGrey};
  }
`;

interface IconWrapperProps {
  rotateChevron: boolean;
}

const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  width: ${ChevronSize};
  height: ${ChevronSize};
  transform: rotate(${({ rotateChevron }) => (rotateChevron ? 0 : '180deg')});
`;

const ErrorMessage = styled(P2)`
  margin: 0;
  font-size: 0.625rem;
  color: ${COLORS.orange};
`;

interface MultiSelectInputProps {
  reservationTags: ReservationTag[];
  name?: string;
  label: string;
  onChange?: (reservationTag: ReservationTag, isChecked: boolean) => void;
  selectedReservationTags: ReservationTag[];
  errorMessage?: string;
}

const MultiSelectInput = React.forwardRef<
  HTMLDivElement,
  MultiSelectInputProps
>(
  (
    {
      reservationTags,
      name,
      label,
      onChange,
      errorMessage,
      selectedReservationTags,
    },
    ref
  ) => {
    const [showDropdown, setShowDropdown] = React.useState(false);
    const dropdownRef = React.useRef<HTMLDivElement>(null);

    const handleCheckboxChange = (tag: ReservationTag, isChecked: boolean) => {
      onChange?.(tag, isChecked);
    };

    const removeTag = (tag: ReservationTag, event) => {
      event.stopPropagation();
      handleCheckboxChange(tag, false);
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        setShowDropdown(!showDropdown);
        event.preventDefault();
      }
    };

    React.useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <div ref={ref}>
        {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
        <Dropdown ref={dropdownRef}>
          <DropdownButton
            tabIndex={0}
            onClick={() => setShowDropdown(!showDropdown)}
            onKeyDown={handleKeyDown}
          >
            <Tags>
              {selectedReservationTags.map((selectedReservationTag) => {
                const tag = reservationTags.find(
                  (t) => t.id === selectedReservationTag.id
                );
                return tag ? (
                  <Tag
                    key={selectedReservationTag.id}
                    onClick={(e) => removeTag(selectedReservationTag, e)}
                  >
                    {tag.displayName}
                    <CloseIcon />
                  </Tag>
                ) : null;
              })}
            </Tags>
            <IconWrapper rotateChevron={showDropdown}>
              <Chevron />
            </IconWrapper>
          </DropdownButton>
          <DropdownContent ref={dropdownRef} show={showDropdown}>
            {reservationTags.map((tag) => (
              <Checkbox
                key={tag.id}
                name={tag.id}
                label={tag.displayName}
                labelSize="large"
                value={!!selectedReservationTags.find((t) => tag.id === t.id)}
                onChange={(isChecked) => handleCheckboxChange(tag, isChecked)}
              />
            ))}
          </DropdownContent>
        </Dropdown>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </div>
    );
  }
);

export default MultiSelectInput;
