import Script from 'next/script';

const CookieYesScript = () => {
  const COOKIEYES_ID = process.env.NEXT_PUBLIC_COOKIEYES_ID;
  if (!COOKIEYES_ID) return null;
  return (
    <>
      <Script strategy="beforeInteractive" id="google-consent-mode">
        {`window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag("consent", "default", {
                ad_storage: "denied",
                ad_user_data: "denied", 
                ad_personalization: "denied",
                analytics_storage: "denied",
                functionality_storage: "denied",
                personalization_storage: "denied",
                security_storage: "granted",
                wait_for_update: 2000,
            });
            gtag("set", "ads_data_redaction", true);
            gtag("set", "url_passthrough", true);`}
      </Script>
      <Script
        id="cookieyes"
        strategy="beforeInteractive"
        src={`https://cdn-cookieyes.com/client_data/${COOKIEYES_ID}/script.js`}
        type="text/javascript"
      />
    </>
  );
};

export default CookieYesScript;
