import styled from 'styled-components';

import VisaCard from 'public/images/icons/visa.svg';
import MasterCard from 'public/images/icons/mastercard.svg';
import Amex from 'public/images/icons/amex.svg';
import Maestro from 'public/images/icons/maestro.svg';
import Cirrus from 'public/images/icons/cirrus.svg';

const CardsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const PaymentCardsList = ({ className = '' }) => {
  return (
    <CardsContainer className={className}>
      <VisaCard />
      <MasterCard />
      <Amex />
      <Maestro />
      <Cirrus />
    </CardsContainer>
  );
};

export default PaymentCardsList;
