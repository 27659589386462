// TimeStep.js
import * as React from 'react';
import styled from 'styled-components';
import { formatDateDisplay } from 'utils/bookingFlowUtils';
import DaySelectButton from '../DaySelectButton';
import Nudge from '../Nudge';
import BREAKPOINTS, { breakpointSizes } from 'styles/breakpoints';
import FONTS from 'styles/fonts';
import COLORS from 'styles/colors';
import { weight } from 'styles/typography';
import { useBookingFlowManager } from 'src/context/bookingFlow';
import { useMediaQuery } from 'react-responsive';
import TimeSlots from '../TimeSlots';

const TimeStepPaddingX = '1.25rem';
interface TimeStepContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  hasSelectedPackage: boolean;
}

const TimeStepContainer = styled.div<TimeStepContainerProps>`
  /* Adds padding to the bottom of the TimeStepContainer if there is a selected package
  to account for the nudge at the bottom of the step */
  position: relative;
  padding-bottom: ${({ hasSelectedPackage }) =>
    hasSelectedPackage ? '3rem' : '0'};

  ${BREAKPOINTS.medium`
    padding-bottom: 0;
  `}
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;

  ${BREAKPOINTS.medium`
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 1rem ${TimeStepPaddingX} 0.5rem ${TimeStepPaddingX};
  background-color: ${COLORS.white};
  border-radius: 0.5rem 0.5rem 0 0;
  `}
`;

const GameTimeNudge = styled(Nudge)`
  justify-content: center;

  ${BREAKPOINTS.medium`
  max-width: 12.0625rem;
  height: fit-content;
  `}
`;
const SelectedVenueLabel = styled.h3`
  font-family: ${FONTS.wulkan};
  font-weight: 850;
  color: ${COLORS.forest};
  font-size: 1.5625rem;
  padding: 1rem ${TimeStepPaddingX} 0.5rem ${TimeStepPaddingX};

  ${BREAKPOINTS.medium`
    padding: 0;
  `}
`;

const Days = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 0.5rem 0;

  ${BREAKPOINTS.medium`
&::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 20%;
    height: 100%;
    background-color: ${COLORS.forestBlack.opacity(0.05)};
    z-index: 0;
  }
  `}
`;

interface DayContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  isDesktop: boolean;
}

const Day = styled.div<DayContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${({ isDesktop }) => (isDesktop ? '1rem 0' : `0.5rem 0`)};
`;

interface DayLabelProps extends React.HTMLAttributes<HTMLSpanElement> {
  isDesktop: boolean;
}

const DayLabel = styled.span<DayLabelProps>`
  display: flex;
  font-family: ${FONTS.poppins};
  color: ${({ isDesktop }) =>
    isDesktop ? COLORS.forestDark : COLORS.darkGrey};
  font-weight: ${weight.semibold};
  justify-content: ${({ isDesktop }) => (isDesktop ? 'center' : 'flex-start')};
  padding-bottom: ${({ isDesktop }) => (isDesktop ? '1rem' : '0')};
  margin-left: ${({ isDesktop }) => (isDesktop ? '0' : '1.25rem')};
`;

const StyledNudge = styled(Nudge)``;

const SPECIAL_DATES = ['2024-11-27', '2024-11-28', '2024-11-29', '2024-11-30'];

const TimeStep = () => {
  const {
    send,
    isReFetching,
    searchData,
    threeDayAvailability,
    selectedTimeSlot,
    totalGameDuration,
  } = useBookingFlowManager();

  const dateObj = new Date(searchData?.When.date!);
  const selectedMonth = dateObj.getMonth() + 1;

  const days = React.useMemo(
    () =>
      threeDayAvailability
        ? [
            threeDayAvailability.currentDay,
            threeDayAvailability.currentDayPlusOne,
            threeDayAvailability.currentDayPlusTwo,
          ]
        : [],
    [threeDayAvailability]
  );

  const isDesktopOrTablet = useMediaQuery({
    query: `(min-width: ${breakpointSizes.medium}px)`,
  });

  return (
    <TimeStepContainer hasSelectedPackage={!!selectedTimeSlot}>
      {[11, 12].includes(selectedMonth) ? (
        <article className="m-4">
          <StyledNudge
            text={
              <p className="text-xs font-normal leading-5 text-left opacity-100 py-[2px] px-[15px]">
                If you&apos;re looking to book for 15 or more people during the
                Christmas period (18 November - 31 December) please{' '}
                <span
                  className="text-blue-500 cursor-pointer underline"
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      window.location.replace('/group-bookings');
                    }
                  }}
                >
                  click here
                </span>{' '}
                to submit an enquiry.
              </p>
            }
            rounded={isDesktopOrTablet ? true : false}
            hasBackground={isDesktopOrTablet ? true : false}
            hasIcon={false}
          />
        </article>
      ) : null}

      {SPECIAL_DATES.includes(searchData?.When?.date!) &&
      searchData?.Where.label === 'Birmingham' ? (
        <article className="m-4">
          <StyledNudge
            text={
              <p className="text-xs font-normal leading-5 text-left opacity-100 py-[2px] px-[15px]">
                * All bookings made for November will be part of our soft launch.
                As a thank you for your patience during this time, the shooting
                experience will cost just £5, and a 50% discount will be applied
                to all food and drink orders on the day. Your feedback will be
                invaluable as we aim to get everything just right.
              </p>
            }
            rounded={isDesktopOrTablet ? true : false}
            hasBackground={isDesktopOrTablet ? true : false}
            hasIcon={false}
          />
        </article>
      ) : null}

      <Header>
        <GameTimeNudge
          text={`Game time: ${totalGameDuration} minutes`}
          hasIcon
          name="gametime"
          rounded
        />

        <SelectedVenueLabel>{searchData?.Where.label}</SelectedVenueLabel>
      </Header>

      <DaySelectButton
        type="previous"
        onClick={() =>
          send({
            type: 'onNudgeAvailability',
            direction: 'backwards',
          })
        }
        isLoading={isReFetching}
      />

      <Days>
        {days.map((d) => (
          <Day key={d.requestedDay} isDesktop={isDesktopOrTablet}>
            <DayLabel isDesktop={isDesktopOrTablet}>
              {formatDateDisplay(d.requestedDay)}
            </DayLabel>

            <TimeSlots d={d} isDesktopOrTablet={isDesktopOrTablet} />
          </Day>
        ))}
      </Days>

      <DaySelectButton
        type="next"
        onClick={() =>
          send({
            type: 'onNudgeAvailability',
            direction: 'forwards',
          })
        }
        isLoading={isReFetching}
      />
    </TimeStepContainer>
  );
};

export default TimeStep;
