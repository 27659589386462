import { useEffect } from 'react';

// Custom hook to lock the body scroll
export const useLockBodyScroll = (isOpen) => {
  useEffect(() => {
    // Store the original values
    const originalStyle = window.getComputedStyle(document.body).overflow;
    const originalPaddingRight = window.getComputedStyle(
      document.body
    ).paddingRight;

    if (isOpen) {
      // Get the scrollbar width
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;

      // Lock the scroll and compensate for the scrollbar width
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    }

    return () => {
      // Restore the original styles
      document.body.style.overflow = originalStyle;
      document.body.style.overflowX = 'hidden';
      document.body.style.paddingRight = originalPaddingRight;
    };
  }, [isOpen]);
};
