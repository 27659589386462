import { format, parseISO, setHours, setMinutes } from 'date-fns';

// --- SEARCH MAIN ---

export const toISODateString = (date) => {
  return new Date(date).toISOString().split('T')[0];
};

// --- WHO HELPERS ---

export const formatWhoValue = (value) => {
  if (value < 1) return '';
  return value === 1 ? '1 person' : `${value} people`;
};

// --- WHEN HELPERS---

// Format time for display in 12-hour format with AM/PM
export const formatTimeForDisplay = (hour, minute) => {
  const date = setMinutes(setHours(new Date(), hour), minute);
  return format(date, 'h:mma').toLowerCase();
};

// Generate time options for dropdown
export const generateTimeOptions = () => {
  const options = [];
  for (let hour = 12; hour <= 23; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      if (hour === 23 && minute > 0) break;
      options.push({
        value: format(setMinutes(setHours(new Date(), hour), minute), 'HH:mm'),
        label: formatTimeForDisplay(hour, minute),
      });
    }
  }
  return options;
};

// Format date and time for display
export const formatDateTimeDisplay = (isoDate, isoTime) => {
  const dateDisplay = isoDate ? format(parseISO(isoDate), 'do MMM') : '';
  const timeDisplay = isoTime
    ? formatTimeForDisplay(...isoTime.split(':').map(Number))
    : '';
  return dateDisplay && timeDisplay
    ? `${dateDisplay} at ${timeDisplay}`
    : dateDisplay || timeDisplay;
};

export const formatDateTimeString = (dateTimeString) => {
  const parsedDate = parseISO(dateTimeString);
  return format(parsedDate, "iii do MMM 'at' h:mmaaa");
};
