import * as React from 'react';
import { useMachine } from '@xstate/react';
import { UseFormReturn } from 'react-hook-form';

import {
  getFocusedAvailabilityDay,
  getIsReFetching,
  getCombinedSearchData,
  getThreeDayAvailability,
  getSelectedTimeSlot,
  getTotalGameDuration,
  getTotalBookingCost,
  getCmsContent,
  getAvailablePackages,
  getSelectedPackage,
  getBooking,
  getAvailableAddOns,
  getSelectedAddOns,
  getUserInfo,
  getReservationTags,
  getSelectedReservationTags,
  getBookingPayment,
  getLastApiErrorEvent,
  getIsFreedomPayReady,
  getPaymentData,
  getPromoCodeState,
  getSelectedPackageCost,
  getSelectedAddOnsCost,
} from './selectors';
import { machine, steps } from './machine';
import {
  BookingFlowAdditionalNotes,
  BookingFlowBillingInfo,
  BookingFlowUserInfo,
} from './types';

export type Machine = ReturnType<typeof useMachine<typeof machine>>;

export type MachineInstanceInput = {
  state: Machine[0];
  send: Machine[1];
  userInfoForm: UseFormReturn<BookingFlowUserInfo, any, undefined>;
  additionalNotesForm: UseFormReturn<
    BookingFlowAdditionalNotes,
    any,
    undefined
  >;
  billingInfoForm: UseFormReturn<BookingFlowBillingInfo, any, undefined>;
  freedomPayIframeId: React.MutableRefObject<string | null>;
};

/**
 * This is a workaround for a bug in react-hook-form where the form doesn't
 * always validate on the first trigger.
 */
const conditionalFormValidateRetry = async (
  form: UseFormReturn<any, any, any>
) => {
  await form.trigger();
  if (!form.formState.isValid) {
    await new Promise((res) => setTimeout(res, 100));
    await form.trigger();
  }
};

export const useBookingFlowHooks = ({
  send,
  state,
  userInfoForm,
  additionalNotesForm,
  billingInfoForm,
  freedomPayIframeId,
}: MachineInstanceInput) => {
  const isReFetching = React.useMemo(
    () => getIsReFetching(state.context.events),
    [state]
  );

  const focusedAvailabilityDay = React.useMemo(
    () => getFocusedAvailabilityDay(state.context.events),
    [state]
  );

  const searchData = React.useMemo(
    () => getCombinedSearchData(state.context.events),
    [state]
  );

  const stepIndex = React.useMemo(() => {
    return steps.findIndex((s) => s === state.value);
  }, [state]);

  const progress = React.useMemo(() => {
    const numberOfSteps = steps.length;
    return (stepIndex / (numberOfSteps - 1)) * 100;
  }, [stepIndex]);

  const shouldShowBackButton = React.useMemo(() => {
    const firstStepToShowFor = 'addons';
    const indexOfFirstStepToShowFor = steps.findIndex(
      (s) => s === firstStepToShowFor
    );

    if (state.matches('confirmation')) {
      return false;
    }

    if (state.matches('payment')) {
      return false;
    }

    if (state.matches('completingPayment')) {
      return false;
    }

    return stepIndex >= indexOfFirstStepToShowFor;
  }, [stepIndex, state]);

  const shouldShowNextButton = React.useMemo(() => {
    return !['preLoad', 'confirmation'].includes(String(state.value));
  }, [state]);

  const shouldShowBookingSummary = React.useMemo(() => {
    return ['package', 'addons', 'userInfo'].includes(String(state.value));
  }, [state]);

  const isLoadingScreen = React.useMemo(
    () =>
      state.matches('preLoad') ||
      state.matches('creatingBooking') ||
      state.matches('updatingPackage') ||
      state.matches('updatingAddOns') ||
      state.matches('initialisingPayment') ||
      state.matches('completingPayment'),
    [state]
  );

  const threeDayAvailability = React.useMemo(
    () => getThreeDayAvailability(state.context.events),
    [state]
  );

  const selectedTimeSlot = React.useMemo(
    () => getSelectedTimeSlot(state.context.events),
    [state]
  );

  const totalGameDuration = React.useMemo(
    () => getTotalGameDuration(state.context.events),
    [state]
  );

  const totalBookingCost = React.useMemo(
    () => getTotalBookingCost(state.context.events),
    [state]
  );

  const cmsContent = React.useMemo(
    () => getCmsContent(state.context.events),
    [state]
  );

  const availablePackages = React.useMemo(
    () => getAvailablePackages(state.context.events),
    [state]
  );

  const availableAddOns = React.useMemo(
    () => getAvailableAddOns(state.context.events),
    [state]
  );

  const selectedPackage = React.useMemo(
    () => getSelectedPackage(state.context.events),
    [state]
  );

  const selectedAddOns = React.useMemo(
    () => getSelectedAddOns(state.context.events),
    [state]
  );

  const booking = React.useMemo(
    () => getBooking(state.context.events),
    [state]
  );

  const isFreedomPayReady = React.useMemo(
    () => getIsFreedomPayReady(state.context.events),
    [state]
  );

  const nextButtonDisabled = React.useMemo(() => {
    if (state.matches('time') && !selectedTimeSlot) {
      return true;
    }

    if (
      state.matches('package') &&
      !selectedPackage &&
      booking?.isPackageRequired
    ) {
      return true;
    }

    if (state.matches('payment') && !isFreedomPayReady) {
      return true;
    }

    return false;
  }, [state, selectedTimeSlot, selectedPackage, booking, isFreedomPayReady]);

  const nextButtonText = React.useMemo(() => {
    if (
      state.matches('package') &&
      !booking?.isPackageRequired &&
      !selectedPackage
    ) {
      return 'Skip';
    }
    if (state.matches('addons') && !selectedAddOns?.length) {
      return 'Skip';
    }
    if (state.matches('summary')) {
      return 'Next: Billing';
    }
    if (state.matches('billingInfo')) {
      return 'Next: Payment';
    }
    if (state.matches('payment')) {
      return 'Confirm & Pay';
    }

    return 'Next';
  }, [state, booking, selectedAddOns, selectedPackage]);

  const userInfo = React.useMemo(
    () => getUserInfo(state.context.events),
    [state]
  );

  const reservationTags = React.useMemo(
    () => getReservationTags(state.context.events),
    [state]
  );

  const selectedReservationTags = React.useMemo(
    () => getSelectedReservationTags(state.context.events),
    [state]
  );

  const bookingPayment = React.useMemo(
    () => getBookingPayment(state.context.events),
    [state]
  );

  const lastApiErrorEvent = React.useMemo(
    () => getLastApiErrorEvent(state.context.events),
    [state]
  );

  const paymentData = React.useMemo(
    () => getPaymentData(state.context.events),
    [state]
  );

  const promoCodeState = React.useMemo(
    () => getPromoCodeState(state.context.events),
    [state]
  );

  const selectedPackageCost = React.useMemo(
    () => getSelectedPackageCost(state.context.events),
    [state]
  );

  const selectedAddOnsCost = React.useMemo(
    () => getSelectedAddOnsCost(state.context.events),
    [state]
  );

  const shouldShowNavigation = React.useMemo(() => {
    return !isLoadingScreen && !state.matches('confirmation');
  }, [isLoadingScreen, state]);

  const onNextStep = React.useCallback(async () => {
    if (state.matches('userInfo')) {
      await conditionalFormValidateRetry(userInfoForm);
    }
    if (state.matches('summary')) {
      await conditionalFormValidateRetry(additionalNotesForm);
    }
    if (state.matches('billingInfo')) {
      await conditionalFormValidateRetry(billingInfoForm);
    }
    send('onNextStep');
  }, [send, state, userInfoForm, billingInfoForm, additionalNotesForm]);

  const setFreedomPayIframeId = React.useCallback(
    (id: string) => {
      freedomPayIframeId.current = id;
    },
    [freedomPayIframeId]
  );

  return {
    send,
    onNextStep,
    setFreedomPayIframeId,
    isReFetching,
    focusedAvailabilityDay,
    progress,
    isLoadingScreen,
    searchData,
    threeDayAvailability,
    selectedTimeSlot,
    shouldShowBackButton,
    totalGameDuration,
    shouldShowBookingSummary,
    shouldShowNextButton,
    totalBookingCost,
    cmsContent,
    availablePackages,
    availableAddOns,
    selectedPackage,
    selectedAddOns,
    booking,
    nextButtonDisabled,
    nextButtonText,
    userInfoForm,
    billingInfoForm,
    state,
    userInfo,
    reservationTags,
    selectedReservationTags,
    additionalNotesForm,
    bookingPayment,
    lastApiErrorEvent,
    isFreedomPayReady,
    paymentData,
    promoCodeState,
    shouldShowNavigation,
    selectedPackageCost,
    selectedAddOnsCost,
  };
};
