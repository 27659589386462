import Script from 'next/script';
import { useEffect, useState } from 'react';

import { isProduction } from 'utils/helpers';

const AtreemoTrackingScript = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      AtreemoTrackingLbr.init(['Clays, https://clays.webecast.atreemo.uk']);
      console.info('Atreemo initialised.');
    }
  }, [isLoaded]);

  if (!isProduction()) return null;

  return (
    <>
      <Script
        strategy="afterInteractive"
        src="https://tracking.atreemo.com/Scripts/TrackingInit.js"
        onLoad={() => setIsLoaded(true)}
      />
      {isLoaded && (
        <Script
          strategy="afterInteractive"
          src="https://tracking.atreemo.com/Scripts/Tracking.js"
        />
      )}
    </>
  );
};

export default AtreemoTrackingScript;
