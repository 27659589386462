import * as React from 'react';

import { useBookingFlowMachineInstance } from './machine';
import { useBookingFlowHooks } from './hooks';

type MachineContextShape = ReturnType<typeof useBookingFlowHooks>;

type MachineProviderProps = {
  children: React.ReactNode;
};

const MachineContext = React.createContext<MachineContextShape>(
  null as unknown as MachineContextShape
);

export const BookingFlowMachineProvider = ({
  children,
}: MachineProviderProps) => {
  const machine = useBookingFlowMachineInstance();
  return (
    <MachineContext.Provider value={useBookingFlowHooks(machine)}>
      {children}
    </MachineContext.Provider>
  );
};

export const useBookingFlowManager = () => {
  const context = React.useContext(MachineContext);

  if (!context) {
    throw new Error(
      'useMachineContext must be used within a BookingFlowMachineProvider'
    );
  }

  return context;
};
