import styled, { keyframes } from 'styled-components';
import COLORS from 'styles/colors';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 0.5rem;
  border: 4px solid ${COLORS.forest};
  border-top: 4px solid ${COLORS.tealMuted};
  border-radius: 50%;
  aspect-ratio: 1;
  height: 100%;
  animation: ${rotate} 2s linear infinite;
`;

const LoadingSpinner = () => {
  return <Spinner />;
};

export default LoadingSpinner;
