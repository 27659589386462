import * as React from 'react';

import styled from 'styled-components';
import * as CheckboxInput from '@radix-ui/react-checkbox';

import FONTS from 'styles/fonts';
import COLORS from 'styles/colors';

const Container = styled.div`
  font-family: ${FONTS.poppins};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const CheckboxField = styled(CheckboxInput.Root)`
  width: 1.5rem;
  height: 1.5rem;
  outline: none;
  border: 1px solid ${COLORS.blueGrey};
  border-radius: 0.25rem;
  background-color: ${COLORS.white};
  padding: 0;
  flex-shrink: 0;

  &[data-state='checked'] {
    background-color: ${COLORS.forest};
  }

  :focus-visible {
    border: 1px solid ${COLORS.blueGreen};
  }
`;

const CheckboxIndicator = styled(CheckboxInput.Indicator)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckboxLabel = styled.label<{
  labelSize?: string;
  hasError?: boolean;
}>`
  font-size: ${({ labelSize }) =>
    labelSize === 'large' ? ' 0.8125rem;' : '0.625rem'};
  color: ${COLORS.forestDark};
  max-width: 20rem;
  line-height: 1.1;

  ${({ hasError }) =>
    hasError &&
    `
        color: ${COLORS.orange}`}
`;

export type CheckboxProps = {
  name: string;
  label: React.ReactNode;
  labelSize?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  errorMessage?: string;
};
const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
  (
    { name, label, labelSize, value, onChange, errorMessage, ...props },
    ref
  ) => {
    return (
      <Container>
        <CheckboxWrapper>
          <CheckboxField
            ref={ref}
            id={`checkbox-${name}`}
            name={name}
            checked={value}
            onCheckedChange={onChange}
            {...props}
          >
            <CheckboxIndicator>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.81353 10.2177L2.96873 7.37294L2 8.33485L5.81353 12.1484L14 3.96191L13.0381 3L5.81353 10.2177Z"
                  fill="white"
                />
              </svg>
            </CheckboxIndicator>
          </CheckboxField>
          <CheckboxLabel
            htmlFor={`checkbox-${name}`}
            labelSize={labelSize}
            hasError={!!errorMessage}
          >
            {label}
          </CheckboxLabel>
        </CheckboxWrapper>
      </Container>
    );
  }
);

export default Checkbox;
