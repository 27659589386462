import { SessionProvider } from 'next-auth/react';

import { ThirdPartyScripts } from 'components/ThirdParty';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import AccountProvider from 'context/AccountProvider';
import ClaysLocationProvider from 'context/ClaysLocationProvider';
import NavProvider from 'context/NavProvider';
import BookingSearchProvider from 'context/BookingSearchProvider';

import GlobalStyles from 'styles/global';
import 'src/styles/global.css';
import { BookingFlowMachineProvider } from 'src/context/bookingFlow';

const GTM_CONTAINER_ID = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID;

export default function App({ Component, pageProps }) {
  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_CONTAINER_ID });
  }, []);

  return (
    <>
      <GlobalStyles />
      <SessionProvider session={pageProps.session}>
        <ClaysLocationProvider>
          <AccountProvider>
            <NavProvider>
              <BookingFlowMachineProvider>
                <BookingSearchProvider>
                  <ThirdPartyScripts />
                  <Component {...pageProps} />
                </BookingSearchProvider>
              </BookingFlowMachineProvider>
            </NavProvider>
          </AccountProvider>
        </ClaysLocationProvider>
      </SessionProvider>
    </>
  );
}
