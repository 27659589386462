declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
    sDataLayer: Record<string, unknown>[];
  }
}

type DataLayerEntry = {
  event: string;
  [x: string | number | symbol]: unknown;
};

export const datalayerPush = (entry: DataLayerEntry) => {
  // Web based GTM
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(entry);
  // Server Side GTM
  window.sDataLayer = window.sDataLayer || [];
  window.sDataLayer.push(entry);
};
