import { useState } from 'react';

const API_ENDPOINT = '/api/analytics/event';

export const ANALYTICS_EVENTS = {
  PERSONAL_INFO_COMPLETED: 'personal_info_completed',
};

export const useEventApi = () => {
  const [error, setError] = useState<string | null>(null);

  const createEvent = async (eventType: string) => {
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ event_type: eventType }),
      });

      const data = await response.json();
      if (response.ok) {
        return { success: true, message: data.message };
      } else {
        setError(data.error);
        return { success: false, message: data.error };
      }
    } catch (err) {
      setError('Failed to create event');
    }
  };

  return { createEvent, error };
};
