import styled from 'styled-components';

import GameAreaCard from '../GameAreaCard';
import Nudge from '../Nudge';

import COLORS from 'styles/colors';
import BREAKPOINTS from 'styles/breakpoints';
import { ChooseGameArea, useBookingFlowManager } from 'src/context/bookingFlow';
import { weight } from 'styles/typography';
import FONTS from 'styles/fonts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${BREAKPOINTS.medium`
    display: block;
    padding: 0 1.5rem;
    `}
`;

const CardsContainer = styled.div`
  margin: 1.5rem 0 0;
`;

const CardWrapper = styled.div``;

const HR = styled.hr`
  display: block;
  border: none;
  height: 1px;
  background-color: #e4e4e4;
  margin: 1.5rem 0;
`;

const Title = styled.span`
  display: block;
  margin-bottom: 1rem;
  color: ${COLORS.darkGrey};
  font-family: ${FONTS.poppins};
  font-weight: ${weight.semibold};
  font-size: 1rem;
`;

const StyledNudge = styled(Nudge)`
  max-width: 21.85rem;
  margin-bottom: 1rem;

  ${BREAKPOINTS.medium`
        max-width: none;
    `}
`;

type GameAreaStepProps = {
  content: Pick<
    ChooseGameArea,
    'smallGroup' | 'largeGroup' | 'unavailableMessage'
  >;
};
const GameAreaStep = ({ content }: GameAreaStepProps) => {
  const { booking } = useBookingFlowManager();

  const isLargeGroup = booking?.gameArea?.selected === 'peg';

  return (
    <Container>
      <CardsContainer>
        <CardWrapper>
          <Title>{content.smallGroup.header}</Title>
          {isLargeGroup && <StyledNudge text={content.unavailableMessage} />}
          <GameAreaCard
            title={content.smallGroup.cardHeader}
            description={content.smallGroup.cardDescription}
            img={content.smallGroup.cardImage.url}
            disabled={isLargeGroup}
          />
        </CardWrapper>

        <HR />

        <CardWrapper>
          <Title>{content.largeGroup.header}</Title>
          {!isLargeGroup && <StyledNudge text={content.unavailableMessage} />}
          <GameAreaCard
            title={content.largeGroup.cardHeader}
            description={content.largeGroup.cardDescription}
            img={content.largeGroup.cardImage.url}
            disabled={!isLargeGroup}
          />
        </CardWrapper>
      </CardsContainer>
    </Container>
  );
};
export default GameAreaStep;
