export const DEFAULT_LOCATION = 'the-city';

export const CONTACT_PHONE = '0203 011 4840';
export const CONTACT_EMAIL = 'hello@clays.bar';
export const GROUP_CONTACT_EMAIL = 'events@clays.bar';

export const FORGOT_PASSWORD = 'forgot-password';
export const SIGN_IN = 'sign-in';

export const SEVENROOMS_BASE_URL = 'https://www.sevenrooms.com/reservations';
export const SEVENROOMS_ALL_LOCATIONS_ID = 'clayslondon';

export const TOGGLE_URL =
  'https://giftcards.clays.bar/shop/clays-fully-loaded-gift-card';

export const AWS_S3_BUCKET_URL =
  'https://clays-booking-site-media.s3.eu-west-2.amazonaws.com';

// Scroll to hash IDs
export const PRICING_HASH_ID = 'pricing';
export const WHATS_ON_HASH_ID = 'scroll-to-whats-on';
export const LOCATION_HASH_ID = 'location';

export const WEBSITE_URL =
  process.env.NEXT_PUBLIC_WEBSITE_URL ||
  `https://${process.env.NEXT_PUBLIC_VERCEL_URL}` ||
  'https://clays.bar';

export const BOOKING_API_BASE_URL = process.env.SEVENROOMS_BOOKING_API_BASE_URL;
