import React from 'react';
import { useBookingSearch } from 'context/BookingSearchProvider';
import Calendar from 'react-calendar';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';
import { generateTimeOptions } from 'utils/bookingSearchUtils';
import { format } from 'date-fns';
import { datalayerPush } from '../ThirdParty/GtmDatalayerPush';

const CalendarWrapper = styled.div`
  width: 100%;
  .react-calendar {
    border: none;
    margin-left: auto;
    margin-right: auto;

    // --- NAVIGATION ---
    .react-calendar__navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 2.5rem;
      margin-bottom: 0.33rem;

      // Arrow buttons
      .react-calendar__navigation__arrow {
        min-width: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        aspect-ratio: 1;
        background-color: ${COLORS.blueGrey};
        border-radius: 50%;

        &:disabled {
          opacity: 0.25;
        }
      }

      // Hides "skip year" buttons
      .react-calendar__navigation__next2-button,
      .react-calendar__navigation__prev2-button {
        display: none;
      }

      // Navigation label
      .react-calendar__navigation__label {
        height: 100%;
        max-width: fit-content;
        padding: 0.16rem 1.6rem;
        font-family: ${FONTS.poppins};
        color: ${COLORS.forest};
        background-color: #efefef;
        border-radius: 2.1875rem;
        font-size: 0.89688rem;
        font-weight: 400;
        cursor: default;
      }
    }

    // --- DAY NAMES ---
    .react-calendar__month-view__weekdays {
      color: ${COLORS.forest};
      display: flex;
      align-items: center;

      & abbr {
        font-family: ${FONTS.poppins};
        font-size: 0.89688rem;
        font-weight: 400;
        line-height: normal;
        text-decoration: none;
        text-transform: none;
      }
    }

    // --- DAY TILES ---
    .react-calendar__month-view__days__day {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
      font-family: ${FONTS.poppins};
      font-size: 0.89688rem;
      color: ${COLORS.forest};
      padding: unset;

      abbr {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        height: 90%;
      }

      &:disabled {
        background: none;
        cursor: not-allowed;

        abbr {
          color: #bdbdbd;
          background-color: #f7f7f7;
        }
      }

      &:hover {
        background: none;

        abbr {
          background-color: #efefef;
        }
      }
    }

    // Selected tile
    .react-calendar__tile--active {
      background: none;

      abbr {
        background: ${COLORS.forest} !important;
        color: ${COLORS.white};
      }
    }
  }

  // Today's tile
  & .react-calendar__tile--now {
    background: none;

    abbr {
      background: none;
      border: 1px solid ${COLORS.forest};
    }
  }
`;

const CalendarArrow = styled.img`
  aspect-ratio: 1;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 1.5rem 0;
  background-color: #e4e4e4;
`;

const TimeContainer = styled.div``;

const SelectXPadding = '1rem';

const TimeSelect = styled.select`
  font-family: ${FONTS.poppins};
  cursor: pointer;
  width: 100%;
  padding: 0.75rem ${SelectXPadding};
  margin-top: 0.5rem;
  -webkit-appearance: none; /* Removes the default arrow for Webkit browsers */
  appearance: none; /* Removes the default arrow for other browsers */
  background-image: url('/images/icons/caret-down.svg'); /* Custom arrow */
  background-size: 0.6875rem;
  background-repeat: no-repeat;
  background-position: calc(100% - ${SelectXPadding}) center;
  border: 1px solid #bdbdbd;
  border-radius: 0.75rem;
  height: auto;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: ${COLORS.darkGrey};

  &:focus {
    outline: none;
  }
`;

export const WhenContent = () => {
  const { searchData, updateSearchData } = useBookingSearch();

  const timeOptions = generateTimeOptions();

  const selectedDate = searchData.When.date
    ? new Date(searchData.When.date)
    : null;
  const selectedTime = searchData.When.time || '';

  const handleDateChange = (newDate) => {
    const formattedDate = newDate ? format(newDate, 'yyyy-MM-dd') : '';
    updateSearchData('When', {
      ...searchData.When,
      date: formattedDate,
    });
    datalayerPush({
      event: 'initialDateSelected',
      date: formattedDate,
    });
  };

  const handleTimeChange = (event) => {
    const newTime = event.target.value;
    updateSearchData('When', {
      ...searchData.When,
      time: newTime,
    });
    datalayerPush({
      event: 'initialTimeSelected',
      time: newTime,
    });
  };

  // Sets maximum calendar date (1 year from now)
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);

  const minDate =
    searchData.Where.label === 'Birmingham'
      ? new Date('2024-11-29')
      : new Date();

  const activeStartDate =
    searchData.Where.label === 'Birmingham'
      ? new Date('2024-11-29')
      : new Date();

  return (
    <>
      <CalendarWrapper>
        <Calendar
          onChange={handleDateChange}
          value={selectedDate}
          prevLabel={
            <CalendarArrow src={'/images/icons/arrow-left-calendar.svg'} />
          }
          nextLabel={
            <CalendarArrow src={'/images/icons/arrow-right-calendar.svg'} />
          }
          showNeighboringMonth={false}
          minDate={minDate}
          maxDate={maxDate}
          minDetail="month"
          defaultActiveStartDate={activeStartDate}
        />
      </CalendarWrapper>
      <Divider />
      <TimeContainer>
        <span>What’s your preferred start time?</span>
        <TimeSelect value={selectedTime} onChange={handleTimeChange}>
          <option value="" disabled hidden>
            Choose a time
          </option>
          {timeOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TimeSelect>
      </TimeContainer>
    </>
  );
};

export default WhenContent;
