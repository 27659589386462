import styled from 'styled-components';

import FONTS from 'styles/fonts';
import COLORS from 'styles/colors';
import BREAKPOINTS from 'styles/breakpoints';

import { H6, P2 } from 'styles/typography';

const CardWrapper = styled.div`
  position: relative;
  max-width: 21.85rem;

  ${BREAKPOINTS.medium`
    max-width: none;
  `}
`;

const Card = styled.div`
  font-family: ${FONTS.poppins};
  border-radius: 0.5rem;
  background-color: ${COLORS.white};
  color: ${COLORS.darkGrey};
  opacity: ${(props) => (props.disabled ? 0.25 : 1)};

  ${BREAKPOINTS.medium`
    display: flex;
  `}
`;

const CardHeader = styled.div`
  max-height: 11.8rem;
  overflow: hidden;
  ${BREAKPOINTS.medium`
    min-height: 11.8rem;
    max-width: 11.8rem;
  `}
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
  display: block;

  ${BREAKPOINTS.medium`
    border-radius: 0.5rem 0 0 0.5rem;
    object-fit: cover;
    object-position: center;
  `}
`;

const CardBody = styled.div`
  padding: 1rem;

  ${BREAKPOINTS.medium`
    padding: 0 5rem 0 1.5rem;
    margin: auto 0;
  `}
`;

const CardTitle = styled(H6)`
  margin: 0;
  font-family: ${FONTS.poppins};
  font-size: 1rem;
`;

const CardDescription = styled(P2)`
  margin: 0;

  ${BREAKPOINTS.medium`
    max-width: 300px;
  `}
`;

const GameAreaCard = ({ img, title, description, disabled = false }) => {
  return (
    <CardWrapper>
      <Card disabled={disabled}>
        <CardHeader>
          <CardImage src={img} />
        </CardHeader>
        <CardBody>
          <CardTitle weight="semibold">{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </CardBody>
      </Card>
    </CardWrapper>
  );
};

export default GameAreaCard;
