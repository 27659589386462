import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import * as Sentry from '@sentry/nextjs';

import useFetch from 'hooks/useFetch';

import { errorMsg } from 'utils/error-messages';
import { postData } from 'utils/api';

export const AccountContext = createContext({
  user: null,
  setUser: () => {},
  isVerified: false,
  isError: false,
  isLoading: false,
  feedback: null,
});

const AccountProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { data: session } = useSession();
  const router = useRouter();
  const {
    query: { emailToken },
  } = router;

  const { data, mutate, isError } = useFetch(
    `/api/contact/client/${session?.user.email}`,
    session || false
  );

  const emailVerificationStatus =
    session?.user.isVerified || data?.contact.isEmailVerified || isVerified;

  const verifyEmail = useCallback(async () => {
    try {
      const res = await postData('/api/auth/verify-email', {
        email: user.email,
        emailToken,
      });

      if (res.success) {
        const newUserData = {
          ...user,
          isEmailVerified: true,
        };

        setIsVerified(true);
        setUser(newUserData);
        mutate({ contact: newUserData });
        router.replace({ pathname: '/my-clays', shallow: true });
      }

      if (res.error) {
        setFeedback(errorMsg.verifyError);
      }
    } catch (e) {
      setFeedback(errorMsg.verifyError);
    }

    setIsLoading(false);
  }, [user, mutate, router, emailToken]);

  // Verify email
  useEffect(() => {
    if (emailToken && !isVerified && user) {
      setIsLoading(true);
      verifyEmail();
    }
  }, [emailToken, isVerified, user, verifyEmail]);

  // Set user data
  useEffect(() => {
    if (data) {
      setUser(data.contact);
      setIsVerified(emailVerificationStatus);

      Sentry.setUser({
        firstName: data.contact.firstName,
        lastName: data.contact.lastName,
        email: data.contact.email,
      });
    }
  }, [data, setIsVerified, emailVerificationStatus]);

  return (
    <AccountContext.Provider
      value={{
        user,
        setUser,
        isVerified,
        feedback,
        isError,
        isLoading: isLoading || !user,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const { user } = useContext(AccountContext);
  return user || {};
};

export default AccountProvider;
