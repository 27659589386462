import styled, { css } from 'styled-components';

import BREAKPOINTS, { breakpointSizes } from './breakpoints';
import COLORS from './colors';
import FONTS, { base } from './fonts';

export const rem = (px) => `${px / base}rem`;

export const weight = {
  black: 900,
  bold: 700,
  semibold: 600,
  medium: 500,
  regular: 400,
};

export const TEXT_INDENT = '30px';

export const fluidType = (min: number, max: number) => {
  const { small, large } = breakpointSizes;

  const minSize = min / base;
  const maxSize = max / base;
  const breakpointSmall = small / base;
  const breakpointLarge = large / base;

  const slope = (maxSize - minSize) / (breakpointLarge - breakpointSmall);
  const yIntersection = (-breakpointSmall * slope + minSize).toFixed(2);

  const slopeAsVw = Math.round(slope * 100);
  const fluidValue = `${yIntersection}rem + ${slopeAsVw}vw`;

  return `max(${minSize.toFixed(2)}rem, min(${fluidValue}, ${maxSize.toFixed(
    2
  )}rem))`;
};

const styles = {
  h1: css`
    font-family: ${FONTS.wulkan};
    font-weight: ${weight.black};
    font-size: ${rem(68)};
    line-height: ${rem(68)};
    font-feature-settings: var(--font-feature-settings-normal);

    ${BREAKPOINTS.small`
      font-size: ${rem(120)};
      line-height: ${rem(105)};
    `}

    ${BREAKPOINTS.medium`
      font-size: ${rem(175)};
      line-height: ${rem(150)};
    `}

    ${BREAKPOINTS.large`
      font-size: ${rem(220)};
      line-height: ${rem(180)};
    `}
  `,
  h2: css`
    font-family: ${FONTS.wulkan};
    font-weight: ${weight.black};
    // Fallbacks: additional font-size and line-height
    // values where css max isn't supported - iOS <= v11
    font-size: ${rem(50)};
    line-height: ${rem(50)};
    font-size: ${fluidType(50, 75)};
    line-height: ${fluidType(50, 80)};
  `,
  h3: css<{
    small?: boolean;
  }>`
    font-family: ${FONTS.wulkan};
    font-weight: ${weight.black};
    font-size: ${(props) => (props.small ? rem(40) : rem(45))};
    line-height: ${rem(50)};
    font-size: ${(props) =>
      props.small ? fluidType(40, 75) : fluidType(45, 75)};
    line-height: ${fluidType(50, 80)};
  `,
  h4: css`
    font-family: ${FONTS.wulkan};
    font-weight: ${weight.black};
    font-size: ${rem(38)};
    line-height: ${rem(50)};
    font-size: ${fluidType(38, 50)};
    line-height: ${fluidType(50, 50)};
  `,
  h5: css<{ small?: boolean }>`
    font-family: ${FONTS.wulkan};
    font-weight: ${weight.black};
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    font-size: ${(props) =>
      props.small ? fluidType(30, 40) : fluidType(30, 50)};
    line-height: ${fluidType(36, 50)};
  `,
  h6: css`
    font-family: ${FONTS.wulkan};
    font-weight: ${weight.black};
    font-size: ${rem(18)};
    line-height: ${rem(25)};
    font-size: ${fluidType(18, 30)};
    line-height: ${fluidType(25, 36)};
  `,
  p1: css`
    font-family: ${FONTS.poppins};
    font-weight: ${weight.regular};
    font-size: ${rem(14)};
    line-height: ${rem(21)};

    ${BREAKPOINTS.medium`
      font-size: ${rem(16)};
      line-height: ${rem(25)};
    `}
  `,
  p2: css`
    font-family: ${FONTS.poppins};
    font-weight: ${weight.regular};
    font-size: ${rem(12)};
    line-height: ${rem(18)};
  `,
  p3: css`
    font-family: ${FONTS.poppins};
    font-weight: ${weight.regular};
    font-size: ${rem(16)};
    line-height: ${rem(25)};
  `,
  small: css`
    font-family: ${FONTS.poppins};
    font-weight: ${weight.regular};
    font-size: ${rem(11)};
    line-height: ${rem(15)};
  `,
  label: css`
    font-family: ${FONTS.wulkan};
    font-weight: ${weight.black};
    font-size: ${rem(25)};
    line-height: ${rem(25)};
  `,
  wulkan: css`
    font-family: ${FONTS.wulkan};
    font-feature-settings: var(--font-feature-settings-normal);
  `,
};

type FontStylesProps = {
  mb?: number;
  style?: string;
  align?: string;
  weight?: string;
};

const fontStyles = css<FontStylesProps>`
  margin-bottom: ${(props) => props.mb || 0}px;
  font-style: ${(props) => props.style || 'normal'};
  text-align: ${(props) => props.align || 'left'};

  ${(props) =>
    props.weight &&
    css`
      font-weight: ${weight[props.weight]};
    `};
`;

export const H1 = styled.h1<any>`
  ${styles.h1}
  ${fontStyles}
`;
export const H2 = styled.h2<any>`
  ${styles.h2}
  ${fontStyles}
`;
export const H3 = styled.h3<any>`
  ${styles.h3}
  ${fontStyles}
`;
export const H4 = styled.h4<any>`
  ${styles.h4}
  ${fontStyles}
`;
export const H5 = styled.h5<any>`
  ${styles.h5}
  ${fontStyles}
`;
export const H6 = styled.h6<any>`
  ${styles.h6}
  ${fontStyles}
`;

export const P1 = styled.p<any>`
  ${styles.p1}
  ${fontStyles}
  text-indent: ${(props) => (props.indent ? TEXT_INDENT : 0)};
`;
export const P2 = styled.p<any>`
  ${styles.p2}
  ${fontStyles}
  text-indent: ${(props) => (props.indent ? TEXT_INDENT : 0)};
`;
export const P3 = styled.p<any>`
  ${styles.p3}
  ${fontStyles}
  text-indent: ${(props) => (props.indent ? TEXT_INDENT : 0)};
`;

export const Italics = styled.em<{
  weight?: string;
}>`
  font-weight: ${(props) =>
    props.weight ? weight[props.weight] : weight.semibold};
`;

export const TextStroke = css<{
  color?: string;
}>`
  color: rgba(0, 0, 0, 0);
  text-stroke: 0.5px ${(props) => props.color || COLORS.pink};
  -webkit-text-stroke: 0.5px ${(props) => props.color || COLORS.pink};
`;

export default styles;
