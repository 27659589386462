import { css } from 'styled-components';

export const breakpointSizes = {
  xxsmall: 360,
  xsmall: 500,
  small: 768,
  medium: 1024,
  large: 1200,
  xLarge: 1400,
} as const;

const breakpoints: any = {};

const query =
  (size: any) =>
  (content: any, ...args: any[]) =>
    css`
      @media screen and (min-width: ${size}px) {
        ${css(content, ...args)}
      }
    `;

Object.keys(breakpointSizes).forEach((key) => {
  breakpoints[key] = query(
    breakpointSizes[key as keyof typeof breakpointSizes]
  );
});

breakpoints.custom = query;

// USAGE EXAMPLE
// breakpoints.small`-css here-`
// breakpoints.custom(1000)`-css here-`

export default breakpoints;
