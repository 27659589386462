import { makeAutoObservable } from 'mobx';
import { ConsentData } from 'types';

declare global {
  interface Window {
    getCkyConsent: () => ConsentData;
  }
}

class ConsentStoreBase {
  consentData: ConsentData | null = null;
  successfullyLoaded = false;

  constructor() {
    makeAutoObservable(this);
    this.initEventListener();
  }

  private initEventListener() {
    if (typeof document !== 'undefined') {
      document.addEventListener('cookieyes_consent_update', (eventData) => {
        this.loadConsentData();
      });
    }
  }

  private loadConsentData(): void {
    if (typeof window.getCkyConsent === 'function') {
      this.consentData = window.getCkyConsent();
      this.successfullyLoaded = true;

      console.info('Consent data loaded:', this.consentData);
    } else {
      this.successfullyLoaded = false;

      console.warn('getCkyConsent function is not available on window.');
    }
  }

  public isCategoryAccepted(category: string): boolean {
    if (!this.consentData || !this.consentData.categories) {
      console.warn('Consent data not available or categories not found.');
      return false;
    }
    const categoryValue = this.consentData.categories[category];
    if (categoryValue === undefined) {
      console.warn('Category not found:', category);
      return false;
    }
    return categoryValue;
  }

  get isUserActionCompleted(): boolean {
    return this.consentData?.isUserActionCompleted || false;
  }

  get hasConsentSuccessfullyLoaded(): boolean {
    return this.successfullyLoaded;
  }

  get allCookiesAccepted(): boolean {
    if (!this.consentData || !this.consentData.categories) {
      return false;
    }
    return (
      this.isCategoryAccepted('advertisement') &&
      this.isCategoryAccepted('analytics') &&
      this.isCategoryAccepted('functional') &&
      this.isCategoryAccepted('necessary')
    );
  }
}

export const ConsentStore = new ConsentStoreBase();
