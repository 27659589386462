import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import Nudge from '../Nudge';
import PackageCard from '../PackageCard';
import RadioBubble from '../RadioBubble';

import BREAKPOINTS, { breakpointSizes } from 'styles/breakpoints';
import { useBookingFlowManager } from 'src/context/bookingFlow';

const Container = styled.div`
  padding: 0 1.25rem;
  ${BREAKPOINTS.medium`
          padding: 0 1.5rem;
    `}
`;

const PackagesContainer = styled.div`
  padding: 1rem 0;

  ${BREAKPOINTS.medium`
          padding:  1.5rem 0;
    `}
`;

const StyledNudge = styled(Nudge)`
  margin-top: 1.5rem;
`;

const RequiredPackageNudge = styled(Nudge)`
  margin: 1.5rem 0 1rem;
`;

const PackageStep = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${breakpointSizes.medium}px)`,
  });

  const { send, availablePackages, selectedPackage, booking } =
    useBookingFlowManager();

  if (!availablePackages || !booking) {
    return null;
  }

  return (
    <Container>
      <StyledNudge
        text="Pre-order our delightful food and drinks for a hassle-free, wallet-friendly experience! *Certain sessions will require pre-purchased food and drink."
        rounded={isDesktopOrLaptop ? true : false}
        hasBackground={isDesktopOrLaptop ? true : false}
        hasIcon={false}
      />
      {booking.isPackageRequired && (
        <RequiredPackageNudge
          text="A package is required for your selected time and group size."
          rounded={isDesktopOrLaptop ? true : false}
        />
      )}
      <PackagesContainer>
        {availablePackages.map((pkg) => {
          const selected = pkg.packageId === selectedPackage?.packageId;

          return (
            <RadioBubble
              key={pkg.packageId}
              onSelect={() =>
                send({
                  type: 'onSelectPackage',
                  package: selected ? null : pkg,
                })
              }
              selected={selected}
            >
              <PackageCard
                packagePrice={`+£${pkg.charge}pp`}
                packageType={pkg.name}
                packageDescription={pkg.descriptionShort}
                packageDescriptionExpanded={pkg.description}
                selected={selected}
              />
            </RadioBubble>
          );
        })}
      </PackagesContainer>
    </Container>
  );
};

export default PackageStep;
