import * as React from 'react';
import Nudge from '../Nudge';
import { useMediaQuery } from 'react-responsive';
import BREAKPOINTS, { breakpointSizes } from 'styles/breakpoints';
import COLORS from 'styles/colors';
import styled from 'styled-components';
import PackagePill from '../PackagePill';
import MultiSelectInput from '../MultiSelectInput';
import Input from '../Input';
import { weight } from 'styles/typography';
import { useBookingFlowManager } from 'src/context/bookingFlow';
import {
  formatDateTimeDisplay,
  formatDateTimeString,
  formatWhoValue,
} from 'src/utils/bookingSearchUtils';
import { Controller } from 'react-hook-form';
import { formatDateToDDMMYYYY } from 'src/utils/bookingFlowUtils';
import TickIcon from 'public/images/icons/tick-success.svg';
import { PromoCodeField } from '../PromoCodeField';

const Container = styled.div`
  padding: 1.5rem 1.25rem;

  ${BREAKPOINTS.medium`
    padding: 1.5rem 1.5rem;
  `}
`;

const StyledNudge = styled(Nudge)`
  max-width: fit-content;
`;

const Summary = styled.div`
  margin-top: 1rem;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;

  gap: 1rem;
  ${BREAKPOINTS.medium`
  
  grid-template-columns: 1fr 1fr;
  `}
`;

const DetailsColumn = styled.div`
  display: grid;
  gap: 1rem;
`;

const Step = styled.div`
  /* LAYOUT */
  padding: 1rem;

  /* STYLES */
  background-color: ${COLORS.white};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${weight.semibold};

  button {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    text-decoration: underline;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
`;

const TotalPrice = styled(Header)`
  height: 2rem;
  margin-top: 1rem;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const Detail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8125rem;
  height: 2rem;
`;

const ReservationNotes = styled(Details)`
  gap: 1rem;
`;

const PaymentColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const PriceBreakdown = styled(Step)`
  height: fit-content;
  width: auto;

  ${BREAKPOINTS.medium`
    margin-top: 0;
  `}
`;

const PriceBreakdownDetails = styled.div`
  margin-left: 1rem;
  border-left: 2px solid ${COLORS.blueGrey};

  ${Detail} {
    margin-left: 1rem;
  }
`;

const PartialPayment = styled(Step)`
  gap: 1rem;
`;

// --- PROMO CODES ---

const PromoDetail = styled(Detail)`
  color: green;
  border-bottom: 1px solid grey;
  margin-top: 1rem;
`;

const PromoSuccess = styled.div`
  padding: 0.81rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  border-radius: 0.5rem;
  background-color: ${COLORS.tealMuted};
  color: ${COLORS.forestDark};
  font-size: 1rem;
  font-weight: ${weight.semibold};
`;

const TickSize = '1.5rem';
const Tick = styled(TickIcon)`
  height: ${TickSize};
  width: ${TickSize};
`;

const SummaryStep = () => {
  const {
    send,
    searchData,
    userInfo,
    selectedPackage,
    selectedAddOns,
    totalBookingCost,
    reservationTags,
    selectedReservationTags,
    booking,
    additionalNotesForm,
    selectedTimeSlot,
  } = useBookingFlowManager();
  const { control, trigger } = additionalNotesForm;

  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${breakpointSizes.medium}px)`,
  });

  const depositAmount = React.useMemo(() => booking?.depositAmount, [booking]);

  const shouldShowDeposit = React.useMemo(() => {
    if (depositAmount === totalBookingCost.total) {
      return false;
    }
    return true;
  }, [depositAmount, totalBookingCost]);

  return (
    <Container>
      <StyledNudge
        text="Please ensure your information is correct."
        hasIcon={isDesktopOrLaptop}
        rounded
        hasBackground={isDesktopOrLaptop}
      />
      <Summary>
        <DetailsColumn>
          <Step>
            <Header>
              <span>Your booking</span>
            </Header>
            <Details>
              <Detail>
                <span>Where</span>
                <span>{searchData?.Where.label}</span>
              </Detail>
              <Detail>
                <span>When</span>
                <span>
                  {selectedTimeSlot?.timeIso
                    ? formatDateTimeString(selectedTimeSlot.timeIso)
                    : ''}
                </span>
              </Detail>
              <Detail>
                <span>Who</span>
                <span>{formatWhoValue(searchData?.Who)}</span>
              </Detail>
              <Detail>
                <span>Occasion</span>
                <span>{searchData?.Occasion.name}</span>
              </Detail>
            </Details>
          </Step>
          <Step>
            <Header>
              <span>Packages & upgrades</span>
              <button onClick={() => send({ type: 'onBackToPackageStep' })}>
                Edit
              </button>
            </Header>

            <Details>
              {selectedPackage && (
                <Detail>
                  <span>Package</span>
                  <PackagePill name={selectedPackage?.name} />
                </Detail>
              )}

              {selectedAddOns?.map((addOn) => (
                <Detail key={addOn.addOnType}>
                  <span>Upgrade</span>
                  <span>{addOn.name}</span>
                </Detail>
              ))}
            </Details>
          </Step>
          <Step>
            <Header>
              <span>Your details</span>
              <button onClick={() => send({ type: 'onBackToUserInfoStep' })}>
                Edit
              </button>
            </Header>
            <Details>
              <Detail>
                <span>First name</span>
                <span>{userInfo?.firstName}</span>
              </Detail>
              <Detail>
                <span>Last name</span>
                <span>{userInfo?.lastName}</span>
              </Detail>
              <Detail>
                <span>Email</span>
                <span>{userInfo?.email}</span>
              </Detail>
              <Detail>
                <span>Mobile</span>
                <span>
                  {userInfo?.mobileNumber.code}
                  {userInfo?.mobileNumber.number}
                </span>
              </Detail>
              <Detail>
                <span>Date of birth</span>
                <span>{formatDateToDDMMYYYY(userInfo?.dob)}</span>
              </Detail>
            </Details>
          </Step>
          <Step>
            <Header>
              <span>Reservation notes</span>
            </Header>
            <ReservationNotes>
              {reservationTags.allergenReservationTags && (
                <MultiSelectInput
                  label="Please select any allergies"
                  reservationTags={reservationTags.allergenReservationTags}
                  selectedReservationTags={selectedReservationTags.filter(
                    (t) => t.type === 'allergen'
                  )}
                  onChange={(reservationTag, selected) =>
                    selected
                      ? send({
                          type: 'onSelectReservationTag',
                          reservationTag,
                        })
                      : send({
                          type: 'onDeSelectReservationTag',
                          reservationTag,
                        })
                  }
                />
              )}
              {reservationTags.dietaryReservationTags && (
                <MultiSelectInput
                  label="Please select any dietary requirements"
                  reservationTags={reservationTags.dietaryReservationTags}
                  selectedReservationTags={selectedReservationTags.filter(
                    (t) => t.type === 'dietary'
                  )}
                  onChange={(reservationTag, selected) =>
                    selected
                      ? send({
                          type: 'onSelectReservationTag',
                          reservationTag,
                        })
                      : send({
                          type: 'onDeSelectReservationTag',
                          reservationTag,
                        })
                  }
                />
              )}
              <Controller
                name="notes"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    label="Any additional notes?"
                    {...field}
                    trigger={trigger}
                    errorMessage={error?.message}
                  />
                )}
              />
            </ReservationNotes>
          </Step>
        </DetailsColumn>
        <PaymentColumn>
          <PriceBreakdown>
            <Header>
              <span>Price breakdown</span>
            </Header>
            <Details>
              <Detail>
                <span>Game booking fee</span>
                <span>£{booking?.reservationCost.toFixed(2)}</span>
              </Detail>
              <Detail>
                <span>Packages & upgrades</span>
                <span>
                  <span>
                    £
                    {(
                      (booking?.packagesCost ?? 0) + (booking?.addOnsCost ?? 0)
                    ).toFixed(2)}
                  </span>
                </span>
              </Detail>
              <PriceBreakdownDetails>
                {selectedPackage && (
                  <Detail>
                    <span>{selectedPackage?.name}</span>
                    <span>£{selectedPackage?.charge.toFixed(2)}pp</span>
                  </Detail>
                )}
                {selectedAddOns?.map((addOn) => (
                  <Detail key={addOn.addOnType}>
                    <span>{addOn.name}</span>
                    <span>£{addOn.charge}pp</span>
                  </Detail>
                ))}
              </PriceBreakdownDetails>
            </Details>
            <TotalPrice>
              <span>Total Price</span>
              <span>£{totalBookingCost.total.toFixed(2)}</span>
            </TotalPrice>
            {booking?.promoCode && (
              <PromoDetail>
                <span> Promo - {booking.promoCode.description}</span>
                <span> - £{booking.promoCodeDiscount?.toFixed(2)}</span>
              </PromoDetail>
            )}
            <PromoCodeField />
          </PriceBreakdown>
          {booking?.promoCode && (
            <PromoSuccess>
              <Tick />
              <span>Discount applied!</span>
            </PromoSuccess>
          )}
          {shouldShowDeposit && (
            <PartialPayment>
              <Header>
                <span>To pay today</span>
              </Header>
              <Header>
                <span>Deposit required today</span>
                <span>£{depositAmount?.toFixed(2)}</span>
              </Header>
              <Detail>
                <span>Remainder (to pay at venue)</span>
                <span>
                  £
                  {(
                    (totalBookingCost.total ?? 0) - (depositAmount ?? 0)
                  ).toFixed(2)}
                </span>
              </Detail>
            </PartialPayment>
          )}
        </PaymentColumn>
      </Summary>
    </Container>
  );
};

export default SummaryStep;
