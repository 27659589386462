import Script from 'next/script';

export const TawkScript = () => {
  return (
    <Script
      id="tawk-customer-service-chat"
      dangerouslySetInnerHTML={{
        __html: `
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/627a33fa7b967b11798e9c64/1g2mlm3fd';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();
        `,
      }}
    />
  );
};
