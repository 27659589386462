export const errorMsg = {
  acceptTerms: 'Please accept our terms and conditions',
  resubmitForm:
    'Uh oh, something went wrong. Please try submitting the form again',
  contactDoesNotExist:
    "Couldn't find a booking with the provided email address",
  generic: 'Uh oh... something went wrong',
  hasAccount: 'It looks like you already have an account. Please sign in.',
  incorrectCredentials: 'The email or password entered is incorrect',
  verifySendError:
    'Sorry we were unable to send a verification link at this time',
  verifyError:
    'Oh no, we were unable to verify your email. Try resending the verification link again.',
  unauthorized: 'Unauthorized',
};
