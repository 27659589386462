import React from 'react';
import Select, { components, SingleValueProps } from 'react-select';
import styled from 'styled-components';
import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';

type OptionType = {
  label: string;
  value: string;
};

// Styles for react-select
const StyledSelect = styled(Select)`
  .react-select__control {
    font-size: 0.813rem;
    padding: 0.688rem 1rem;
    border: 1px solid ${COLORS.blueGrey};
    border-radius: 0.5rem;
    cursor: pointer;
    font-family: ${FONTS.poppins};

    &:hover {
      border-color: ${COLORS.blueGrey};
    }
  }

  .react-select__value-container {
    padding: 0;

    & > div {
      padding: 0;
      margin: 0;
    }
  }

  .react-select__menu {
    width: 40vh;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }
`;

const CustomSingleValue = ({
  children,
  ...props
}: SingleValueProps<OptionType, false>) => (
  <components.SingleValue {...props}>{props.data.value}</components.SingleValue>
);

interface PhoneCodeProps {
  options: OptionType[];
  value: string;
  onChange: (value: string) => void;
}

const PhoneCode = ({ options, value, onChange }: PhoneCodeProps) => {
  const handleChange = (selectedOption: OptionType | null) => {
    onChange(selectedOption ? selectedOption.value : '');
  };

  const selectedOption =
    options.find((option) => option.value === value) || null;

  return (
    <StyledSelect
      options={options}
      value={selectedOption}
      onChange={handleChange}
      classNamePrefix="react-select"
      components={{ SingleValue: CustomSingleValue }}
      getOptionLabel={(option: OptionType) => option.label}
    />
  );
};

export default PhoneCode;
