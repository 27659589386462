import { unique } from 'radash';

import { calculateNextThreeDays } from 'utils/bookingFlowUtils';

import {
  BookingFlowAdditionalNotes,
  BookingFlowBillingInfo,
  BookingFlowUserInfo,
  SearchData,
  ThreeDayAvailability,
} from './types';
import {
  fetchAvailabilityResponseSchema,
  CreateBookingInput,
  createBookingResponseSchema,
  CreateBookingData,
  singleDayAvailabilitySchemaCorporate,
  getAvailablePackagesResponseSchema,
  GetAvailablePackagesInput,
  AvailablePackages,
  getAvailableAddOnsResponseSchema,
  GetAvailableAddOnsInput,
  AvailableAddOnsGroup,
  KnownAddOnType,
  allergenReservationTagsSchema,
  AllergenReservationTags,
  DietaryReservationTags,
  dietaryReservationTagsSchema,
  initialiseBookingPaymentResponseSchema,
  BookingPayment,
  completeBookingPaymentAsyncResultSchema,
} from './apiSchemas';
import { FreedomPayAttributes, FreedomPayPaymentKeys } from './freedomPay';

export type FetchAvailabilityResponse =
  | {
      type: 'ok';
      payload: ThreeDayAvailability;
    }
  | {
      type: 'corporateRedirect';
    }
  | {
      type: 'error';
      errorClass: 'dataValidation' | 'network';
    };
export const fetchAvailability = async (
  searchData: SearchData
): Promise<FetchAvailabilityResponse> => {
  const currentDay = searchData.When.date;

  const { datePlusOne, datePlusTwo, datePlusThree } = calculateNextThreeDays(
    searchData.When.date
  );

  const buildUrl = (date: string) => {
    let baseUrl = `/api/sevenrooms/getAvailability?venue_id=${searchData.Where.id}&availability_date=${date}&party_size=${searchData.Who}`;
    if (searchData.Occasion.id) {
      baseUrl += `&occasion_id=${encodeURIComponent(searchData.Occasion.id)}`;
    }
    return baseUrl;
  };

  const dates = unique([
    currentDay,
    datePlusOne,
    datePlusTwo,
    datePlusThree,
  ]).slice(0, 3);

  const urls = dates.map(buildUrl);

  try {
    const responses = await Promise.all(urls.map((url) => fetch(url)));
    const [currentDayData, currentDayPlusOneData, currentDayPlusTwoData] =
      await Promise.all(responses.map((response) => response.json()));

    const res = fetchAvailabilityResponseSchema.safeParse({
      currentDay: currentDayData,
      currentDayPlusOne: currentDayPlusOneData,
      currentDayPlusTwo: currentDayPlusTwoData,
    });

    if (res.success) {
      return {
        payload: {
          currentDay: {
            ...res.data.currentDay.data,
            requestedDay: dates[0],
          },
          currentDayPlusOne: {
            ...res.data.currentDayPlusOne.data,
            requestedDay: dates[1],
          },
          currentDayPlusTwo: {
            ...res.data.currentDayPlusTwo.data,
            requestedDay: dates[2],
          },
        },
        type: 'ok',
      };
    }

    const corporateRes =
      singleDayAvailabilitySchemaCorporate.safeParse(currentDayData);

    if (corporateRes.success) {
      return {
        type: 'corporateRedirect',
      };
    }

    console.error(res.error);

    return {
      type: 'error',
      errorClass: 'dataValidation',
    };
  } catch (err) {
    console.error(err);
    return {
      type: 'error',
      errorClass: 'network',
    };
  }
};

export type CreateBookingResponse =
  | {
      type: 'ok';
      payload: CreateBookingData;
    }
  | {
      type: 'error';
      errorClass: 'dataValidation' | 'network' | 'bookingNotCreated';
    };
export const createBooking = async (
  input: CreateBookingInput
): Promise<CreateBookingResponse> => {
  const bookingPayload = {
    venue_id: input.venueId,
    reservation_date: input.reservationDate,
    reservation_time: input.reservationTime,
    party_size: input.partySize,
    access_persistent_id: input.accessPersistentId,
    shift_persistent_id: input.shiftPersistentId,
    ...(input.occasionId && {
      occasion_id: input.occasionId,
    }),
  };

  try {
    const response = await fetch('/api/sevenrooms/createBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bookingPayload),
    });

    const data = await response.json();
    if (!response.ok) {
      console.error(`Booking not created`, data);
      return {
        type: 'error',
        errorClass: 'bookingNotCreated',
      };
    }

    const parsed = createBookingResponseSchema.safeParse(data);

    if (parsed.success) {
      return {
        type: 'ok',
        payload: parsed.data.data,
      };
    }

    console.error(`Parsing error`, parsed.error);

    return {
      type: 'error',
      errorClass: 'dataValidation',
    };
  } catch (error) {
    console.error(`Fetch threw`, error);
    return {
      type: 'error',
      errorClass: 'network',
    };
  }
};

export type GetAvailablePackagesResponse =
  | {
      type: 'ok';
      payload: AvailablePackages;
    }
  | {
      type: 'error';
      errorClass: 'dataValidation' | 'network';
    };
export const getAvailablePackages = async ({
  bookingId,
}: GetAvailablePackagesInput): Promise<GetAvailablePackagesResponse> => {
  try {
    const response = await fetch(
      `/api/sevenrooms/getPackages?bookingId=${bookingId}`
    );

    const data = await response.json();

    const parsed = getAvailablePackagesResponseSchema.safeParse(data);

    if (parsed.success) {
      return {
        type: 'ok',
        payload: parsed.data.data.packages,
      };
    }

    console.error(`Parsing error`, parsed.error);

    return {
      type: 'error',
      errorClass: 'dataValidation',
    };
  } catch (error) {
    console.error(`Fetch threw`, error);
    return {
      type: 'error',
      errorClass: 'network',
    };
  }
};

type UpdateBookingPackagesInput = {
  bookingId: string;
  packageIds: string[];
};
export type UpdatePackagesResponse =
  | {
      type: 'ok';
      payload: CreateBookingData;
    }
  | {
      type: 'error';
      errorClass: 'dataValidation' | 'network' | 'packagesNotUpdated';
    };
export const updateBookingPackages = async ({
  bookingId,
  packageIds,
}: UpdateBookingPackagesInput): Promise<UpdatePackagesResponse> => {
  try {
    const response = await fetch(
      `/api/sevenrooms/bookingPackages?bookingId=${bookingId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          packages: packageIds.map((p) => ({ package_id: p })),
        }),
      }
    );

    const data = await response.json();
    if (!response.ok) {
      console.error(`Packages not updated`, data);
      return {
        type: 'error',
        errorClass: 'packagesNotUpdated',
      };
    }

    const parsed = createBookingResponseSchema.safeParse(data);

    if (parsed.success) {
      return {
        type: 'ok',
        payload: parsed.data.data,
      };
    }

    console.error(`Parsing error`, parsed.error);

    return {
      type: 'error',
      errorClass: 'dataValidation',
    };
  } catch (error) {
    console.error(`Fetch threw`, error);
    return {
      type: 'error',
      errorClass: 'network',
    };
  }
};

type DeleteBookingPackageInput = {
  bookingId: string;
  packageId: string;
};
export type DeleteBookingPackagesResponse =
  | {
      type: 'ok';
      payload: CreateBookingData;
    }
  | {
      type: 'error';
      errorClass: 'dataValidation' | 'network' | 'packageNotDeleted';
    };
export const deleteBookingPackage = async ({
  packageId,
  bookingId,
}: DeleteBookingPackageInput): Promise<DeleteBookingPackagesResponse> => {
  try {
    const response = await fetch(
      `/api/sevenrooms/bookingPackages?bookingId=${bookingId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          package_id: packageId,
        }),
      }
    );

    const data = await response.json();
    if (!response.ok) {
      console.error(`Packages not deleted`, data);
      return {
        type: 'error',
        errorClass: 'packageNotDeleted',
      };
    }

    const parsed = createBookingResponseSchema.safeParse(data);

    if (parsed.success) {
      return {
        type: 'ok',
        payload: parsed.data.data,
      };
    }

    console.error(`Parsing error`, parsed.error);

    return {
      type: 'error',
      errorClass: 'dataValidation',
    };
  } catch (error) {
    console.error(`Fetch threw`, error);
    return {
      type: 'error',
      errorClass: 'network',
    };
  }
};

export type GetAvailableAddOnsResponse =
  | {
      type: 'ok';
      payload: AvailableAddOnsGroup[];
    }
  | {
      type: 'error';
      errorClass: 'dataValidation' | 'network';
    };
export const getAvailableAddOns = async ({
  bookingId,
}: GetAvailableAddOnsInput): Promise<GetAvailableAddOnsResponse> => {
  try {
    const response = await fetch(
      `/api/sevenrooms/bookingAddOns?bookingId=${bookingId}`
    );

    const data = await response.json();

    const parsed = getAvailableAddOnsResponseSchema.safeParse(data);

    if (parsed.success) {
      return {
        type: 'ok',
        payload: parsed.data.data.addOnGroups,
      };
    }

    console.error(`Parsing error`, parsed.error);

    return {
      type: 'error',
      errorClass: 'dataValidation',
    };
  } catch (error) {
    console.error(`Fetch threw`, error);
    return {
      type: 'error',
      errorClass: 'network',
    };
  }
};

type UpdateBookingAddOnsInput = {
  bookingId: string;
  addOnTypes: KnownAddOnType[];
};
export type UpdateBookingAddOnsResponse =
  | {
      type: 'ok';
      payload: CreateBookingData;
    }
  | {
      type: 'error';
      errorClass: 'dataValidation' | 'network' | 'addOnsNotUpdated';
    };

export const updateBookingAddOns = async ({
  bookingId,
  addOnTypes,
}: UpdateBookingAddOnsInput): Promise<UpdateBookingAddOnsResponse> => {
  try {
    const response = await fetch(
      `/api/sevenrooms/updateBookingAddOns?bookingId=${bookingId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          add_ons: addOnTypes.map((a) => ({ add_on_type: a })),
        }),
      }
    );

    const data = await response.json();
    if (!response.ok) {
      console.error(`Add-ons not updated`, data);
      return {
        type: 'error',
        errorClass: 'addOnsNotUpdated',
      };
    }

    const parsed = createBookingResponseSchema.safeParse(data);

    if (parsed.success) {
      return {
        type: 'ok',
        payload: parsed.data.data,
      };
    }

    console.error(`Parsing error`, parsed.error);

    return {
      type: 'error',
      errorClass: 'dataValidation',
    };
  } catch (error) {
    console.error(`Fetch threw`, error);
    return {
      type: 'error',
      errorClass: 'network',
    };
  }
};

export type GetAllergenReservationTagsResponse =
  | {
      type: 'ok';
      payload: AllergenReservationTags;
    }
  | {
      type: 'error';
      errorClass: 'dataValidation' | 'network';
    };
export const getAllergenReservationTags =
  async (): Promise<GetAllergenReservationTagsResponse> => {
    try {
      const response = await fetch(`/api/sevenrooms/getAllergenTags`);

      const data = await response.json();

      const parsed = allergenReservationTagsSchema.safeParse(data);

      if (parsed.success) {
        return {
          type: 'ok',
          payload: parsed.data,
        };
      }

      console.error(`Parsing error`, parsed.error);

      return {
        type: 'error',
        errorClass: 'dataValidation',
      };
    } catch (error) {
      console.error(`Fetch threw`, error);
      return {
        type: 'error',
        errorClass: 'network',
      };
    }
  };

export type GetDietaryReservationTagsResponse =
  | {
      type: 'ok';
      payload: DietaryReservationTags;
    }
  | {
      type: 'error';
      errorClass: 'dataValidation' | 'network';
    };
export const getDietaryReservationTags =
  async (): Promise<GetDietaryReservationTagsResponse> => {
    try {
      const response = await fetch(`/api/sevenrooms/getDietaryTags`);

      const data = await response.json();

      const parsed = dietaryReservationTagsSchema.safeParse(data);

      if (parsed.success) {
        return {
          type: 'ok',
          payload: parsed.data,
        };
      }

      console.error(`Parsing error`, parsed.error);

      return {
        type: 'error',
        errorClass: 'dataValidation',
      };
    } catch (error) {
      console.error(`Fetch threw`, error);
      return {
        type: 'error',
        errorClass: 'network',
      };
    }
  };

type UpdateBookingReservationTagsInput = {
  bookingId: string;
  reservationTagIds: string[];
};
export type UpdateBookingReservationTagsResponse =
  | {
      type: 'ok';
      payload: CreateBookingData;
    }
  | {
      type: 'error';
      errorClass: 'dataValidation' | 'network' | 'reservationTagsNotUpdated';
    };
export const updateBookingReservationTags = async ({
  bookingId,
  reservationTagIds,
}: UpdateBookingReservationTagsInput): Promise<UpdateBookingReservationTagsResponse> => {
  try {
    const response = await fetch(
      `/api/sevenrooms/updateBookingReservationTags?bookingId=${bookingId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reservation_tags: reservationTagIds.map((id) => ({ id })),
        }),
      }
    );

    const data = await response.json();
    if (!response.ok) {
      console.error(`Reservation tags not updated`, data);
      return {
        type: 'error',
        errorClass: 'reservationTagsNotUpdated',
      };
    }

    const parsed = createBookingResponseSchema.safeParse(data);

    if (parsed.success) {
      return {
        type: 'ok',
        payload: parsed.data.data,
      };
    }

    console.error(`Parsing error`, parsed.error);

    return {
      type: 'error',
      errorClass: 'dataValidation',
    };
  } catch (error) {
    console.error(`Fetch threw`, error);
    return {
      type: 'error',
      errorClass: 'network',
    };
  }
};

type UpdateBookingBillingInput = {
  bookingId: string;
  billingInfo: BookingFlowBillingInfo;
  userInfo: BookingFlowUserInfo;
  additionalNotes: BookingFlowAdditionalNotes;
};
export type UpdateBookingBillingResponse =
  | {
      type: 'ok';
      payload: CreateBookingData;
    }
  | {
      type: 'error';
      errorClass: 'dataValidation' | 'network' | 'billingNotUpdated';
    };
export const updateBookingBilling = async ({
  bookingId,
  billingInfo,
  userInfo,
  additionalNotes,
}: UpdateBookingBillingInput): Promise<UpdateBookingBillingResponse> => {
  try {
    const response = await fetch(
      `/api/sevenrooms/updateBookingBilling?bookingId=${bookingId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name_on_card: billingInfo.name,
          email: userInfo.email,
          salutation: '',
          first_name: userInfo.firstName,
          last_name: userInfo.lastName,
          phone: `${userInfo.mobileNumber.code}${userInfo.mobileNumber.number}`,
          address: billingInfo.addressLine1,
          address_2: billingInfo.addressLine2,
          city: billingInfo.city,
          state: '',
          post_code: billingInfo.postcode,
          country_code: billingInfo.country,
          date_of_birth: new Date(userInfo.dob).toISOString().split('T')[0],
          marketing_opt_in: userInfo.newsletter,
          notes: additionalNotes.notes,
        }),
      }
    );

    const data = await response.json();
    if (!response.ok) {
      console.error(`Billing not updated`, data);
      return {
        type: 'error',
        errorClass: 'billingNotUpdated',
      };
    }

    const parsed = createBookingResponseSchema.safeParse(data);

    if (parsed.success) {
      return {
        type: 'ok',
        payload: parsed.data.data,
      };
    }

    console.error(`Parsing error`, parsed.error);

    return {
      type: 'error',
      errorClass: 'dataValidation',
    };
  } catch (error) {
    console.error(`Fetch threw`, error);
    return {
      type: 'error',
      errorClass: 'network',
    };
  }
};

export type InitialiseBookingPaymentResponse =
  | {
      type: 'ok';
      payload: BookingPayment;
    }
  | {
      type: 'error';
      errorClass: 'dataValidation' | 'network' | 'paymentNotInitialised';
    };
export const initialiseBookingPayment = async ({
  bookingId,
}: {
  bookingId: string;
}): Promise<InitialiseBookingPaymentResponse> => {
  try {
    const response = await fetch(
      `/api/sevenrooms/initialiseBookingPayment?bookingId=${bookingId}`,
      { method: 'POST' }
    );

    const data = await response.json();
    if (!response.ok) {
      console.error(`Payment not initialised`, data);
      return {
        type: 'error',
        errorClass: 'paymentNotInitialised',
      };
    }

    const parsed = initialiseBookingPaymentResponseSchema.safeParse(data);

    if (parsed.success) {
      return {
        type: 'ok',
        payload: parsed.data.data,
      };
    }

    console.error(`Parsing error`, parsed.error);

    return {
      type: 'error',
      errorClass: 'dataValidation',
    };
  } catch (error) {
    console.error(`Fetch threw`, error);
    return {
      type: 'error',
      errorClass: 'network',
    };
  }
};

type CompleteBookingPaymentAsyncInitInput = {
  bookingId: string;
  attributes: FreedomPayAttributes;
  paymentKeys: FreedomPayPaymentKeys;
};

export type CompleteBookingPaymentInitResponse =
  | { type: 'ok' }
  | { type: 'error' };
export const completeBookingPaymentAsyncInit = async ({
  bookingId,
  attributes,
  paymentKeys,
}: CompleteBookingPaymentAsyncInitInput): Promise<CompleteBookingPaymentInitResponse> => {
  try {
    const response = await fetch(
      `/api/sevenrooms/completeBookingPaymentAsyncInit?bookingId=${bookingId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payment_key: paymentKeys.find(Boolean),
          card_issuer: attributes.cardIssuer,
          masked_card_number: attributes.maskedCardNumber,
        }),
      }
    );

    if (response.status === 202) {
      return {
        type: 'ok',
      };
    }
  } catch (error) {
    console.error(error);
  }

  return {
    type: 'error',
  };
};

type CompleteBookingPaymentAsyncResultInput = {
  bookingId: string;
};
type CompleteBookingPaymentAsyncResultResponse =
  | {
      type: 'ok';
      payload: CreateBookingData;
    }
  | {
      type: 'incomplete';
    }
  | {
      type: 'error';
      errorClass:
        | 'dataValidation'
        | 'network'
        | 'paymentNotCompleted'
        | 'softDecline';
    };

export const completeBookingPaymentAsyncResult = async ({
  bookingId,
}: CompleteBookingPaymentAsyncResultInput): Promise<CompleteBookingPaymentAsyncResultResponse> => {
  try {
    const response = await fetch(
      `/api/sevenrooms/completeBookingPaymentAsyncResult?bookingId=${bookingId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 202) {
      return {
        type: 'incomplete',
      };
    }

    const data = await response.json();

    const parsed = completeBookingPaymentAsyncResultSchema.safeParse(data);

    if (parsed.success) {
      if (parsed.data.status === 202) {
        return {
          type: 'incomplete',
        };
      }

      if (parsed.data.status === 200) {
        return {
          type: 'ok',
          payload: parsed.data.data,
        };
      }

      console.error(`Payment not completed`, data);

      // Figure out if it's a soft decline.
      const isSoftDecline = !!parsed.data.errors?.payment.find(
        (e) => e.includes('(216)') || e.includes('(217)')
      );
      if (isSoftDecline) {
        return {
          type: 'error',
          errorClass: 'softDecline',
        };
      }

      return {
        type: 'error',
        errorClass: 'paymentNotCompleted',
      };
    }

    console.error(
      response.ok
        ? 'Ok response could not be parsed'
        : 'Error response could not be parsed',
      parsed.error
    );

    // If the response is ok, but we can't parse the data, it's a data
    // validation error rather than a failure to process the payment.
    if (response.ok) {
      return {
        type: 'error',
        errorClass: 'dataValidation',
      };
    }

    // We don't handle all error cases in the API schema, so this is a fall
    // through for any other errors.
    return {
      type: 'error',
      errorClass: 'paymentNotCompleted',
    };
  } catch (error) {
    console.error(`Fetch threw`, error);
    return {
      type: 'error',
      errorClass: 'network',
    };
  }
};

type AddBookingPromoCodeInput = {
  bookingId: string;
  promoCode: string;
};
export type AddBookingPromoCodeResponse =
  | {
      type: 'ok';
      payload: CreateBookingData;
    }
  | {
      type: 'error';
      errorClass: 'dataValidation' | 'network' | 'promoCodeNotAdded';
    };

export const addBookingPromoCode = async ({
  bookingId,
  promoCode,
}: AddBookingPromoCodeInput): Promise<AddBookingPromoCodeResponse> => {
  try {
    const response = await fetch(
      `/api/sevenrooms/addBookingPromoCode?bookingId=${bookingId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code: promoCode,
        }),
      }
    );

    const data = await response.json();
    if (!response.ok) {
      console.error(`Promo code not added`, data);
      return {
        type: 'error',
        errorClass: 'promoCodeNotAdded',
      };
    }

    const parsed = createBookingResponseSchema.safeParse(data);

    if (parsed.success) {
      return {
        type: 'ok',
        payload: parsed.data.data,
      };
    }

    console.error(`Parsing error`, parsed.error);

    return {
      type: 'error',
      errorClass: 'dataValidation',
    };
  } catch (error) {
    console.error('Fetch threw', error);
    return {
      type: 'error',
      errorClass: 'network',
    };
  }
};
