import fetch from 'node-fetch';
import { WEBSITE_URL } from 'utils/constants';

export const baseUrl = `${process.env.ATREEMO_URL}/api`;
export const apiRouteUrl = `${WEBSITE_URL}/api`;

const hasAccessToken = (token) => token && { Authorization: `Bearer ${token}` };

export const fetchData = async (url, token) => {
  const res = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...hasAccessToken(token) },
  });

  if (!res.ok) {
    const error = await res.json();
    throw new Error(
      error.Message
        ? `Error: ${error.Message} Details: ${error.MessageDetail}`
        : error.error
    );
  }

  return res.json();
};

export const postData = async (url, data, token, customErr = false) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...hasAccessToken(token) },
    body: JSON.stringify(data),
  });

  if (!res.ok && !customErr) {
    const error = await res.json();
    throw new Error(
      error?.Message
        ? `Error: ${error?.Message} Details: ${error?.MessageDetail}`
        : error.error
    );
  }

  return res.json();
};

export const updateData = async (url, data, token) => {
  const res = await fetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...hasAccessToken(token) },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    const error = await res.json();
    throw new Error(`Error: ${error.Message} Details: ${error.MessageDetail}`);
  }

  return res.json();
};

export const fetchContactByEmail = async (email, token) => {
  let contact = null;

  const { CtcIDs, Error } = await fetchData(
    `${baseUrl}/Contact/GetContactIDsByEmail?Email=${email}`,
    token
  );

  if (CtcIDs?.length) {
    contact = await fetchData(
      `${baseUrl}/Contact/Get/${CtcIDs[0].CtcID}`,
      token
    );
  }

  return { contact, Error };
};
