import { keyframes } from 'styled-components';

export const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const fadeTranslateInOut = keyframes`
  8%, 88% {
    opacity: 1;
    transform: translateY(0);
  }
  95% { transform: translateY(-100px) }
  100% { opacity: 0 }
`;

export const fadeInOut = keyframes`
  0% { opacity: 0.8 }
  50% { opacity: 0.5 }
  100% { opacity: 0.8 }
`;

export const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;
