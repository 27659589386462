/* eslint-disable no-console */
import * as React from 'react';
import { useLockBodyScroll } from '../hooks/useLockBodyScroll';

import { isMobileDevice } from 'utils/helpers';

export const NavContext = React.createContext({
  modalIsOpen: false,
  setModalIsOpen: () => {},
  burgerMenuIsOpen: false,
  toggleBurgerMenu: () => {},
  isSearchModalOpen: false,
  setSearchModalOpen: () => {},
  activeSearchDropdown: null,
  setActiveSearchDropdown: () => {},
  isolate: false,
  setIsolate: () => {},
  setIsBookingModalOpen: (isOpen) => {},
  toggleBookingModal: (toggle) => {},
  isExitBookingAlertOpen: false,
  setIsExitBookingAlertOpen: (isOpen) => {},
  isBookingModalOpen: false,
});

const NavProvider = ({ children }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [burgerMenuIsOpen, setBurgerMenuIsOpen] = React.useState(false);
  const [activeSearchDropdown, setActiveSearchDropdown] = React.useState(null);
  const [isSearchModalOpen, setSearchModalOpen] = React.useState(false);
  const [isBookingModalOpen, setIsBookingModalOpen] = React.useState(false);
  const [isExitBookingAlertOpen, setIsExitBookingAlertOpen] =
    React.useState(false);

  const [isolate, setIsolate] = React.useState(false);

  // Lock or unlock body scroll based on modalIsOpen state
  useLockBodyScroll(modalIsOpen);

  const handleResponsiveState = () => {
    if (!isMobileDevice()) {
      // Close mobile search modal and reset active dropdown for desktop
      setSearchModalOpen(false);
      setActiveSearchDropdown(null);
    } else {
      setActiveSearchDropdown(null);
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResponsiveState);

    handleResponsiveState();

    return () => window.removeEventListener('resize', handleResponsiveState);
  }, [setSearchModalOpen, setActiveSearchDropdown]);

  // Set modalIsOpen state based on other modal states
  React.useEffect(() => {
    const anyModalOpen =
      burgerMenuIsOpen || isSearchModalOpen || isBookingModalOpen;
    setModalIsOpen(anyModalOpen);
  }, [burgerMenuIsOpen, isSearchModalOpen, isBookingModalOpen]);

  const toggleBurgerMenu = () => {
    setBurgerMenuIsOpen((prev) => !prev);
  };

  const toggleBookingModal = async () => {
    setIsBookingModalOpen((prev) => !prev);
  };

  const value = {
    setModalIsOpen,
    modalIsOpen,
    toggleBurgerMenu,
    burgerMenuIsOpen,
    setBurgerMenuIsOpen,
    isSearchModalOpen,
    setSearchModalOpen,
    activeSearchDropdown,
    setActiveSearchDropdown,
    isBookingModalOpen,
    setIsBookingModalOpen,
    toggleBookingModal,
    isExitBookingAlertOpen,
    setIsExitBookingAlertOpen,
    setIsolate,
    isolate,
  };

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

export const useNav = () => React.useContext(NavContext);

export default NavProvider;
