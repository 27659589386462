import React from 'react';
import styled from 'styled-components';
import useEmblaCarousel from 'embla-carousel-react';
import COLORS from 'styles/colors';

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
`;

const TimeStepPaddingX = '1.25rem';
const Container = styled.div`
  display: flex;
  touch-action: pan-y;
`;

const Slide = styled.div`
  display: flex;
  flex: 0 0 20%;
  align-items: center;
  justify-content: center;
  min-width: 0;
  grid-column-gap: ${TimeStepPaddingX};
`;

const ButtonSize = '2.5rem';
const CarouselButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.blueGrey};
  height: ${ButtonSize};
  width: ${ButtonSize};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const LeftButton = styled(CarouselButton)`
  left: 0;
`;

const RightButton = styled(CarouselButton)`
  right: 0;
`;

const Arrow = styled.img`
  aspect-ratio: 1;
`;

export const TimeCarousel = ({
  children,
  startingPosition,
}: {
  children: React.ReactNode;
  startingPosition: number | null;
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ skipSnaps: true });

  const scrollPrev = React.useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = React.useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  React.useEffect(() => {
    if (typeof startingPosition === 'number') {
      emblaApi?.scrollTo(startingPosition - 1, true);
    }
  }, [startingPosition, emblaApi]);

  React.useEffect(() => {
    if (emblaApi) {
      emblaApi.reInit();
    }
  }, [emblaApi, children]);

  return (
    <Wrapper ref={emblaRef}>
      <Container>
        {React.Children.map(children, (child, index) => (
          <Slide key={index}>{child}</Slide>
        ))}
      </Container>
      <LeftButton onClick={scrollPrev}>
        <Arrow src={'/images/icons/arrow-left-calendar.svg'} />
      </LeftButton>
      <RightButton onClick={scrollNext}>
        <Arrow src={'/images/icons/arrow-right-calendar.svg'} />
      </RightButton>
    </Wrapper>
  );
};

export default TimeCarousel;
