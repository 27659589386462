import * as React from 'react';
import { useBookingFlowManager } from './Provider';
import LoadingStep from 'components/BookingFlow/BookingSteps/LoadingStep';
import TimeStep from 'components/BookingFlow/BookingSteps/TimeStep';
import GameAreaStep from 'src/components/BookingFlow/BookingSteps/GameAreaStep';
import PackageStep from 'src/components/BookingFlow/BookingSteps/PackageStep';
import AddonsStep from 'src/components/BookingFlow/BookingSteps/AddonsStep';
import SummaryStep from 'src/components/BookingFlow/BookingSteps/SummaryStep';
import UserInfoStep from 'src/components/BookingFlow/BookingSteps/UserInfoStep';
import BillingInfoStep from 'src/components/BookingFlow/BookingSteps/BillingInfoStep';
import PaymentStep from 'src/components/BookingFlow/BookingSteps/PaymentStep';
import ConfirmationStep from 'src/components/BookingFlow/BookingSteps/ConfirmationStep';

export type StepContent = {
  header: string | null;
  content: React.ReactNode;
};

export const useStepContent = (): StepContent => {
  const { cmsContent, state } = useBookingFlowManager();

  switch (true) {
    case state.matches('preLoad'):
      return {
        header: null,
        content: <LoadingStep text="Searching..." />,
      };

    case state.matches('time'):
      return {
        header: 'Choose your time',
        content: <TimeStep />,
      };

    case state.matches('creatingBooking'):
      return {
        header: null,
        content: <LoadingStep text="Loading..." />,
      };

    case state.matches('gameArea'):
      return {
        header: cmsContent?.chooseGameArea?.header || 'Choose your game area',
        content: cmsContent ? (
          <GameAreaStep content={cmsContent?.chooseGameArea} />
        ) : null,
      };

    case state.matches('package'):
      return {
        header: 'Select Your Food and Drink',
        content: <PackageStep />,
      };

    case state.matches('updatingPackage'):
      return {
        header: null,
        content: <LoadingStep text="Updating booking..." />,
      };

    case state.matches('addons'):
      return {
        header: 'Extend your visit',
        content: <AddonsStep />,
      };

    case state.matches('updatingAddOns'):
      return {
        header: null,
        content: <LoadingStep text="Updating booking..." />,
      };

    case state.matches('userInfo'):
      return {
        header: 'Your details',
        content: <UserInfoStep />,
      };

    case state.matches('summary'):
      return {
        header: 'Summary',
        content: <SummaryStep />,
      };

    case state.matches('billingInfo'):
      return {
        header: 'Billing Info',
        content: <BillingInfoStep />,
      };

    case state.matches('initialisingPayment'):
      return {
        header: null,
        content: <LoadingStep text="Updating booking..." />,
      };

    // We keep the payment step mounted during `completingPayment` in-case of a
    // soft decline where we need to re-submit the payment.
    case state.matches('payment'):
    case state.matches('completingPayment'):
      return {
        header: state.matches('completingPayment') ? null : 'Payment',
        content: (
          <>
            <div
              style={{
                visibility: state.matches('completingPayment')
                  ? 'hidden'
                  : 'visible',
                position: state.matches('completingPayment')
                  ? 'absolute'
                  : 'relative',
              }}
            >
              <PaymentStep />
            </div>
            {state.matches('completingPayment') && (
              <LoadingStep text="Processing payment..." />
            )}
          </>
        ),
      };

    case state.matches('confirmation'):
      return {
        header: 'Preparing your visit schedule',
        content: <LoadingStep text="Preparing booking info..." />,
      };

    default:
      throw new Error('Step not defined');
  }
};
