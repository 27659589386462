import styled from 'styled-components';

import ArrowDown from 'public/images/icons/arrow-right-calendar.svg';
import LoadingSpinner from './LoadingSpinner';

import FONTS from 'styles/fonts';
import COLORS from 'styles/colors';
import BREAKPOINTS from 'styles/breakpoints';

const Container = styled.button`
  color: ${COLORS.forestBlack};
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border: none;
  border-top: 1px solid ${COLORS.tealMuted};
  border-bottom: 1px solid ${COLORS.tealMuted};
  background: transparent;
  cursor: pointer;
  height: 2.5rem;

  &:focus-visible {
    outline: 1px solid -webkit-focus-ring-color;
  }

  ${BREAKPOINTS.medium`
  background-color: ${COLORS.white};
  `}
`;

const ArrowIcon = styled(ArrowDown)`
  transform: rotate(
    ${(props) => (props.type === 'previous' ? '270deg' : '90deg')}
  );
  path {
    fill: currentColor;
  }
`;

const Text = styled.span`
  font-size: 0.8125rem;
  font-family: ${FONTS.poppins};
`;

const types = ['next', 'previous'];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const DaySelectButton = ({ type, onClick, isLoading, className = '' }) => {
  const selectedType = types.includes(type.toLowerCase())
    ? capitalizeFirstLetter(type.toLowerCase())
    : capitalizeFirstLetter('next');

  return (
    <Container onClick={onClick} disabled={isLoading} className={className}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <ArrowIcon type={type} />
          <Text>{selectedType} day</Text>
        </>
      )}
    </Container>
  );
};

export default DaySelectButton;
