import styled, { css } from 'styled-components';
import FONTS from 'styles/fonts';
import COLORS from 'styles/colors';

const packageColors = {
  default: 'transparent',
  gold: COLORS.packageGold,
  silver: COLORS.packageSilver,
  bronze: COLORS.packageBronze,
};

const Container = styled.div`
  /* LAYOUT */
  box-sizing: border-box;
  align-items: center;
  height: 1.5rem;
  padding: ${({ packageType }) =>
    packageType === 'default' ? 0 : '0.25rem 0.5rem'};
  display: flex;
  gap: 0.5rem;

  /* STYLES */
  background-color: ${({ packageType }) => `${packageColors[packageType]}`};
  border: 1px solid transparent;
  border-radius: 6.25rem;
  font-family: ${FONTS.poppins};

  ${({ openStyle }) =>
    openStyle &&
    css`
      background-color: transparent;
      border: 1px solid ${({ packageType }) => `${packageColors[packageType]}`};

      & > ${Text} {
        color: ${COLORS.white};
      }
    `}
`;

const Text = styled.span`
  font-size: ${({ packageType }) =>
    packageType === 'default' ? '1rem' : '0.8125rem'};
  font-weight: ${({ packageType }) =>
    packageType === 'default' ? '600' : '400'};
  color: ${COLORS.black};
  text-transform: capitalize;
`;

const PackagePill = ({ name = 'default', openStyle = false }) => {
  const packageType = ['gold', 'silver', 'bronze'].includes(name.toLowerCase())
    ? name.toLowerCase()
    : 'default';

  return (
    <Container packageType={packageType} openStyle={openStyle}>
      <Text packageType={packageType}>{name}</Text>
    </Container>
  );
};

export default PackagePill;
