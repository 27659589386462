import * as zod from 'zod';

const gameAreaSectionSchema = zod.object({
  header: zod.string(),
  cardHeader: zod.string(),
  cardDescription: zod.string(),
  cardImage: zod.object({
    url: zod.string(),
  }),
});

export type GameAreaSection = zod.infer<typeof gameAreaSectionSchema>;

const chooseGameAreaSchema = zod.object({
  header: zod.string(),
  smallGroup: gameAreaSectionSchema,
  largeGroup: gameAreaSectionSchema,
  unavailableMessage: zod.string(),
});

export type ChooseGameArea = zod.infer<typeof chooseGameAreaSchema>;

export const cmsContentSchema = zod.object({
  chooseGameArea: chooseGameAreaSchema,
});

export type CmsContent = zod.infer<typeof cmsContentSchema>;
