import * as React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import Script from 'next/script';

import BREAKPOINTS, { breakpointSizes } from 'styles/breakpoints';
import COLORS from 'styles/colors';
import {
  subscribeToFreedomPayEvents,
  useBookingFlowManager,
} from 'src/context/bookingFlow';
import Nudge from '../Nudge';
import PaymentCardsList from '../PaymentCardsList';
import SummaryTabInline from '../SummaryTabInline';

const { log } = console;

const { NEXT_PUBLIC_FREEDOM_PAY_SONG_BIRD } = process.env;

const Step = styled.div`
  padding: 1rem;
  ${BREAKPOINTS.medium`
          padding: 1.5rem;
    `}
`;

const FormContainer = styled.div`
  height: fit-content;
  background-color: ${COLORS.white};
  padding: 1rem;
  border-radius: 0.5rem;
  display: grid;
  gap: 1rem;
`;

const IframeWrapper = styled.div<{
  iframeHeight: number;
}>`
  iframe {
    width: 100%;
    height: ${({ iframeHeight }) => `${iframeHeight}px`};
    border: none;
  }
`;

const StyledPaymentCardsList = styled(PaymentCardsList)`
  margin-top: 1rem;
  ${BREAKPOINTS.medium`
        margin-top: 1.65rem;
    `}
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 1rem;

  margin-top: 1rem;

  ${BREAKPOINTS.medium`
    grid-template-columns: 1fr 1fr;
    margin-top: 1.5rem;
    `}
`;

const PaymentStep = () => {
  const { bookingPayment, setFreedomPayIframeId } = useBookingFlowManager();
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${breakpointSizes.medium}px)`,
  });
  const [iframeHeight, setIframeHeight] = React.useState(0);
  const iframeWrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const sub = subscribeToFreedomPayEvents((event) => {
      if (event.type === 'onSetIframeHeight') {
        setIframeHeight(event.height);
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    // Get the id of the iframe child of iframeWrapperRef.current and set it in
    // the bookingFlowManager so that we can use it to send messages to the
    // iframe.
    const iframe = iframeWrapperRef.current?.querySelector('iframe');
    if (iframe) {
      setFreedomPayIframeId(iframe.id);
    }
  }, [setFreedomPayIframeId]);

  return (
    <Step>
      <Nudge
        text="All transactions are secure and encrypted."
        rounded
        hasBackground={isDesktopOrLaptop ? true : false}
        hasIcon={isDesktopOrLaptop ? true : false}
      />

      <StyledPaymentCardsList />

      <Script
        src={NEXT_PUBLIC_FREEDOM_PAY_SONG_BIRD}
        strategy="afterInteractive"
        onLoad={() => {
          log(`${NEXT_PUBLIC_FREEDOM_PAY_SONG_BIRD} loaded`);
        }}
      />

      <Container>
        <FormContainer>
          {iframeHeight === 0 && (
            <Nudge text="Loading payment form..." hasIcon={false} />
          )}

          {bookingPayment ? (
            <IframeWrapper
              ref={iframeWrapperRef}
              iframeHeight={iframeHeight}
              dangerouslySetInnerHTML={{
                __html: bookingPayment.iframe,
              }}
            />
          ) : (
            <Nudge text="Something went wrong. Please try again later." />
          )}
        </FormContainer>
        {isDesktopOrLaptop && <SummaryTabInline />}
      </Container>
    </Step>
  );
};

export default PaymentStep;
