import Script from 'next/script';

const ServerGTMScript = () => {
  return (
    <>
      <Script strategy="beforeInteractive" id="fun-times-with-sgtm">
        {`(function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://load.cdn.clays.bar/jfwqwmac.js?st=' + i + dl + '';
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'sDataLayer', 'N2QX6N7Q');
        `}
      </Script>
    </>
  );
};

export default ServerGTMScript;
