import { createContext, useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import { hasLocalStorage, formatPathname } from 'utils/helpers';

type ContextShape = {
  claysLocation: string | null;
  allClaysLocations: any[];
  isLocationGatePage: boolean;
};

export const ClaysLocationContext = createContext<ContextShape>({
  allClaysLocations: [],
  claysLocation: null,
  isLocationGatePage: false,
});

const ClaysLocationProvider = ({ children }) => {
  const [claysLocation, setClaysLocation] = useState<string | null>(null);
  const [allClaysLocations, setAllClaysLocations] = useState<any[]>([]);
  const [isLocationGatePage, setIsLocationGatePage] = useState(false);

  const {
    asPath,
    query: { location },
  } = useRouter();

  // Fetch all location data
  useEffect(() => {
    const getAllClaysLocations = async () => {
      try {
        const endpointUrl = `/api/all-clays-locations`;
        const data = await fetch(endpointUrl);
        const json = await data.json();

        setAllClaysLocations(json.data || []);
      } catch (e) {
        console.error(e);
      }
    };

    getAllClaysLocations();
  }, []);

  // Store selected location
  useEffect(() => {
    if (location) {
      const _location = Array.isArray(location)
        ? location.find(Boolean)
        : location;
      if (_location) {
        setClaysLocation(_location);
        if (hasLocalStorage()) {
          localStorage.setItem('claysLocation', _location);
        }
      }
    }

    if (!location && hasLocalStorage()) {
      setClaysLocation(localStorage.claysLocation);
    }
  }, [location]);

  useEffect(() => {
    setIsLocationGatePage(formatPathname(asPath) === '/');
  }, [asPath]);

  return (
    <ClaysLocationContext.Provider
      value={{
        claysLocation,
        allClaysLocations,
        isLocationGatePage,
      }}
    >
      {children}
    </ClaysLocationContext.Provider>
  );
};

export const useClaysLocation = () => useContext(ClaysLocationContext);

export default ClaysLocationProvider;
