import styled, { css } from 'styled-components';

import FONTS from 'styles/fonts';
import COLORS from 'styles/colors';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;

  border: 1px solid ${COLORS.blueGrey};
  border-radius: 0.5rem;

  font-size: 0.8125rem;
  font-family: ${FONTS.poppins};

  color: inherit;
  background-color: inherit;

  ${({ disabled }) =>
    disabled &&
    css`
      border: none;
    `}
`;

const AddOnCard = ({ valueOne, valueTwo, disabled = false }) => {
  return (
    <Container disabled={disabled}>
      {valueOne && <span>{valueOne}</span>}
      {valueTwo && <span>{valueTwo}</span>}
    </Container>
  );
};

export default AddOnCard;
