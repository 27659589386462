import * as React from 'react';
import styled from 'styled-components';

import Input from './Input';

import FONTS from 'styles/fonts';
import COLORS from 'styles/colors';
import { P2 } from 'styles/typography';
import BREAKPOINTS from 'styles/breakpoints';

const Container = styled.div`
  font-family: ${FONTS.poppins};

  ${BREAKPOINTS.medium`
         max-width: 20rem;
    `}
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: 1rem;
`;

export const StyledInput = styled(Input)`
  appearance: none;
  -moz-appearance: textfield;
  height: 100%;

  input {
    font-family: ${FONTS.poppins};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputLabel = styled.label`
  font-size: 0.625rem;
  color: ${COLORS.forestDark};
  margin-bottom: 0.5rem;
  display: block;
`;

const ErrorMessage = styled(P2)`
  margin: 0;
  font-size: 0.625rem;
  color: ${COLORS.orange};
  margin-top: 0.25rem;
`;

type MobileNumberProps = {
  label: string;
  errorMessage?: string;
  codeFieldSlot: React.ReactNode;
  numberFieldSlot: React.ReactNode;
};
const MobileNumber = ({
  label,
  codeFieldSlot,
  numberFieldSlot,
  errorMessage,
}: MobileNumberProps) => {
  return (
    <Container>
      <InputLabel>{label}</InputLabel>
      <InputContainer>
        {codeFieldSlot}
        {numberFieldSlot}
      </InputContainer>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};

export default MobileNumber;
