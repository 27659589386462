import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import Nudge from '../Nudge';
import AddOnCard from '../AddOnCard';
import RadioBubble from '../RadioBubble';

import BREAKPOINTS, { breakpointSizes } from 'styles/breakpoints';
import FONTS from 'styles/fonts';
import COLORS from 'styles/colors';
import { H6, weight } from 'styles/typography';
import { useBookingFlowManager } from 'src/context/bookingFlow';
import { KnownAddOnType } from 'src/context/bookingFlow/apiSchemas';

const Container = styled.div`
  padding: 0 1.25rem;
  ${BREAKPOINTS.medium`
          padding: 0 1.5rem;
    `}
`;

const StyledNudge = styled(Nudge)`
  margin-top: 1.5rem;
`;

const AddOnsContainer = styled.div`
  margin-top: 1rem;
  display: grid;
  gap: 1rem;
  ${BREAKPOINTS.medium`
          gap: 0.62rem;
          margin-top:  1.5rem;
    `}
`;

const AddOn = styled.div`
  background-color: ${COLORS.white};
  color: ${COLORS.darkGrey};
  padding: 1rem;
  border-radius: 0.5rem;
  display: grid;
  gap: 1rem;

  ${BREAKPOINTS.medium`
    gap: 0.87rem;
    `}
`;

const AddOnTitle = styled(H6)`
  font-family: ${FONTS.poppins};
  font-size: 1rem;
  margin: 0;
  font-weight: ${weight.semibold};
`;

const AddOnDescription = styled.p`
  font-family: ${FONTS.poppins};
  font-size: 0.8125rem;
  margin: 0;
`;

const StyledRadioBubble = styled(RadioBubble)`
  margin-bottom: 0;
`;

const AddonsStep = () => {
  const { send, availableAddOns, selectedAddOns } = useBookingFlowManager();

  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${breakpointSizes.medium}px)`,
  });

  const beforeOrAfterString = (addOnType: KnownAddOnType) => {
    if (addOnType === 'pre_game_outdoor_table') {
      return ' before peg booking';
    }
    if (addOnType === 'post_game_outdoor_table') {
      return ' after peg booking';
    }
    return '';
  };

  return (
    <Container>
      <StyledNudge
        text="Spend more time together at Clays."
        hasIcon={false}
        rounded
        hasBackground={isDesktopOrLaptop ? true : false}
      />
      <AddOnsContainer>
        {availableAddOns?.map((group) => (
          <AddOn key={group.key}>
            <AddOnTitle>{group.title}</AddOnTitle>
            <AddOnDescription>{group.description}</AddOnDescription>

            {group.addOns.map((addOn) => {
              const selected = !!selectedAddOns.find(
                (selectedAddOn) => selectedAddOn.addOnType === addOn.addOnType
              );

              return (
                <StyledRadioBubble
                  key={addOn.name}
                  onSelect={() => {
                    if (selected) {
                      send({
                        type: 'onDeSelectAddOn',
                        addOn,
                        addOnGroupKey: group.key,
                      });
                    } else {
                      send({
                        type: 'onSelectAddOn',
                        addOnGroupKey: group.key,
                        addOnGroupAllowMultiple: group.allowMultiple,
                        addOn,
                      });
                    }
                  }}
                  selected={selected}
                >
                  <AddOnCard
                    valueOne={`+${addOn.duration} mins${
                      addOn.addOnType
                        ? beforeOrAfterString(addOn.addOnType)
                        : ''
                    }`}
                    valueTwo={`+£${addOn.charge.toFixed(0)}pp`}
                  />
                </StyledRadioBubble>
              );
            })}
          </AddOn>
        ))}
      </AddOnsContainer>
    </Container>
  );
};

export default AddonsStep;
