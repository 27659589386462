import * as zod from 'zod';

declare const window: Window & {
  FreedomPay: {
    Apm: {
      ConsumerAuth: {
        invoke(input: { mandateChallenge: true }): void;
      };
    };
  };
};

const IFRAME_ORIGIN = process.env.NEXT_PUBLIC_FREEDOM_PAY_IFRAME_ORIGIN;

const authenticateResponseSchema = zod
  .object({
    Enrolled: zod.string().optional(),
    ErrorNo: zod.tuple([zod.number()]).rest(zod.unknown()).optional(),
    ErrorDesc: zod.string().optional(),
    TransactionId: zod.string().optional(),
  })
  .transform(({ Enrolled, ErrorNo, ErrorDesc, TransactionId }) => ({
    enrolled: Enrolled,
    errorNo: ErrorNo,
    errorDesc: ErrorDesc,
    transactionId: TransactionId,
  }));

const attributesSchema = zod
  .object({
    CardIssuer: zod.string(),
    ExpirationDate: zod.string(),
    MaskedCardNumber: zod.string(),
    AuthenticateResponse: zod
      .string()
      .optional()
      .transform((value) => {
        if (!value) return null;
        try {
          const rawParsed = JSON.parse(value);
          return authenticateResponseSchema.parse(rawParsed);
        } catch (error) {
          console.error(
            'Malformed FreedomPay AuthenticateResponse',
            value,
            error
          );
        }
        return null;
      }),
  })
  .transform(
    ({
      CardIssuer,
      ExpirationDate,
      MaskedCardNumber,
      AuthenticateResponse,
    }) => ({
      cardIssuer: CardIssuer,
      expirationDate: ExpirationDate,
      maskedCardNumber: MaskedCardNumber,
      authenticateResponse: AuthenticateResponse,
    })
  );

export type FreedomPayAttributes = zod.infer<typeof attributesSchema>;

const paymentKeysSchema = zod.array(zod.string());

export type FreedomPayPaymentKeys = zod.infer<typeof paymentKeysSchema>;

export const freedomPayErrorSchema = zod.object({
  emittedBy: zod.string(),
  errorType: zod.number(),
  message: zod.string(),
  messageCode: zod.string(),
  data: zod.unknown(),
});

export type FreedomPayError = zod.infer<typeof freedomPayErrorSchema>;

const eciFlagSchema = zod.union([
  zod.literal('00'),
  zod.literal('01'),
  zod.literal('02'),
  zod.literal('05'),
  zod.literal('06'),
  zod.literal('07'),
]);

const parResStatusSchema = zod.union([
  zod.literal('Y'),
  zod.literal('A'),
  zod.literal('N'),
  zod.literal('U'),
  zod.literal('R'),
  zod.literal(''),
]);

const freedomPayEvent = zod.union([
  zod.object({
    type: zod.literal('onSetIframeHeight'),
    height: zod.number(),
  }),
  zod.object({
    type: zod.literal('onError'),
    errors: zod.array(zod.unknown()).transform((value) => {
      return value
        .map((error) => {
          const parsed = freedomPayErrorSchema.safeParse(error);
          if (parsed.success) {
            return parsed.data;
          }

          console.error('Malformed FreedomPay error', error, parsed.error);

          return null;
        })
        .filter(Boolean) as zod.infer<typeof freedomPayErrorSchema>[];
    }),
  }),
  zod.object({
    type: zod.literal('onPaymentKeys'),
    paymentKeys: paymentKeysSchema,
    attributes: zod
      .array(
        zod.object({
          Key: zod.string(),
          Value: zod.string(),
        })
      )
      .transform((value) => {
        const reduced = Object.values(value).reduce(
          (acc, { Key, Value }) => ({ ...acc, [Key]: Value }),
          {} as Record<string, string>
        );

        const parsed = attributesSchema.safeParse(reduced);
        if (parsed.success) {
          return parsed.data;
        }

        console.error('Malformed FreedomPay attributes', value, parsed.error);

        return null;
      }),
  }),
  zod.object({
    type: zod.literal('onFieldValidation'),
    // data.emittedBy, data.isValid, data.message, data.type
  }),
  zod.object({
    type: zod.literal('onBinLookupComplete'),
    data: zod.object({
      bin: zod.string(),
    }),
  }),
  zod
    .object({
      type: zod.literal('3dsResponse'),
      CmpiLookup: zod
        .object({
          EciFlag: eciFlagSchema,
          PAResStatus: parResStatusSchema,
          Enrolled: zod.string(),
        })
        .transform(({ EciFlag, PAResStatus, Enrolled }) => ({
          eciFlag: EciFlag,
          authenticationStatus: PAResStatus,
          enrolled: Enrolled,
        }))
        .optional(),
      Payment: zod
        .object({
          ExtendedData: zod
            .object({
              ECIFlag: eciFlagSchema,
              PAResStatus: parResStatusSchema,
            })
            .transform(({ ECIFlag, PAResStatus }) => {
              return {
                eciFlag: ECIFlag,
                authenticationStatus: PAResStatus,
              };
            }),
        })
        .optional(),
    })
    .transform(({ Payment, CmpiLookup, ...props }) => ({
      ...props,
      ...Payment?.ExtendedData,
      ...CmpiLookup,
    })),
  zod.object({
    type: zod.literal('3dsResponseParsingError'),
  }),
]);

type FreedomPayEvent = zod.infer<typeof freedomPayEvent>;

type FreedomPayEventType = FreedomPayEvent['type'];

type Subscription = {
  unsubscribe: () => void;
};

type Handler = (event: FreedomPayEvent) => void;
export const subscribeToFreedomPayEvents = (handler: Handler): Subscription => {
  const listener = (event: MessageEvent) => {
    if (!event.isTrusted || event.origin !== IFRAME_ORIGIN) {
      return;
    }

    const message = event.data;
    const data = message.data;
    let eventType: FreedomPayEventType | undefined = undefined;

    switch (message.type) {
      case 1:
        eventType = 'onError';
        console.error('Error from FreedomPay', data.errors);
        break;

      case 2:
        eventType = 'onSetIframeHeight';
        break;

      case 3:
        eventType = 'onPaymentKeys';
        break;

      case 4:
        eventType = 'onFieldValidation';
        break;

      case 16:
        eventType = '3dsResponse';
        break;

      case 22:
        eventType = 'onBinLookupComplete';
        break;

      default:
        // console.warn(`Unknown FreedomPay event type: ${message.type}`);
        break;
    }

    if (!eventType) {
      return;
    }

    const parsedEvent = freedomPayEvent.safeParse({
      type: eventType,
      ...data,
    });

    if (!parsedEvent.success) {
      console.error(`Could not parse event "${eventType}"`, data);

      if (eventType === '3dsResponse') {
        handler({ type: '3dsResponseParsingError' });
      }

      return;
    }

    handler(parsedEvent.data);
  };

  window.addEventListener('message', listener);
  return {
    unsubscribe: () => {
      window.removeEventListener('message', listener);
    },
  };
};

export const triggerGetPaymentKeys = (iframeId: string) => {
  const iframe: HTMLIFrameElement | null = document.querySelector(
    `iframe#${iframeId}`
  );
  if (iframe && IFRAME_ORIGIN) {
    iframe.contentWindow?.postMessage('getPaymentKeys', IFRAME_ORIGIN);
  } else {
    if (!iframe) {
      console.error('Could not find iframe');
    }
    if (!IFRAME_ORIGIN) {
      console.error('Missing IFRAME_ORIGIN env variable');
    }
  }
};

export const invokeConsumerAuthChallenge = () => {
  window.FreedomPay.Apm.ConsumerAuth.invoke({ mandateChallenge: true });
};
