import React from 'react';
import { useBookingSearch } from 'context/BookingSearchProvider';
import styled from 'styled-components';
import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';
import { P2 } from 'styles/typography';
import { datalayerPush } from '../ThirdParty/GtmDatalayerPush';

const RadioButtonContainer = styled.label`
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

  background-color: ${({ selected }) =>
    selected ? COLORS.forest : COLORS.white};
  color: ${({ selected }) => (selected ? COLORS.white : COLORS.forest)};
  border: 1px solid
    ${({ selected }) => (selected ? 'transparent' : COLORS.blueGrey)};

  font-family: ${FONTS.poppins};
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 140%;

  &:hover {
    background-color: ${COLORS.forest};
    color: ${COLORS.white};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const HiddenRadioButton = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const ErrorText = styled(P2)`
  color: ${COLORS.error};
  margin: 0;
  text-align: center;
`;

export const OccasionContent = () => {
  const { searchData, updateSearchData, occasions } = useBookingSearch();

  const occasionsIncludingNone = occasions && [
    ...occasions,
    {
      id: null,
      display_name: 'No Occasion',
    },
  ];

  const handleSelectOccasion = (occasion) => {
    updateSearchData('Occasion', occasion);
    datalayerPush({
      event: 'occasionSelected',
      occasion: occasion.name,
    });
  };

  if (occasions === null) {
    return (
      <ErrorText weight="bold">
        There was an error getting occasions. Please refresh to try again.
      </ErrorText>
    );
  }

  return (
    <div>
      {occasionsIncludingNone?.map((occasion) => (
        <RadioButtonContainer
          key={occasion.id}
          selected={searchData.Occasion.id === occasion.id}
          onClick={() =>
            handleSelectOccasion({
              id: occasion.id,
              name: occasion.display_name,
            })
          }
        >
          <HiddenRadioButton
            name="occasion"
            checked={searchData.Occasion.id === occasion.id}
            onChange={() =>
              handleSelectOccasion({
                id: occasion.id,
                name: occasion.display_name,
              })
            }
          />
          {occasion.display_name}
        </RadioButtonContainer>
      ))}
    </div>
  );
};

export default OccasionContent;
