import React, { useState } from 'react';
import styled from 'styled-components';
import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';
import BREAKPOINTS from 'styles/breakpoints';
import { formatDateTimeString, formatWhoValue } from 'utils/bookingSearchUtils';
import { useBookingFlowManager } from 'context/bookingFlow';
import { weight } from 'styles/typography';

const Tab = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding: 0.62rem 1rem;
  background-color: ${COLORS.forestBlack};
  color: ${COLORS.white};
  font-size: 1rem;
  font-family: ${FONTS.poppins};
  font-weight: ${weight.semibold};
  border-radius: 0.5rem 0.5rem 0 0;
`;

const Summary = styled.ul`
  /* LAYOUT */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  padding: 0.5rem 1rem 1rem 1rem;
  margin: 0;

  /* STYLES */
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: ${COLORS.white};
  color: ${COLORS.darkGrey};
  font-size: 0.8125rem;
  font-weight: 400;
`;

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
`;

const PriceBreakdownDetails = styled.div`
  margin-left: 1rem;
  border-left: 2px solid ${COLORS.blueGrey};

  ${Item} {
    margin-left: 1rem;
  }
`;

const Price = styled.ul`
  margin-top: 1rem;
  list-style-type: none;
  padding: 0;
`;

const Total = styled.li`
  font-size: 1rem;
  font-weight: 600;
`;

const PerPerson = styled.li`
  font-size: 0.8125rem;
`;

const SummaryTabInline = () => {
  const {
    searchData,
    selectedTimeSlot,
    totalGameDuration,
    totalBookingCost,
    selectedPackage,
    selectedAddOns,
    booking,
  } = useBookingFlowManager();

  const depositAmount = React.useMemo(() => booking?.depositAmount, [booking]);

  const shouldShowDeposit = React.useMemo(() => {
    if (depositAmount === totalBookingCost.total) {
      return false;
    }
    return true;
  }, [depositAmount, totalBookingCost]);

  return (
    <Tab>
      <Header>
        <span>Your booking</span>
      </Header>
      <Summary>
        <Item>
          <span>Where</span>
          <span>{booking.venueName}</span>
        </Item>
        <Item>
          <span>When</span>
          <span>
            {selectedTimeSlot.timeIso
              ? formatDateTimeString(selectedTimeSlot.timeIso)
              : ''}
          </span>
        </Item>
        <Item>
          <span>Who</span>
          <span>{formatWhoValue(searchData.Who)}</span>
        </Item>
        <Item>
          <span>Occasion</span>
          <span>{searchData.Occasion.name}</span>
        </Item>
        <Item>
          <span>Playing time</span>
          <span>{totalGameDuration} minutes</span>
        </Item>
        <Item>
          <span>Game booking fee</span>
          <span>£{totalBookingCost.total.toFixed(2)}</span>
        </Item>
        <Item>
          <span>Packages & upgrades</span>
          <span>
            £
            {(
              (booking?.packagesCost ?? 0) + (booking?.addOnsCost ?? 0)
            ).toFixed(2)}
          </span>
        </Item>
        <PriceBreakdownDetails>
          {selectedPackage && (
            <Item>
              <span>{selectedPackage?.name}</span>
              <span>£{selectedPackage?.charge.toFixed(2)}pp</span>
            </Item>
          )}
          {selectedAddOns?.map((addOn) => (
            <Item key={addOn.addOnType}>
              <span>{addOn.name}</span>
              <span>£{addOn.charge.toFixed(2)}pp</span>
            </Item>
          ))}
        </PriceBreakdownDetails>
        {shouldShowDeposit ? (
          <Price>
            <Total>Deposit required: £{depositAmount.toFixed(2)}</Total>
            <PerPerson>
              Due on arrival: £
              {((totalBookingCost.total ?? 0) - (depositAmount ?? 0)).toFixed(
                2
              )}
            </PerPerson>
          </Price>
        ) : (
          <Price>
            <Total>Total: £{totalBookingCost.total.toFixed(2)}</Total>
            <PerPerson>
              Per person: £{totalBookingCost.perPerson.toFixed(2)}
            </PerPerson>
          </Price>
        )}
      </Summary>
    </Tab>
  );
};

export default SummaryTabInline;
