import React from 'react';
import { useBookingSearch } from 'context/BookingSearchProvider';
import styled from 'styled-components';
import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';
import { datalayerPush } from '../ThirdParty/GtmDatalayerPush';

const Subheading = styled.span`
  color: ${COLORS.darkGrey};
`;

const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
  justify-content: space-between;
`;

const Button = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  border: none;
  background-color: ${COLORS.blueGrey};
  color: ${COLORS.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
    background-image: none;
  }

  &.increment {
    background-image: url('/images/icons/plus.svg');
  }

  &.decrement {
    background-image: url('/images/icons/minus.svg');
  }
`;

const CountInput = styled.input`
  width: auto;
  height: 3rem;
  padding: 0.75rem 1.5rem;
  text-align: center;
  border-radius: 12rem;
  border: 1px solid #e4e4e4;
  color: ${COLORS.darkGrey};
  font-family: ${FONTS.poppins};
  font-size: 1rem;
  line-height: 100%;

  // --- REMOVE DEFAULT INCREMENT/DECREMENT BUTTONS ---

  -moz-appearance: textfield; /* Firefox */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* For IE11 and Microsoft Edge */
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
`;


const AgeDisclaimer = styled.span`
  display: block;
  font-size: 0.8em;
  color: ${COLORS.darkGrey};
  margin-top: 0.5rem;
  font-weight: bold;
`;

export const WhoContent = () => {
  const { searchData, updateSearchData } = useBookingSearch();

  const handleIncrement = () => {
    if (searchData.Who < 500) {
      let newCount = searchData.Who + 1;
      updateSearchData('Who', newCount);
      datalayerPush({
        event: 'initialWhoIncremented',
        who: newCount,
      });
    }
  };

  const handleDecrement = () => {
    if (searchData.Who > 0) {
      let newCount = searchData.Who - 1;
      updateSearchData('Who', newCount);
      datalayerPush({
        event: 'initialWhoDecremented',
        who: newCount,
      });
    }
  };

  const handleChange = (event) => {
    const value = +event.target.value.replace(/\D/g, '');
    const newCount = isNaN(value) ? 0 : Math.max(0, Math.min(500, value));
    updateSearchData('Who', newCount);
    datalayerPush({
      event: 'initialWhoEntered',
      who: newCount,
    });
  };

  const handleBlur = () => {
    if (searchData.Who === 0 || searchData.Who === '') {
      updateSearchData('Who', 0);
    }
  };

  return (
    <>
      <Subheading>We cater for groups of 1 to 500</Subheading>
      <AgeDisclaimer>*Please note Clays is for big children, over 18s only.</AgeDisclaimer>
      
      <CounterContainer>
        <Button
          className="decrement"
          onClick={handleDecrement}
          disabled={searchData.Who <= 1}
          aria-label="Decrease number of people"
        />
        <CountInput
          type="number"
          value={searchData.Who === 0 ? '' : searchData.Who}
          onChange={handleChange}
          onBlur={handleBlur}
          min="1"
          max="500"
        />
        <Button
          className="increment"
          onClick={handleIncrement}
          disabled={searchData.Who >= 500}
          aria-label="Increase number of people"
        />
      </CounterContainer>
    </>
  );
};

export default WhoContent;
