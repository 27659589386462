import styled from 'styled-components';
import COLORS from 'styles/colors';

const themeColors = {
  dawn: COLORS.dawn,
  blue: COLORS.blueGrey,
};

const StyledBookingFlowButton = styled.button`
  display: flex;
  padding: 0.5rem 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
  background: ${({ theme }) => themeColors[theme]};
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;

  &:focus-visible {
    outline: 1px solid -webkit-focus-ring-color;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const themes = ['dawn', 'blue'];

export const BookingFlowButton = ({
  theme = 'dawn',
  children,
  onClick,
  ...props
}) => {
  const selectedTheme = themes.includes(theme.toLowerCase())
    ? theme.toLowerCase()
    : 'dawn';
  return (
    <StyledBookingFlowButton onClick={onClick} {...props} theme={selectedTheme}>
      {children}
    </StyledBookingFlowButton>
  );
};

export default BookingFlowButton;
