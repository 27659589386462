import type {
  FetchAvailabilitySingleDayAvailability,
  SingleTimeSlot,
  CreateBookingData,
  InlineBookingFlowType,
  AvailablePackages,
  AvailablePackage,
  AvailableAddOnsGroup,
  AvailableAddOn,
  AllergenReservationTags,
  ReservationTag,
  DietaryReservationTags,
  BookingPayment,
} from './apiSchemas';
import type { CmsContent } from './cmsSchemas';
import type {
  FreedomPayAttributes,
  FreedomPayError,
  FreedomPayPaymentKeys,
} from './freedomPay';

export type SingleDayAvailability =
  FetchAvailabilitySingleDayAvailability['data'] & {
    requestedDay: string;
  };

export type ThreeDayAvailability = {
  currentDay: SingleDayAvailability;
  currentDayPlusOne: SingleDayAvailability;
  currentDayPlusTwo: SingleDayAvailability;
};

export type CreateBookingResponse = {};

export type SearchData = {
  Where: { id: string; label: string };
  When: {
    date: string;
    time: string;
  };
  Who: number;
  Occasion: {
    id: string;
    name: string;
  };
};

export type BookingCostInfo = {
  perPerson: number;
  total: number;
};

export type BookingFlowUserInfo = {
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
  mobileNumber: {
    code: string;
    number: string;
  };
  newsletter: boolean;
};

export type BookingFlowAdditionalNotes = {
  notes: string;
};

export type BookingFlowBillingInfo = {
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postcode: string;
  country: string;
  termsAndConditions: boolean;
  cancellationPolicy: boolean;
  privacyPolicy: boolean;
  ageConfirmation: boolean;
};

export type BookingFlowMachineEvent =
  | { type: 'onResetFlow' }
  | {
      type: 'onAvailabilityFetched';
      threeDayAvailability: ThreeDayAvailability;
    }
  | {
      type: 'onAvailabilityFetchError';
    }
  | {
      type: 'onCorporateRedirect';
    }
  | {
      type: 'onNudgeAvailability';
      direction: 'backwards' | 'forwards';
    }
  | {
      type: 'onInitialSearchData';
      searchData: SearchData;
    }
  | {
      type: 'onTimeSlotSelected';
      timeSlot: SingleTimeSlot;
      bookingFlowType: InlineBookingFlowType;
    }
  | { type: 'onNextStep' }
  | { type: 'onPrevStep' }
  | {
      type: 'onCreateBookingSuccess';
      booking: CreateBookingData;
      availablePackages: AvailablePackages;
      cmsContent: CmsContent;
      allergenReservationTags: AllergenReservationTags;
      dietaryReservationTags: DietaryReservationTags;
    }
  | {
      type: 'onCreateBookingError';
    }
  | {
      type: 'onSelectPackage';
      package: AvailablePackage | null;
    }
  | {
      type: 'onUpdatePackageSuccess';
      booking: CreateBookingData;
      availableAddOns: AvailableAddOnsGroup[];
    }
  | {
      type: 'onUpdatePackageError';
    }
  | {
      type: 'onSelectAddOn';
      addOn: AvailableAddOn;
      addOnGroupKey: AvailableAddOnsGroup['key'];
      addOnGroupAllowMultiple: AvailableAddOnsGroup['allowMultiple'];
    }
  | {
      type: 'onDeSelectAddOn';
      addOn: AvailableAddOn;
      addOnGroupKey: AvailableAddOnsGroup['key'];
    }
  | {
      type: 'onUpdateAddOnsSuccess';
      booking: CreateBookingData;
    }
  | {
      type: 'onUpdateAddOnsError';
    }
  | {
      type: 'onUserInfoUpdated';
      userInfo: BookingFlowUserInfo;
    }
  | {
      type: 'onBackToPackageStep';
    }
  | {
      type: 'onBackToUserInfoStep';
    }
  | {
      type: 'onSelectReservationTag';
      reservationTag: ReservationTag;
    }
  | {
      type: 'onDeSelectReservationTag';
      reservationTag: ReservationTag;
    }
  | {
      type: 'onAdditionalNotesUpdated';
      additionalNotes: BookingFlowAdditionalNotes;
    }
  | {
      type: 'onApplyPromoCode';
      promoCode: string;
    }
  | {
      type: 'onApplyPromoCodeSuccess';
      booking: CreateBookingData;
    }
  | {
      type: 'onApplyPromoCodeError';
    }
  | {
      type: 'onBillingInfoUpdated';
      billingInfo: BookingFlowBillingInfo;
    }
  | {
      type: 'onPaymentInitialised';
      booking: CreateBookingData;
      bookingPayment: BookingPayment;
    }
  | {
      type: 'onPaymentInitialisationError';
    }
  | {
      type: 'onFreedomPayReady';
    }
  | {
      type: 'onFreedomPayPaymentKeys';
      paymentKeys: FreedomPayPaymentKeys;
      paymentAttributes: FreedomPayAttributes | null;
    }
  | {
      type: 'onFreedomPayError';
      errors: FreedomPayError[] | 'rejected';
    }
  | {
      type: 'onPaymentCompleted';
      booking: CreateBookingData;
    }
  | {
      type: 'onPaymentError';
      softDecline: boolean;
    };

export type BookingFlowMachineEventType = BookingFlowMachineEvent['type'];

export type BookingFlowApiEventGroup = {
  success: BookingFlowMachineEventType[];
  error: BookingFlowMachineEventType[];
};

export const apiEvents: BookingFlowApiEventGroup[] = [
  {
    success: ['onAvailabilityFetched', 'onCorporateRedirect'],
    error: ['onAvailabilityFetchError'],
  },
  {
    success: ['onCreateBookingSuccess'],
    error: ['onCreateBookingError'],
  },
  {
    success: ['onUpdatePackageSuccess'],
    error: ['onUpdatePackageError'],
  },
  {
    success: ['onUpdateAddOnsSuccess'],
    error: ['onUpdateAddOnsError'],
  },
  {
    success: ['onPaymentInitialised'],
    error: ['onPaymentInitialisationError'],
  },
  {
    success: ['onFreedomPayPaymentKeys'],
    error: ['onFreedomPayError'],
  },
  {
    success: ['onPaymentCompleted'],
    error: ['onPaymentError'],
  },
];

export const API_SUCCESS_EVENTS = apiEvents.reduce(
  (acc, curr) => [...acc, ...curr.success],
  [] as BookingFlowMachineEventType[]
);

export const API_ERROR_EVENTS = apiEvents.reduce(
  (acc, curr) => [...acc, ...curr.error],
  [] as BookingFlowMachineEventType[]
);
