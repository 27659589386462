import React from 'react';
import styled from 'styled-components';
import FONTS from 'styles/fonts';
import COLORS from 'styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.forest};
`;

const SearchingText = styled.span`
  font-family: ${FONTS.wulkan};
  font-size: 2.34375rem;
  color: ${COLORS.white};
`;

const LoadingStep = ({ text = 'Searching...' }) => {
  return (
    <Container>
      <SearchingText>{text}</SearchingText>
    </Container>
  );
};

export default LoadingStep;
