import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

import COLORS from 'styles/colors';
import TYPO, { weight } from 'styles/typography';

export const InputStyles = css<{
  $hasValue?: boolean;
  $hasError?: boolean;
  disabled?: boolean;
}>`
  ${TYPO.p3}
  padding: 0 15px;
  width: 100%;
  height: 43px;
  border-radius: 10px;
  border: 1px solid
    ${({ $hasValue }) =>
      $hasValue ? COLORS.forest : COLORS.forest.opacity(0.5)};
  background-color: ${COLORS.pinkLight};
  color: ${COLORS.forestBlack};
  font-weight: ${weight.medium};
  outline: none;
  transition: 0.2s;
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};

  &:focus {
    border-color: ${({ $hasError }) =>
      $hasError ? COLORS.error : COLORS.forest};
  }

  &::placeholder {
    color: ${COLORS.forestBlack.opacity(0.5)};
  }

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: ${COLORS.error};
    `}
`;

export const Label = styled.label<{
  $theme?: 'light' | 'dark';
}>`
  ${TYPO.p3}
  margin-bottom: 6px;
  display: block;
  color: ${({ $theme }) =>
    $theme === 'light' ? COLORS.pinkMuted : COLORS.forest};
`;

const InputField = styled.input`
  ${InputStyles}
  position: relative;

  &[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    height: auto;
    background: transparent;
    color: transparent;
    cursor: pointer;
  }
`;

const Textarea = styled(TextareaAutosize)`
  ${InputStyles}
  padding: 8px 15px;
  min-height: 86px;
  vertical-align: top;
  resize: none;
`;

export const HelpText = styled.span<{
  $hasError?: boolean;
  $theme?: 'light' | 'dark';
}>`
  ${TYPO.p2}
  margin: 5px 0 10px;
  display: block;
  min-height: 4px;
  color: ${({ $theme }) =>
    $theme === 'light' ? COLORS.pinkMuted : COLORS.forest};

  ${({ $hasError }) =>
    $hasError &&
    css`
      color: ${COLORS.error};
    `}
`;

type InputProps = {
  label: string;
  name: string;
  value: any;
  type?: string;
  onChange?: any;
  helpText?: string;
  errorMessage?: string;
  isDisabled?: boolean;
  multiline?: boolean;
  theme?: 'light' | 'dark';
};

const Input = forwardRef<any, InputProps>(
  (
    {
      label,
      name,
      value,
      onChange,
      type = 'text',
      helpText,
      errorMessage,
      isDisabled,
      multiline,
      theme,
      ...props
    },
    ref
  ) => {
    return (
      <div>
        <Label htmlFor={name} $theme={theme}>
          {label}
        </Label>
        {!multiline ? (
          <InputField
            id={name}
            name={name}
            ref={ref}
            type={type}
            onChange={onChange}
            disabled={isDisabled}
            $hasValue={Boolean(value)}
            $hasError={Boolean(errorMessage)}
            {...props}
          />
        ) : (
          <Textarea
            id={name}
            name={name}
            ref={ref}
            onChange={onChange}
            disabled={isDisabled}
            minRows={1}
            maxRows={10}
            $hasValue={Boolean(value)}
            $hasError={Boolean(errorMessage)}
            {...props}
          />
        )}
        <HelpText $hasError={Boolean(errorMessage)} $theme={theme}>
          {errorMessage || helpText}
        </HelpText>
      </div>
    );
  }
);

export default Input;
