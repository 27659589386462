import colorString from 'color-string';
import { lerp } from '../utils/math';

// Easy to use helper for colors
export const color = (str: any) => {
  /* eslint-disable no-new-wrappers */
  const instance: any = new String(str);

  // add opacity helper to color string
  instance.opacity = (opacity: any) => {
    return color(
      colorString.to.rgb([
        ...(colorString.get.rgb(str) as any).slice(0, 3),
        opacity,
      ])
    );
  };
  // mixes two colors together
  instance.mix = (color2: any, a: any) => {
    const c1: any = colorString.get.rgb(str);
    const c2: any = colorString.get.rgb(color2);
    const mix = [
      lerp(c1[0], c2[0], a),
      lerp(c1[1], c2[1], a),
      lerp(c1[2], c2[2], a),
      lerp(c1[3], c2[3], a),
    ];

    // return as RGB color again
    return color(colorString.to.rgb(mix));
  };
  // get rgb values
  instance.rgb = () => {
    return colorString.get.rgb(str);
  };

  // since we're using a String() instance we can't compare like we normally do...
  instance.equals = (color2: any) => {
    if (!color2 || color2 === 'none') {
      return false;
    }

    const c1: any = colorString.get.rgb(str);
    const c2: any = colorString.get.rgb(color2);
    return c1[0] === c2[0] && c1[1] === c2[1] && c1[2] === c2[2];
  };

  instance.luma = () => {
    const rgb = instance.rgb();
    const r = rgb[0];
    const g = rgb[1];
    const b = rgb[2];
    return Math.sqrt(0.241 * r + 0.691 * g + 0.068 * b);
  };

  return instance;
};

export const colors = {
  white: color('#FFFFFF'),
  black: color('#000000'),
  darkGrey: color('#2d2d2d'),
  grey: color('#DDD7D7'),

  forestBlack: color('#0D1F21'),
  forestDark: color('#002324'),
  forest: color('#004043'),

  blueGreen: color('#007C73'),
  lightblueGreen: color('#33968F'),
  emerald: color('#0D917D'),
  blueGrey: color('#7A9EA5'),
  tealMuted: color('#C3E1D6'),
  tealNeon: color('#B2FCE5'),
  sky: color('#ECF5F1'),

  shootoutBlue: color('#C8F5E6'),
  shootoutLightBlue: color('#E0F5ED'),
  shootoutOffWhite: color('#E9FCF6'),
  shootoutGold: color('#D9BC5D'),
  shootoutSilver: color('#C8C8C8'),

  redWine: color('#630F32'),
  velvety: color('#8B3E50'),
  orange: color('#FE5C44'),
  sunset: color('#FE6B64'),
  dawn: color('#FF8188'),
  dawnDark: color('#FF6F77'),
  pink: color('#FFCEC0'),
  pinkMillennial: color('#FFEAE7'),
  pinkMuted: color('#F3E6E0'),
  pinkLight: color('#F5EFEC'),
  pinkGrey: color('#E4E1E1'),

  purpleDark: color('#43266F'),
  purpleHot: color('#6200D1'),
  purple: color('#9C90FE'),
  lilac: color('#E4D2FC'),

  gold: color('#FBB100'),
  silver: color('#8CA1A3'),
  bronze: color('#D69450'),

  packageGold: color('#E9DEB0'),
  packageSilver: color('#DCDCDC'),
  packageBronze: color('#C7B0A5'),

  error: color('#EB5757'),
  success: color('#44D520'),
} as const;

export default colors;
