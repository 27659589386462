import styled, { css } from 'styled-components';

import COLORS from 'styles/colors';

const RadioButtonContainer = styled.button`
  // --- LAYOUT ---
  display: block;
  width: 100%;
  position: relative;
  border: none;
  outline: none;
  padding: 0;
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  &:focus {
    outline: 1px solid ${COLORS.forest};
  }

  &:last-child {
    margin-bottom: 0;
  }

  // --- STYLES ---
  background-color: ${COLORS.white};
  color: ${COLORS.forestBlack};
  cursor: pointer;

  // --- SELECTED STYLES ---
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${COLORS.forest};
      color: ${COLORS.white};

      & > div {
        color: ${COLORS.white};
        background-color: ${COLORS.forest};
      }
    `}

  // --- DISABLED STYLES ---
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const DisabledOverlay = styled.div`
  // --- DISABLED STYLES ---
  position: absolute;
  inset: 0;
  display: none;
  border-radius: 0.5rem;

  ${({ disabled }) =>
    disabled &&
    css`
      z-index: 1000;
      display: block;
      background-color: #f7f7f7;
      opacity: 0.5;
      color: rgba(13, 31, 33, 0.5);
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const RadioBubble = ({
  selected = false,
  disabled = false,
  onSelect,
  children,
  className = '',
}) => {
  return (
    <RadioButtonContainer
      selected={selected && !disabled}
      disabled={disabled}
      onClick={onSelect}
      className={className}
    >
      <DisabledOverlay disabled={disabled} />
      {children}
    </RadioButtonContainer>
  );
};

export default RadioBubble;
