import * as React from 'react';

import styled from 'styled-components';
import DatePicker, { DatePickerProps } from 'react-date-picker';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import FONTS from 'styles/fonts';
import COLORS from 'styles/colors';
import { P2 } from 'styles/typography';
import BREAKPOINTS from 'styles/breakpoints';
import { UseFormTrigger } from 'react-hook-form';

const InputWrapper = styled.div`
  font-family: ${FONTS.poppins};
  display: grid;
`;

export const InputLabel = styled.label`
  font-size: 0.625rem;
  color: ${COLORS.forestDark};
  margin-bottom: 0.5rem;
`;

const InputField = styled.input`
  border: 1px solid ${COLORS.blueGrey};
  border-radius: 0.5rem;
  padding: 0.688rem 1rem;
  font-family: ${FONTS.poppins};
  font-size: 0.813rem;
  width: 100%;

  :focus-visible {
    outline: 1px solid ${COLORS.blueGreen};
  }

  ::placeholder {
    text-transform: capitalize;
    color: ${COLORS.forestDark.opacity(0.5)};
  }
`;

const DateInputField = styled(DatePicker)`
  width: 100%;

  .react-date-picker__wrapper {
    width: 100%;
    border: 1px solid ${COLORS.blueGrey};
    border-radius: 0.5rem;
    padding: 0.35rem 2rem 0.35rem 1rem;
  }

  .react-date-picker__inputGroup {
    padding: 0;
  }

  .react-date-picker__inputGroup__input:valid,
  .react-date-picker__inputGroup__input:not(:placeholder-shown)
    ~ .react-date-picker__inputGroup__divider {
    color: ${COLORS.forestBlack};
  }

  .react-date-picker__inputGroup__input,
  .react-date-picker__inputGroup__divider {
    font-size: 0.813rem;
    color: ${COLORS.forestDark.opacity(0.5)};
  }

  .react-date-picker__inputGroup__input:invalid {
    background: none;
  }

  .react-date-picker__button {
    display: none;
  }

  ${BREAKPOINTS.medium`
    .react-date-picker__wrapper {
        padding: 0.35rem 1.79rem 0.35rem 0.89rem;
    }
  `}
`;

const ErrorMessage = styled(P2)`
  margin: 0;
  font-size: 0.625rem;
  color: ${COLORS.orange};
  margin-top: 0.25rem;
`;

type InputFieldProps = {
  type?: 'text' | 'email' | 'number' | 'password' | 'tel';
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
};
type DatePickerFieldProps = {
  type: 'date';
  onChange?: DatePickerProps['onChange'];
  value?: DatePickerProps['value'];
};

export type InputProps = {
  className?: string;
  name: string;
  label?: string;
  errorMessage?: string;
  trigger?: UseFormTrigger<any>;
  placeholderText?: string;
  required?: boolean;
  onBlur?: () => void;
} & (InputFieldProps | DatePickerFieldProps);

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      name,
      errorMessage,
      label,
      trigger,
      placeholderText,
      required = true,
      ...props
    },
    ref
  ) => {
    return (
      <InputWrapper className={className}>
        {label && <InputLabel htmlFor={`input-${name}`}>{label}</InputLabel>}{' '}
        {props.type === 'date' ? (
          <DateInputField
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            yearPlaceholder="YYYY"
            disableCalendar={true}
            {...props}
          />
        ) : (
          <InputField
            ref={ref}
            type={props.type || 'text'}
            name={name}
            id={`input-${name}`}
            placeholder={placeholderText ? placeholderText : ''}
            {...props}
          />
        )}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </InputWrapper>
    );
  }
);

export default Input;
