import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Controller } from 'react-hook-form';

import Input from '../Input';
import Nudge from '../Nudge';
import MobileNumber, { StyledInput } from '../MobileNumber';
import Checkbox from '../Checkbox';

import { countryStringList } from 'utils/countriesWithDialCodes';
import { isValidEmail } from 'utils/form-validation-schema';

import COLORS from 'styles/colors';
import BREAKPOINTS, { breakpointSizes } from 'styles/breakpoints';
import { useBookingFlowManager } from 'src/context/bookingFlow';
import PhoneCode from '../PhoneCode';

const Container = styled.div`
  padding: 1rem;
  ${BREAKPOINTS.medium`
          padding: 1.5rem;
    `}
`;

const StyledNudge = styled(Nudge)``;

const FormContainer = styled.div`
  background-color: ${COLORS.white};
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  display: grid;
  gap: 1rem;

  ${BREAKPOINTS.medium`
        margin-top: 1.5rem;
    `}
`;

const NameInputsContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;

  div,
  input {
    width: 100%;
  }
`;

const MobileAndDOBContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${BREAKPOINTS.medium`
    flex-direction: row; 
      align-items: flex-start;
    `}
`;

const UserInfoStep = () => {
  const { userInfoForm } = useBookingFlowManager();
  const { control, trigger, formState } = userInfoForm;

  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${breakpointSizes.medium}px)`,
  });

  return (
    <Container>
      <StyledNudge
        text="Please enter your personal information."
        hasIcon={false}
        rounded
        hasBackground={isDesktopOrLaptop ? true : false}
      />
      <FormContainer>
        {/* Names */}
        <NameInputsContainer>
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: { value: true, message: 'First name is required' },
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                label="First name"
                {...field}
                trigger={trigger}
                errorMessage={error?.message}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: { value: true, message: 'Last name is required' },
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                label="Last name"
                {...field}
                trigger={trigger}
                errorMessage={error?.message}
              />
            )}
          />
        </NameInputsContainer>

        {/* Email */}
        <Controller
          name="email"
          control={control}
          rules={{
            required: { value: true, message: 'Email is required' },
            validate: {
              matchPattern: (v) =>
                isValidEmail(v) || 'Email must be a valid email address',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <Input
              type="email"
              label="Email address"
              {...field}
              trigger={trigger}
              errorMessage={error?.message}
            />
          )}
        />

        {/* Mobile and DOB */}
        <MobileAndDOBContainer>
          <MobileNumber
            label="Mobile number"
            errorMessage={
              formState.errors.mobileNumber?.code?.message ||
              formState.errors.mobileNumber?.number?.message
            }
            codeFieldSlot={
              <Controller
                name="mobileNumber.code"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Please select a country code',
                  },
                }}
                render={({ field }) => (
                  <PhoneCode options={countryStringList} {...field} />
                )}
              />
            }
            numberFieldSlot={
              <Controller
                name="mobileNumber.number"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter a valid mobile number',
                  },
                  validate: (value) => {
                    const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

                    if (!value || !re.test(value)) {
                      return 'Please enter a valid mobile number';
                    }

                    return true;
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <StyledInput type="tel" trigger={trigger} {...field} />
                )}
              />
            }
          />

          <Controller
            name="dob"
            control={control}
            rules={{
              required: { value: true, message: 'Date of birth is required' },
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                type="date"
                label="Date of birth"
                {...field}
                trigger={trigger}
                errorMessage={error?.message}
              />
            )}
          />
        </MobileAndDOBContainer>

        {/* Newsletter Consent */}
        <Controller
          name="newsletter"
          control={control}
          render={({ field }) => (
            <Checkbox
              label="Opt in to receive the latest news from Clays, exclusive offers and discounts tailored to you, a special birthday treat and so much more!"
              {...field}
            />
          )}
        />
      </FormContainer>
    </Container>
  );
};

export default UserInfoStep;
