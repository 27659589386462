// hooks/useContext.ts

import { useState } from 'react';

const API_ENDPOINT = '/api/analytics/context';

export const useContextApi = () => {
  const [error, setError] = useState<string | null>(null);

  // Function to fetch the current context ID
  const fetchContextId = async () => {
    try {
      const response = await fetch(API_ENDPOINT);
      const data = await response.json();
      if (!response.ok) {
        setError(data.error);
      }
    } catch (err) {
      setError('Failed to fetch context ID');
    }
  };

  // Function to create a new context
  const createContext = async (payload: any) => {
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (!response.ok) {
        setError(data.error);
      }
    } catch (err) {
      setError('Failed to create context');
    }
  };

  // Function to delete the context ID
  const deleteContext = async () => {
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'DELETE',
      });
      const data = await response.json();
      if (!response.ok) {
        setError(data.error);
      }
    } catch (err) {
      setError('Failed to delete context');
    }
  };

  return { error, fetchContextId, createContext, deleteContext };
};
