import { toISODateString } from './bookingSearchUtils';
import { format } from 'date-fns';

export const calculateFlowProgress = (currentStep, steps) => {
  const stepsArray = Object.values(steps);
  const currentStepIndex = stepsArray.indexOf(currentStep);
  return (currentStepIndex / (stepsArray.length - 1)) * 100;
};

export const formatDateDisplay = (dateString) => {
  const options = { weekday: 'short', day: 'numeric', month: 'short' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};

// --- AVAILABILITY ---

export const calculateAdjacentDates = (baseDate) => {
  const base = new Date(baseDate);
  const prevDate = toISODateString(new Date(base.setDate(base.getDate() - 1)));
  const nextDate = toISODateString(new Date(base.setDate(base.getDate() + 2)));

  return { prevDate, nextDate };
};

const getOffsetDate = (baseDate, offset) => {
  const base = new Date(baseDate);
  return toISODateString(new Date(base.setDate(base.getDate() + offset)));
};

export const calculateNextThreeDays = (baseDate) => {
  return {
    datePlusOne: getOffsetDate(baseDate, 1),
    datePlusTwo: getOffsetDate(baseDate, 2),
    datePlusThree: getOffsetDate(baseDate, 3),
  };
};

export const splitDateTimeString = (dateTimeString) => {
  const [date, time] = dateTimeString.split(' ');
  return { date, time };
};

export const formatDateToDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  return format(date, 'dd/MM/yyyy');
};
