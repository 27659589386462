import styled, { createGlobalStyle, css } from 'styled-components';

import BREAKPOINTS from './breakpoints';
import COLORS from './colors';
import FONTS, { base } from './fonts';
import SlickSlider from './third-party/slick-slider';
import TYPO, { weight } from './typography';
import { fadeIn, rotate } from 'styles/animations';

const sqrLength = '634';

export default createGlobalStyle`
  :root {
    --gutter: 20px;
    --gutter-side: 18px;
    --font-feature-settings-normal: 'pnum' on, 'lnum' on, 'ss03' on;

    --landing-has-banner: translate3d(0, 0, 0);

    ${BREAKPOINTS.custom(414)`
      --gutter-side: 27px;
    `};
  }

  html,
  body {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: ${base}px;
    font-family: ${FONTS.poppins};
    font-feature-settings: 'ss01' on, 'ss02' on, 'ss03' on, 'ss04' on;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: inherit;
    text-decoration: underline;
    font-weight: ${weight.semibold};
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    font-family: ${FONTS.wulkan};
    font-feature-settings: var(--font-feature-settings-normal);
  }

  p {
    ${TYPO.p1}
    opacity: 0.8;
  }

  strong {
    font-weight: ${weight.black};
  }

  button {
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  input,
  input[type=text],
  input[type=password],
  textarea {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
  }

  ${SlickSlider}
    // Cookie Banner Start
  .cky-overlay {
    animation: fadeIn 2s ease-in forwards;
    animation-delay: 1s;
    opacity: 0;
  }

  @keyframes fadeIn {
    to {
      opacity: 0.6;
    }
  }

  .cky-btn-revisit-wrapper {
    display: none !important;
  }

  .cky-always-active {
    color: ${COLORS.forest} !important;
  }

  .cky-consent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 99999999 !important;
    opacity: ${({ hide }) => (hide ? 0 : 1)} !important;
    transition: 0.4s !important;
    pointer-events: none !important;
  }

  .cky-consent-bar {
    border: 0px !important;
    font-size: 12px !important;
    box-shadow: none !important;
    padding: 0px !important;
    position: relative !important;
    margin: 0 !important;
    width: ${sqrLength}px !important;
    height: ${sqrLength}px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 100% !important;
    background-color: ${COLORS.pinkMillennial} !important;
    color: ${COLORS.forest};
    pointer-events: initial;
    animation: ${fadeIn} 1s;
  }

  .cky-consent-bar::before {
    content: '';
    position: absolute !important;
    animation: ${rotate} 45s linear infinite;
    background: url("/images/roundels/cookies-roundel.svg") no-repeat center !important;
    background-size: ${sqrLength - 64}px ${sqrLength - 64}px !important;
    height: ${sqrLength}px;
    width: ${sqrLength}px;
  }

  .cky-title {
    display: none !important;
  }

  .cky-notice {
    height: ${sqrLength}px !important;
    width: ${sqrLength}px !important;
    padding: 155px;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
  }

  .cky-notice-group {
    z-index: 26 !important;
    position: relative;
    top: 12px;
  }

  .cky-notice-des {
    color: var(--cookie-banner-text) !important;
    margin: 0 !important;
    width: 380px !important;
  }

  .cky-notice-des a {
    font-size: 12px !important;
    line-height: 1.125rem !important;
    color: ${COLORS.forest};
  }

  .cky-notice-des p {
    font-size: 15px !important;
    line-height: 1.125rem !important;
    color: ${COLORS.forest};
  }

  .cky-btn {
    max-height: 40px !important;
    min-height: 40px !important;
    height: 40px !important;
    overflow: hidden !important;
    margin: 0 !important;
    min-width: fit-content !important;
    max-width: 100px !important;
    border: none !important;
    font-size: 12px !important;
    border-radius: 100px 100px !important;
    padding: 0px 14px !important;
  }

  .cky-btn-accept {
    color: ${COLORS.white} !important;
    background-color: ${COLORS.dawn} !important;
  }

  .cky-btn-customize {
    color: ${COLORS.dawn} !important;
    background-color: ${COLORS.white} !important;
  }

  .cky-btn-reject {
    display: none;
  }

  .cky-preference-center {
    background-color: ${COLORS.pinkLight} !important;
    color: ${COLORS.forest} !important;
    justify-content: right !important;
  }

  .cky-prefrence-btn-wrapper {
    display: flex !important;
    flex-direction: row-reverse !important;
    flex-wrap: nowrap !important;
    justify-content: center !important;
    gap: 8px !important;
    padding: 20px 10px !important;
  }

  .cky-prefrence-btn-wrapper button {
    background-color: ${COLORS.dawn} !important;
    color: ${COLORS.white} !important;
    font-size: 12px !important;
    border-radius: 100px 100px !important;
    display: inline-block !important;
  }

  .cky-footer-shadow {
    display: none !important;
  }

  .cky-btn-revisit-wrapper .cky-revisit-bottom-left {
    display: none !important;
  }

  @media only screen and (max-width: 350px) {
    .cky-prefrence-btn-wrapper {
      justify-content: space-between !important;
      gap: 0px !important;
      padding: 20px 1px !important;
    }
  }

  @media only screen and (max-width: 767px) {
    .cky-notice {
      padding: 135px 185px;
    }

    .cky-box-bottom-left {
      left: 0 !important;
    }

    .cky-notice-des {
      padding: 0px !important;
      max-height: auto !important;
    }
  }

  @media only screen and (min-width: 768px) {
    .cky-consent-bar {
      font-size: 13px !important;
      width: ${sqrLength}px !important;
      height: ${sqrLength}px !important;
      align-items: flex-start !important;
    }

    .cky-consent-bar::before {
      width: ${sqrLength}px;
      height: ${sqrLength}px;
    }
  }

  @media only screen and (max-width: 1023px) {
    .cky-notice-group {
      height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
    }

    .cky-notice-des p {
      font-size: 15px !important;
      line-height: 1.0rem !important;
      padding: 0px !important
    }

    .cky-notice-des {
      padding-left: 0px !important;
      margin: 0 !important;
      max-height 60vh !important;
      overflow-y: hidden !important;
    }

    .cky-notice-btn-wrapper {
      margin-top: 10px;
      display: flex !important;
      flex-direction: row !important;
      align-items: flex-start !important;
      justify-content: center !important;
      padding: 0px !important;
      margin-left: 0px !important;
    }

    .cky-btn-accept {
      order: 2 !important;
      max-width: 80px !important;
    }

    .cky-btn-customize {
      order: 1 !important;
    }

    .cky-btn {
      margin: 0px !important;
    }
  }

  // Cookie Banner End
`;

export const BackgroundTexture = css`
  background-image: url('/images/backgrounds/bg-texture.png');
  background-size: 220px auto;
`;

export const DotsPattern = css`
  background-image: url('/images/backgrounds/bg-dots-pattern.svg');
  background-size: 54px auto;

  ${BREAKPOINTS.small`
    background-size: 63px auto;
  `}
`;

export const CircleButton = css`
  padding: 4px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-clip: content-box;
  border: 1px solid ${COLORS.dawn.opacity(0.4)};
  border-radius: 100%;
  background-color: ${COLORS.dawn};
  transition: 0.4s;
  cursor: pointer;
`;

export const variants = {
  light: {
    bgColor: COLORS.pinkMuted,
    color: COLORS.forest,
    roundel: '/images/backgrounds/bg-roundel-dark.svg',
  },
  dark: {
    bgColor: COLORS.forest,
    color: COLORS.pinkMuted,
    roundel: '/images/backgrounds/bg-roundel-light.svg',
  },
};

export const PageContainer = styled.div`
  background-color: ${({ theme }) => variants[theme].bgColor};
  color: ${({ theme }) => variants[theme].color};
  min-height: 100vh;

  ${BREAKPOINTS.small`
    background-image: ${({ theme }) => `url(${variants[theme].roundel})`};
    background-position: -400px 400px;
    background-repeat: no-repeat;

     ${({ theme, bgRoundels }) =>
       bgRoundels === 2 &&
       css`
         background-image: url(${variants[theme].roundel}),
           url(${variants[theme].roundel});
         background-position: -400px 400px, calc(100vw - 220px) -182px;
       `}
  `}
`;

export const CircleMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: inherit;
  background-color: ${(props) => props.bgColor || COLORS.redWine};
  border-radius: 50vw 50vw 0 0;
  overflow: hidden;
  ${DotsPattern}

  /* Fix for overflow hidden with border-radius in Safari */
  transform: translateZ(0) translateY(1px);
`;
