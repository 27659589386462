import Script from 'next/script';

const FbqFallbackScript = () => {
  // Ever since CookieYes was implemented we're getting non stop fbq undefined errors
  // This is a fallback script to prevent those errors
  return (
    <>
      <Script strategy="beforeInteractive" id="fun-times-with-fbq">
        {`if (typeof fbq === 'undefined') {
            window.fbq = function() {
              console.warn('fbq is called but not defined');
              window.fbq.queue = window.fbq.queue || [];
              window.fbq.queue.push(arguments);
            };
            window.fbq.loaded = false;
            window.fbq.version = '0.0';
            window.fbq.queue = [];
          }`}
      </Script>
    </>
  );
};

export default FbqFallbackScript;
