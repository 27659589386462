import { css } from 'styled-components';

import COLORS from 'styles/colors';

const SlickSlider = css`
  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
  }
  .slick-track {
    display: flex;
  }
  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: 0;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    top: 0;
    left: 0;
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    &:focus {
      outline: none;
    }
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  /*# sourceMappingURL=slick.min.css.map */
  .slick-dots {
    position: absolute;
    display: block;
    padding: 0;
  }
  .slick-loading .slick-list {
    background: url(ajax-loader.gif) center center no-repeat ${COLORS.white};
  }
  .slick-next,
  .slick-prev {
    position: absolute;
    display: block;
    cursor: pointer;

    &:before {
      content: '';
    }
  }
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-dots {
    bottom: -25px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 6px;
    padding: 0;
    cursor: pointer;
  }
  .slick-dots li button {
    display: block;
    width: inherit;
    height: inherit;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
  }
  .slick-dots li button:focus,
  .slick-dots li button:hover {
    outline: 0;
  }
  .slick-dots li button:focus:before,
  .slick-dots li button:hover:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    content: '';
    text-align: center;
    border-radius: 50%;
    background-color: ${COLORS.forest.opacity(0.2)};
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: ${COLORS.black};
    background-color: ${COLORS.forest};
  }
  /*# sourceMappingURL=slick-theme.min.css.map */
`;

export default SlickSlider;
