import { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';

import { HelpText } from 'components/Form/Input';

import validation from 'utils/form-validation-schema';
import { postData } from 'utils/api';

const ErrorMessage = styled(HelpText)`
  margin-bottom: 20px;
`;

const GoogleReCaptcha = ({
  setIsSuccessful = (successful) => undefined,
  onSuccess = () => undefined,
}) => {
  const recaptchaRef = useRef(null);
  const [recaptchaError, setRecaptchaError] = useState('');

  const onReCaptchaChange = async (captchaCode) => {
    setRecaptchaError('');
    setIsSuccessful(false);

    if (!captchaCode) {
      setRecaptchaError(validation.googleRecaptchaExpired);
      recaptchaRef.current.reset();
      return;
    }

    try {
      const res = await postData('/api/enquiry/google-recaptcha', {
        captcha: captchaCode,
      });

      if (res.success) {
        setIsSuccessful(true);
        onSuccess();
      } else {
        setRecaptchaError(validation.googleRecaptcha);
        recaptchaRef.current.reset();
      }
    } catch (e) {
      console.error(e);
      setRecaptchaError(validation.googleRecaptcha);
      recaptchaRef.current.reset();
    }
  };

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
        onChange={onReCaptchaChange}
      />

      <ErrorMessage $hasError={true}>{recaptchaError}</ErrorMessage>
    </>
  );
};

export default GoogleReCaptcha;
